import React from "react";
import HModal from "./HModal";
import felishaImage from "../../../assets/landing/felisha.png";
import arrowIco from "../../../assets/landing/arrow.svg";
import closeIco from "../../../assets/landing/close.svg";
import { Link } from "react-router-dom";
import KScrollBar from "./KScrollBar";

export default function FModal({ open, onClose }) {
  return (
    <HModal open={open} onClose={onClose}>
      <div className="image-section">
        <img className="feli-img" src={felishaImage} alt="" />
      </div>
      <div className="f-modal-wrapper">
        <KScrollBar>
          <div onClick={onClose} className="close">
            <img src={closeIco} alt="" />
          </div>
          <h1 className="title">
            Hey 👋 I'm Felisha, the founder <br /> of Breaker Nation
          </h1>

          <div className="content">
            <p>
              Releasing music is the easy part. Marketing is the hard part.
              <br />
              <br />
              No matter when you started making music: It's never been easy for musicians to market and promote themselves. But of course,
              you know that, otherwise you wouldn't be here. 😉
              <br />
              <br />
              As an independent musician, breaking music is damn near impossible and it's only going to get worse. Soon, over 100,000 new
              tracks will be uploaded to music streaming services daily. Growing a fanbase is hard. Promotion is more expensive, people are
              more difficult to trust, social followings are harder to build…and getting to the point where you can do music full time is
              the hardest it's ever been.
              <br />
              <br />
              And that's why we're here to help.
              <br />
              <br />
              As an entrepreneur who's been in the music business for over 20 years and has seen everything from new apps, platforms come
              and go, promoters, playlisters, influencers, scammers & more — I've pretty much seen it all. I've educated, connected,
              promoted and given opportunities to thousands of musicians all around the globe — a few I could even name drop, but I'm not
              really into all that. 🙂
              <br />
              <br />
              Empowering music creators is not only my passion — it's my purpose.
              <br />
              <br />
              That's why I created Breaker Nation — to take everything I've learned about the entire music ecosystem and use that
              intelligence to build a new type of platform that can help you create the ultimate fanbase marketing machine.
              <br />
              <br />
              You won't find an innovative platform like this anywhere else. If you have no fans, a few fans, some or tons, I know Breaker
              Nation can help you get to your next level.
              <br />
              <br />
              If that strikes a chord with you, consider making an investment to become a member. 🙏
            </p>

            <Link to="/pricing">
              <button className="btn-grow-fanbase">
                <span>Grow Your Fanbase</span>
                <img src={arrowIco} alt="" />
              </button>
            </Link>
          </div>
        </KScrollBar>
      </div>
    </HModal>
  );
}
