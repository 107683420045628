export const MY_PROFILE_TABS = [
  {
    name: "Profile",
    value: 0,
  },
  {
    name: "Payment info",
    value: 1,
  },
  {
    name: "Pro settings",
    value: 2,
  },
  {
    name: "Logout",
    value: 3,
  },
];

export const FOLLOWING_TABS = [{ name: "People", value: 0 }, { name: "Rooms", value: 1 }];
