import { Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import downArrow from "../../assets/img/pricing/down-arrow.svg";
import PlanCardFeatures from "../Pricing/PlanCardFeatures";
import content from "../Pricing/pricingContent";

const GreenRadio = withStyles({
  root: {
    color: "#42AE55",
    "&$checked": {
      color: "#42AE55",
    },
  },
  checked: {},
})((props) => <Radio size="small" color="default" disableRipple {...props} />);

export default function PaymentPlanCard(props) {
  const isBusiness = props.special;
  const [open, setOpen] = useState(false);

  const checked = props.selectedPlan && props.selectedPlan.title === props.plan.title;
  const plan = props.plan;

  return (
    <div className={`payment-plan__card ${props.selectedPlan.title === props.title ? "green-border" : ""}`}>
      <div className="plan-header">
        {/* <Cricle style={{ marginTop: "30px" }} /> */}
        <div className="big-radio">
          <GreenRadio checked={checked} onChange={() => props.handleChange(plan)} value={plan} name="radio-button-demo" />
        </div>
        <div className="small-radio">
          <GreenRadio size="small" checked={checked} onChange={() => props.handleChange(plan)} value={plan} name="radio-button-demo" />
        </div>

        <div className="plan-header__wrapper">
          <div className="plan-header__title">
            <div>
              <span>Breaker Nation</span>
              <h2>{props.title}</h2>
            </div>
          </div>
          <div className="plan-header__desc">
            <span>{props.description}</span>
          </div>
        </div>
        <span className="plan-arrow">
          <img
            onClick={() => setOpen((open) => !open)}
            src={downArrow}
            alt="up-arrow"
            className={`payment-arrow__img ${open ? "payment-arrow__down" : "payment-arrow__up"}`}
          />
        </span>
      </div>

      <div className="plan-body" style={isBusiness ? { borderTop: "none" } : {}}>
        {/* <div>FREE</div> */}
        <div className={`pricing-plan__dollar-amount ${isBusiness ? "purple-bg" : ""} ${isBusiness && open && "remove-border"}`}>
          {props.price === "FREE" ? (
            <div style={{ paddingBottom: "5px" }}>FREE</div>
          ) : (
            <>
              <sup>$</sup>
              {props.price}
              <span className="small">/{props.recurring}</span>
            </>
          )}
        </div>

        {open && (
          <div className={`plan-features__container ${isBusiness ? "purple-border" : ""}`}>
            <div className="plan-features__wrapper">
              <PlanCardFeatures features={props.features} style={{ marginTop: "0px" }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
