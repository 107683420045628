import React from "react";
import { Link } from "react-router-dom";
import Signature from "../../assets/img/musician/signature.png";
import { AppStore, GooglePlay } from "../../utils/imgLoader";
const Confirmation = () => {
  return (
    <section className="beta-txt-container">
      <div className="beta-txt-sub-container">
        <span className="beta-txt">CONFIRMATION</span>
      </div>
      <p className="confirmation-txt">
        Welcome to Breaker Nation! We’re excited to be apart of your journey and can’t wait to get acquainted! To get started, proceed to
        the{" "}
        <Link to="/dashboard">
          <span className="confirmation-txt__dashboard-txt">DASHBOARD</span>
        </Link>{" "}
        to upload your first track.&nbsp;&nbsp; Following that, a member of our team will be in touch to answer any additional questions you
        may have!.
        <br />
        <br />
        If you haven’t downloaded the app, be sure to do so. Tap in to hear great music, support your peers, share your opinion on new music
        and engage with the community.
        <br />
        <br />
        We can’t wait to help you find people who love your music as much as you do!
      </p>
      <div className="confirmation-letter__your-truly">
        Your’s Truly,
        <br />
        <span>
          <img src={Signature} alt="signature" />
        </span>
        <br />
        Felisha Booker
        <br />
        Founder/CEO
        <br />
        & the entire Breaker Nation Team!!!
        <br />
        <br />
        <b>DOWNLOAD THE APP!</b>
      </div>
      <div className="confirmation-apps__container">
        <a className="app" href="https://apps.apple.com/us/app/breaker-nation/id1533950005">
          <img src={AppStore} alt="download app" />
        </a>
        <a className="app" href="https://play.google.com/store/apps/details?id=com.breakernation">
          <img src={GooglePlay} alt="download app" />
        </a>
      </div>
    </section>
  );
};

export default Confirmation;
