export const TRACK_STATUS = [
  {
    name: "All tracks",
    value: 'ALL_TRACKS',
  },
  {
    name: "In queue",
    value: 'IN_QUEUE',
  },
  {
    name: "Completed",
    value: 'COMPLETED',
  },
];
