import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Lottie from "react-lottie";
// import { Brand, AppStore, GooglePlay } from "../../utils/imgLoader";
import "../Home/home.styles.scss";
import HomeFooter from "../Home/ui/HomeFooter";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
// import menuAnimationData1 from "../../animations/menuanimation1/data";
// import menuAnimationData2 from "../../animations/menuanimation2/data";
// import logoAnimation from "../../animations/logoanimation/data";

const FourOFourLayout = ({ children }) => {
  //   const [show, setShow] = useState(false);
  //   const [isStopped1, setIsStopped1] = useState(true);
  //   const [isStopped2, setIsStopped2] = useState(true);
  //   const [isStoppedLogo, setIsStoppedLogo] = useState(true);
  //   const [showFirstAnimation, setShowFirstAnimation] = useState(true);
  //   const [showSecondAnimation, setShowSecondAnimation] = useState(false);

  //   const defaultOptionsLogo = {
  //     loop: false,
  //     autoplay: false,
  //     animationData: logoAnimation,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //     },
  //   };

  //   const defaultOptions1 = {
  //     loop: false,
  //     autoplay: false,
  //     animationData: menuAnimationData1,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //     },
  //   };
  //   const defaultOptions2 = {
  //     loop: false,
  //     autoplay: false,
  //     animationData: menuAnimationData2,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //     },
  //   };
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenuOnSidebar, setShowMenuOnSidebar] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    if (!showSidebar) {
      setShowMenuOnSidebar(false);
    }
  }, [showSidebar]);

  return (
    <div className="home">
      <HomeNav
        onMenuClick={() => setShowSidebar(true)}
        onMenuClose={() => setShowSidebar(false)}
        showSidebar={showSidebar}
        showMenu={showMenu}
      />
      {/* <header className="home-header">
        <div className="home-header-container">
          <Link to="/home">
            <div
              className="brand"
              onMouseEnter={() => {
                setIsStoppedLogo(false);
              }}
              onMouseLeave={() => {
                setIsStoppedLogo(true);
              }}
            >
              <Lottie className="brand" options={defaultOptionsLogo} height={66} width={160} isStopped={isStoppedLogo} />
            </div>
          </Link>
          <div className="right-side">
            <div className="header-txt">
              <p>THE FORCE IS UPON US</p>
            </div>
          </div>
        </div>
      </header> */}
      {/* {showFirstAnimation && (
        <div
          className="hamburger"
          onClick={() => {
            setIsStopped1(false);
            setShow(true);
            setIsStopped2(true);
            setTimeout(() => {
              setShowSecondAnimation(true);
              setShowFirstAnimation(false);
            }, 500);
          }}
        >
          <Lottie options={defaultOptions1} height={23} width={50} isStopped={isStopped1} />
        </div>
      )}
      {showSecondAnimation && (
        <div
          className="hamburger"
          onClick={() => {
            setIsStopped1(true);
            setIsStopped2(false);
            setTimeout(() => {
              setShow(false);
            }, 150);
            setTimeout(() => {
              setShowSecondAnimation(false);
              setShowFirstAnimation(true);
            }, 600);
          }}
        >
          <Lottie options={defaultOptions2} height={23} width={50} isStopped={isStopped2} />
        </div>
      )} */}
      <main>
        {children}
        {/* <div className={show ? "active side-nav" : "side-nav"}>
          <div className="d-flex">
            <a href="https://testing.breakernation.com/login" className="btn-login">
              LOGIN
            </a>
          </div>
          <ul>
            <li>
              <a href="https://www.notion.so/Terms-of-Service-14290b5f4ef24f4289bdf20f904fbc1b">TERMS</a>
            </li>
            <li>
              <a href="https://www.notion.so/Privacy-Policy-ab5b0b7fea1a42bc8551a3a03dad3e35">PRIVACY</a>
            </li>
            <li>
              <a href="https://www.notion.so/Community-Guidelines-66175d3e3c444786a78220847c24ea93">GUIDELINES</a>
            </li>
            <li>
              <a href="mailto:mailto:hello@breakernation.com">CONTACT US</a>
            </li>
          </ul>
          <div className="app-select">
            <div className="app-select-text">
              BREAK NEW MUSIC.
              <br /> BREAK OLD TRADITIONS. <br />
              BREAK YOURSELF — YOUR WAY!
            </div>
            <div className="apps">
              <a className="app-store2" href="https://apps.apple.com/us/app/breaker-nation/id1533950005">
                <img src={AppStore} alt="download app" />
              </a>
              <a className="google-play2" href="https://play.google.com/store/apps/details?id=com.breakernation">
                <img src={GooglePlay} alt="download app" />
              </a>
            </div>
          </div>
          <div className="nav-bottom">
            <img src={Brand} alt="brand" />
            <p className="nav-bottom-text">
              BREAKER NATION 2020-2021 <br /> All rights reserved
            </p>
          </div>
        </div> */}
        {/* <div>
          <a className="app-store1" href="https://apps.apple.com/us/app/breaker-nation/id1533950005">
            <img src={AppStore} alt="download app" />
          </a>
          <a className="google-play1" href="https://play.google.com/store/apps/details?id=com.breakernation">
            <img src={GooglePlay} alt="download app" />
          </a>
        </div> */}
        <HomeFooter
          onMenuClick={() => {
            setShowSidebar(true);
            setShowMenuOnSidebar(true);
            setShowMenu(false);
          }}
        />
      </main>
      {/* <footer className="home-footer">
        <div className="home-footer-text">
          BREAK NEW MUSIC.
          <br className="home-footer-text__breaker" /> BREAK OLD TRADITIONS.
          <br className="home-footer-text__breaker" /> BREAK YOURSELF — YOUR WAY!
        </div>
      </footer> */}
      <SideDrawer
        showClose={showMenuOnSidebar}
        open={showSidebar}
        onClose={() => {
          setShowSidebar(false);
          setShowMenu(true);
        }}
      />
    </div>
  );
};

export default FourOFourLayout;
