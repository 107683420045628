import React from "react";
import styled from "styled-components";

const StepsCard = () => {
  return (
    <Wrapper>
      <SingleCard>
        <OuterTitleWrapper>
          <TitleWrapper>
            <Title>01</Title>
            <Line />
            <Arroww />
          </TitleWrapper>
          <Subtitle>Listen</Subtitle>
        </OuterTitleWrapper>
        <ListItemsWrapper>
          <ListItem>Discover new artists </ListItem>
          <ListItem>Give your opinion on tracks </ListItem>
          <ListItem>Invite talented artists to our community </ListItem>
        </ListItemsWrapper>
      </SingleCard>
      <SingleCard>
        <OuterTitleWrapper>
          <TitleWrapper>
            <Title>02</Title>
            <Line purple={true} />
            <Arroww purple={true} />
          </TitleWrapper>
          <Subtitle>Support</Subtitle>
        </OuterTitleWrapper>
        <ListItemsWrapper>
          <ListItem>Join teams and promote artists </ListItem>
          <ListItem>Spread the word about new talent</ListItem>
          <ListItem>Share your fav tracks on social media</ListItem>
        </ListItemsWrapper>
      </SingleCard>
      <SingleCard>
        <OuterTitleWrapper>
          <TitleWrapper>
            <Title lastStep={true}>03</Title>
          </TitleWrapper>
          <Subtitle lastStep={true}>Network</Subtitle>
        </OuterTitleWrapper>
        <ListItemsWrapper>
          <ListItem>Meet other aspiring music pros</ListItem>
          <ListItem>Cohost & organize music meetups</ListItem>
          <ListItem>Attend in-app networking events</ListItem>
        </ListItemsWrapper>
      </SingleCard>
    </Wrapper>
  );
};

export default StepsCard;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5vh;
  padding: 5vh 2.94vw;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.6);
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 60px 20px 0 20px;
    padding: 40px 16px;
  }
`;

const SingleCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2.77vw;
  :last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const OuterTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.55vw;
  color: ${(props) => (props.lastStep ? props.theme.purple : props.theme.gold)};
  @media (max-width: 768px) {
    font-size: 22px;
    margin-right: 19px;
  }
`;

const Subtitle = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.11vw;
  color: ${(props) => (props.lastStep ? props.theme.purple : props.theme.gold)};
  margin: 2.6vh 0;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-right: 19px;
  }
`;

const ListItemsWrapper = styled.ul`
  @media (max-width: 768px) {
    margin-left: 23px;
  }
`;

const ListItem = styled.li`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 2.1vh;
  color: ${(props) => props.theme.white};
  margin-bottom: 1vh;
  margin-left: -1.5vw;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Line = styled.div`
  width: 100%;
  border: 0.5px solid ${(props) => (props.purple ? props.theme.purple : props.theme.gold)};
  margin-left: 1.66vw;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Arroww = styled.div`
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid ${(props) => (props.purple ? props.theme.purple : props.theme.gold)};
  @media (max-width: 768px) {
    display: none;
  }
`;
