import React from "react";

const Name = ({ children }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    className="tab-name"
  >
    {children}
  </div>
);

export const INITIAL_SETUP_TABS = [
  {
    id: "create-account-slider",
    name: (
      <Name>
        <font size="+2" style={{ fontFamily: "Montserrat" }}>
          ①
        </font>
        <div style={{ marginLeft: "5px" }}>Create account</div>
      </Name>
    ),
    value: 0,
  },
  {
    id: "verify-phone-slider",
    name: (
      <Name>
        <font size="+2" style={{ fontFamily: "Montserrat" }}>
          ②
        </font>
        <div style={{ marginLeft: "5px" }}>Verify Phone</div>
      </Name>
    ),
    value: 1,
  },
  {
    id: "payment-slider",
    name: (
      <Name>
        <font size="+2" style={{ fontFamily: "Montserrat" }}>
          ③
        </font>
        <div style={{ marginLeft: "5px" }}>Payment</div>
      </Name>
    ),

    value: 2,
  },
];

export const TAB_DETAILS = {
  CREATE_ACCOUNT: 0,
  VERIFY_PHONE: 1,
  PAYMENT: 2,
};
