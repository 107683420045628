import React from "react";
import "./dashboard.styles.scss";
import {ReactComponent as Search} from "../../assets/img/dashboard/search.svg";
import {ReactComponent as Clear} from "../../assets/img/musician/clear input.svg";
import SearchGold from "../../assets/img/search.png";
import InfluencerCard from "./influencerCard";
import Button from "../../common/Button";
import content2 from "../Musicians/content";
import {CircularProgress} from "@material-ui/core";

const InfluencerComponent = ({
  dataList,
  searchValue,
  handleOnChange,
  handleOpenProfile,
  handleClearSearch,
  isLoading,
  onLoadMore,
  isLoadingMore,
  isLoadMoreAllowed
}) => {
  return (
    <>
      <div className="search-bar-container">
        <div className="search-input-container">
          <Search/>
          <input
            type="text"
            id="searchValue"
            className="search-input"
            value={searchValue}
            onChange={handleOnChange}
            placeholder="Search by name..."
          />
          {!!searchValue && <Clear onClick={handleClearSearch}/>}
        </div>
      </div>
      {" "}
      <div className="influencer-main-container">
        <span className="influencer-header">Professionals</span>
        <section className="influencer-section">
          {dataList?.length ?
            (dataList?.map((userData) => {
                return <InfluencerCard
                  key={userData._id}
                  userData={userData}
                  onClick={() => handleOpenProfile(userData)}
                />;
              })
            ) : (
              <section className="empty-search-container">
                <div className="empty-search-sub-container">
                  <img src={SearchGold} alt="Search"/>
                  <span className="no-result-txt ">
                    {isLoading ? 'Loading...' : 'No results found!'}
                  </span>
                </div>
              </section>
            )}
        </section>
      </div>
      <div className='influencer-footer'>
        {isLoadingMore &&
        <CircularProgress/>
        }
        {!isLoadingMore && isLoadMoreAllowed &&
        <Button
          onClick={onLoadMore}
          className={'load-more-button'}
          buttonText={content2.LOAD_MORE}
        />}
      </div>
    </>
  );
}

export default InfluencerComponent;
