export default {
  HEADING_TEXT: `GET BACKSTAGE ACCESS TO THE MUSIC INDUSTRY`,
  CONTENT_TEXT: `Your opinion on music counts — now make it real. Hear new music first, share your opinion and earn rewards. Tap in and level up your reputation as a verified music professional!`,
  BUTTON_TEXT: `Let me in!`,
  DOWNLOAD_HEADING_TEXT: `Download the app!`,
  DOWNLOAD_CAPTION_1: `Discover future stars today`,
  DOWNLOAD_CAPTION_2: `Support independent musicians`,
  DOWNLOAD_CAPTION_3: `Connect with your favorite artists`,
  DOWNLOAD_CAPTION_4: `Establish your credibility as a professional`,
  STORE_TEXT_APP_STORE: `Download on`,
  DOWNLOAD_ON_THE: `Download on the`,
  STORE_HEADING_APP_STORE: `App Store`,
  STORE_HEADING_GOOGLE_PAY: `Google Play`,
  MAKE_MUSIC_HEADING: `Make Music?`,
  MAKE_MUSIC_CAPTION: `Connect with a music industry professionals and take your career to the next level!`,
  COPYRIGHT_TEXT: `BREAKER NATION 2020-2021. All rights reserved`,
  FANS: "Music fans",
  FOR_MUSICIANS: "For musicians",
};
