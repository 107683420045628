import { Drawer } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import closeGoldIco from "../../../assets/landing/close-gold.svg";
import breakerLogo from "../../../assets/landing/logo.png";

import appStore from "../../../assets/landing/app_store.png";
import playStore from "../../../assets/landing/play_store.png";

import Button from "../../../common/Button";

export default function SideDrawer({ open, onClose, showClose }) {
  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <div className="side-drawer-wrapper">
        <div className="close">{showClose && <img onClick={onClose} src={closeGoldIco} alt="" />}</div>
        <div className={`controls ${showClose ? "show-close" : ""}`}>
          <Link to="/login">
            <Button className="btn-login" buttonText="LOG IN" />
          </Link>

          <Link to="/music-internships">
            <Button className="btn-join" buttonText="VIRTUAL INTERNSHIP" />
          </Link>

          <Link to="/pricing">
            <Button className="btn-join" buttonText="MEMBERSHIP PLANS" />
          </Link>

          <Link to="/pricing">
            <Button className="btn-join" buttonText="JOIN NOW" />
          </Link>
        </div>

        <div className="sidebar-footer">
          <section className="company-intro">
            BREAK NEW MUSIC. <br />
            BREAK OLD TRADITIONS. <br />
            BREAK YOURSELF — YOUR WAY!
          </section>

          <div className="store-logo">
            <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/breaker-nation/id1533950005">
              <img src={appStore} alt="" />
            </a>

            <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.breakernation">
              <img src={playStore} alt="" />
            </a>
          </div>

          <Link to="/">
            <img src={breakerLogo} alt="" />
          </Link>
          <div className="sidebar-tagline">Fanbase Marketing Machine</div>
        </div>
      </div>
    </Drawer>
  );
}
