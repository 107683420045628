import React, { useEffect, useState } from "react";
import "./navFooterStyle.scss";
import Lottie from "react-lottie";
import menuAnimationData1 from "../../../animations/menuanimation3/data";

export default function AnimatedMenu({ onMenuClick, onMenuClose, showSidebar, ...rest }) {
  const [isStopped, setIsStopped] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [showSecondAnimation, setShowSecondAnimation] = useState(false);

  useEffect(() => {
    if (!showSidebar) {
      setIsPaused(false);
      onMenuClose();
      setShowSecondAnimation(false);
    }
  }, [onMenuClose, showSidebar]);
  const defaultOptions1 = {
    loop: false,
    autoplay: false,
    animationData: menuAnimationData1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className={`hamburger ${showSecondAnimation ? "to-left" : ""} `}
      {...rest}
      data-cy="home-menu-toggler"
      onClick={() => {
        setIsStopped(false);
        if (showSecondAnimation) {
          setIsPaused(false);
          onMenuClose();
          setShowSecondAnimation(false);
        } else {
          setTimeout(() => {
            setIsPaused(true);
          }, 500);
          onMenuClick();
          setShowSecondAnimation(true);
        }
      }}
    >
      <Lottie
        options={defaultOptions1}
        height={23}
        width={50}
        isPaused={isPaused}
        isStopped={isStopped}
        speed={showSecondAnimation ? 1 : -1}
      />
    </div>
  );
}
