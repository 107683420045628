import React from "react";
export default function PaymentPlan(props) {
  return (
    <div className="payment-plan__container">
      <div className="payment-plan__title">{props.title}</div>
      <div className="about-plan__container">
        <span>{props.description}</span>
        <ul>
          {props.features.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
      <div className="payment-plan__btn-container">
        {props.plans.map((plan) => (
          <button
            onClick={() =>
              props.handlePaymentSelection({
                value: plan.value,
                isAddPremium: plan.isAddPremium,
                isYearlySubscription: plan.isYearlySubscription,
              })
            }
          >
            {plan.label}
          </button>
        ))}
      </div>
    </div>
  );
}
