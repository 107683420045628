import React from "react";
export default function Program(props) {
  return (
    <div className="program-wrap" {...props}>
      <div className="program">
        <h2 className="num">{props.number}</h2>
        <h1>{props.title}</h1>
        <p>{props.desc}</p>
      </div>
    </div>
  );
}
