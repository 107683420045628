import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
import AppleIphone11 from "../../assets/img/apple-iphone-11-pro-space-grey-2.png";
import ApplePodcast from "../../assets/img/apple-podcast.svg";
import GooglePodcast from "../../assets/img/google-podcast.svg";
import CheatCode from "../../assets/img/cheatcode.png";

import { decodeErrorMessage } from "../../utils";

import "./Podcast.styles.scss";
import { axiosInstance } from "../../utils/axiosInstance";

export default function PodcastComponent() {
  const [showSidebar, setShowSidebar] = useState(false);

  const [email, setEmail] = useState("");
  const [subLoading, setSubLoading] = useState(false);

  const onSubscribe = async () => {
    if (subLoading || !email) return;
    setSubLoading(true);
    try {
      const res = await axiosInstance.post("/v1/users/subscribe", { email });
      setEmail("");
      setSubLoading(false);
      toast.success(res.message);
    } catch (err) {
      setSubLoading(false);
      toast.error(decodeErrorMessage(err));
    }
  };

  useEffect(() => {
    localStorage.setItem("subscribed", "true");
    return () => {
      localStorage.setItem("subscribed", "false");
    };
  }, []);

  return (
    <div className="podcast">
      <HomeNav onMenuClick={() => setShowSidebar(true)} onMenuClose={() => setShowSidebar(false)} showSidebar={showSidebar} />

      <main className="container">
        {/* Left container */}
        <aside className="left">
          <div className="welcome-container">
            <span className="welcome-title">WELCOME TO</span>
            {/* <div className="welcome-border" /> */}
            <hr className="welcome-border" />
          </div>

          <div className="main-title">THE BREAKER NATION AUDIO EXPERIENCE</div>
          <img className="cheat-code__text" src={CheatCode} alt="The Cheat Code to Promoting" />
          <div className="listen-now__container">
            <input type="email" placeholder="EMAIL ADDRESS" value={email} onChange={(e) => setEmail(e.target.value)} />
            <button className="listen-now__btn" onClick={onSubscribe}>
              {subLoading ? "Please wait..." : "Listen Now"}
            </button>
          </div>
          <div className="about-text">
            <b>About the podcast</b>
            <br />
            <br />
            Have you ever wondered how songs go viral, or what the formula is for building a fanbase that promotes your music relentlessly?
            <br />
            <br />
            Breaker Nation’s new podcast, <i>The Cheat Code to Promoting Music That Goes Viral</i> explores the intersection of music,
            promotion and fandoms.
            <br />
            <br />
            Join Breaker Nation as they interview superfans, insiders and historians to uncover the whole truth and nothing but the truth.
            If you’re intrigued by what happens behind the music, discover little known facts and never before told stories.
            <br />
            <br />
            Trying to promote your own music? Get access to the tools and strategies you need to be successful. Each episode of the podcast
            is hosted by Felisha Booker, Kevin Jack, Chuck Greene and a Guest Co-Host.
            <br />
            <br />
            The podcast is currently in production with plans to launch Q2 of 2022.
          </div>
        </aside>

        {/* Right container */}
        <aside className="right">
          <div className="mobile-image">
            <img src={AppleIphone11} alt="podcast in iphone" />
          </div>
          <div className="podcast-on">
            <div className="stream-text">Stream our private podcast on</div>
            <div className="listen-on__container">
              <div className="listen-on-podcast">
                <div className="listen-on-podcast__container">
                  <div className="listen-on-podcast-image">
                    <img src={ApplePodcast} alt="Apple Podcast" />
                  </div>
                  <div className="listen-on-podcast__details">
                    <span className="listen-on__text">Listen on</span>
                    <span className="podcast-brand">Apple Podcasts</span>
                  </div>
                </div>
              </div>
              <div className="listen-on-podcast google-podcast">
                <div className="listen-on-podcast__container">
                  <div className="listen-on-podcast-image">
                    <img src={GooglePodcast} alt="Google Podcast" />
                  </div>
                  <div className="listen-on-podcast__details">
                    <span className="listen-on__text">Listen on</span>
                    <span className="podcast-brand">Google podcasts</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </main>

      <SideDrawer showClose={false} open={showSidebar} onClose={() => setShowSidebar(false)} />
    </div>
  );
}
