import React from "react";
import HModal from "./HModal";
import closeIco from "../../../assets/landing/close.svg";
export default function VModal({ open, onClose }) {
  return (
    <HModal open={open} onClose={onClose}>
      <div
        style={{
          position: "fixed",
          top: "4vh",
          right: "5vw",
          zIndex: 9999,
          cursor: "pointer",
        }}
        onClick={onClose}
        className="close"
      >
        <img src={closeIco} alt="" />
      </div>
      <div style={{ width: "80vw", height: "80vh" }}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/bXRcID1l2kA"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        ></iframe>
      </div>
    </HModal>
  );
}
