import React, { useCallback, useEffect, useState } from "react";
import { CardCVCElement, CardExpiryElement, CardNumberElement, Elements, injectStripe, StripeProvider } from "react-stripe-elements";
import { STRIPE_KEY } from "../../config";
import "rc-checkbox/assets/index.css";
import content from "../MyProfile/tabs/InfluencerSetting/content";
import InputField from "../../common/InputField";
import Button from "../../common/Button";
import ToggleSwitch from "../../common/ToggleSwitch";
import { trackMixpanel } from "../../mixpanel";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentMethods, getPaymentMethods, getUserDetails, updateUserData } from "../../state/actions/userActions";
import { axiosInstance } from "../../utils/axiosInstance";
import { useHistory } from "react-router-dom";

function StripePaymentComponent({ stripe, plan, interval, setShowSuccessScreen, setShowFailureScreen }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails?.user);
  //   const handleClickAdd = async () => {
  //       if(!accountName) return
  //     const { token } = await props.stripe.createToken({ name: accountName });
  //     if (token) {
  //       props.handleAddPaymentMethod(token);
  //     }
  //   };

  const [rememberPaymentMethod, setRememberPaymentMethod] = React.useState(true);

  const onInputChange = (e) => {
    setAccountName(e.target.value);
  };

  const handlePay = async () => {
    if (!accountName) return;
    const { token } = await stripe.createToken({ name: accountName });

    // const title = plan.title;
    const plan_name = plan.slug;
    const plan_frequency = interval === "annual" ? "yearly" : interval === "monthly" ? "monthly" : "";
    const payload = {
      plan_name,
      plan_frequency,
      payment_token: token.id,
    };
    if (plan_name === "Free") {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        history.push("/dashboard");
      }, 700);
      return;
    }

    try {
      setLoading(true);
      await axiosInstance.post(`subscription`, payload);
      if (rememberPaymentMethod) {
        handleAddPaymentMethod(token);
      }
      setLoading(false);
      setTimeout(() => {
        setShowSuccessScreen(true);
      }, 700);
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        setShowFailureScreen(true);
      }, 700);
    }
  };

  const handleAddPaymentMethod = useCallback(
    (cardDetail) => {
      const payload = {
        paymentToken: cardDetail.id,
      };
      dispatch(addPaymentMethods(payload))
        .then((resp) => {
          if (!userDetails.default_payment_id) {
            dispatch(updateUserData({ default_payment_id: resp.id }, { refresh: true }));
          }
        })
        .catch((err) => {});
    },
    [dispatch, userDetails.default_payment_id]
  );

  useEffect(() => {
    // dispatch(getPaymentMethods());
    dispatch(getUserDetails(true));
  }, [dispatch]);

  return (
    <div>
      <div className="paymentInputContainer">
        <label className="titleLabel">{content.NAME_ON_CARD}</label>
        <InputField
          id="accountName"
          value={accountName}
          onChange={onInputChange}
          className="titleInput"
          placeholder={content.NAME_PLACEHOLDER}
          style={{ base: { color: "#FFFFFF80" } }}
        />
        <label className="titleLabel">{content.CARD_NUMBER_LABEL}</label>
        <CardNumberElement
          className="titleInput"
          placeholder={"_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _"}
          style={{
            base: {
              color: "#FFFFFF80",
              "::placeholder": { color: "#FFFFFF80", opacity: 1 },
              ":-ms-input-placeholder": { color: "#FFFFFF80" },
              "::-ms-input-placeholder": { color: "#FFFFFF80" },
            },
          }}
        />
        <div className="cardDetailsContainer">
          <div className="expiry-container">
            <label className="titleLabel">{content.EXPIRY_DATE_LABEL}</label>
            <CardExpiryElement
              className="titleInput"
              placeholder={"_ _ / _ _"}
              style={{
                base: {
                  color: "#FFFFFF80",
                  "::placeholder": { color: "#FFFFFF80", opacity: 1 },
                  ":-ms-input-placeholder": { color: "#FFFFFF80" },
                  "::-ms-input-placeholder": { color: "#FFFFFF80" },
                },
              }}
            />
          </div>
          <div style={{ width: 120 }}>
            <label className="titleLabel">{content.CVC_LABEL}</label>
            <CardCVCElement
              className="titleInput"
              style={{
                base: {
                  color: "#FFFFFF80",
                  "::placeholder": { color: "#FFFFFF80", opacity: 1 },
                  ":-ms-input-placeholder": { color: "#FFFFFF80" },
                  "::-ms-input-placeholder": { color: "#FFFFFF80" },
                },
              }}
              placeholder={"_ _ _"}
            />
          </div>
        </div>
        <div className="remember-container">
          <div className="remember-text">Remember card for future payments</div>
          <div>
            {/* <ToggleSwitch checked={false} onChange={() => {}} /> */}
            <ToggleSwitch checked={rememberPaymentMethod} onChange={() => setRememberPaymentMethod((oldVal) => !oldVal)} />
          </div>
        </div>
        <div className="payment-pay-btn__container">
          <Button
            onClick={() => {
              handlePay();
            }}
            loading={loading}
            className="pay-button-element payment-pay-btn"
            buttonText={"PAY $" + plan.price}
          />
        </div>
      </div>
    </div>
  );
}

export default injectStripe(StripePaymentComponent);
