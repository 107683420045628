import React, { useCallback, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SignupComponent from "../../components/Signup/SignupComponent";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Signup() {
  let query = useQuery();
  const userOptions = {
    plan: query.get("plan"),
    interval: query.get("type"),
  };
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const history = useHistory();

  const handleChangeTab = useCallback();
  // (event, value) => {
  //   if (value === 0) return history.push("/signup");
  //   if (value === 1) return history.push("/signup/verify-phone");
  //   if (value === 2) return history.push("/signup/payment");
  // },
  // [history]

  //   React.useEffect(() => {
  //     switch (location.pathname) {
  //       case "/signup":
  //         setTabValue(0);
  //         break;
  //       case "/signup/":
  //         setTabValue(0);
  //         break;
  //       case "/signup/create-account":
  //         setTabValue(0);
  //         break;
  //       case "/signup/create-account/":
  //         setTabValue(0);
  //         break;
  //       case "/signup/verify-phone":
  //         setTabValue(1);
  //         break;
  //       case "/signup/verify-phone/":
  //         setTabValue(1);
  //         break;
  //       case "/signup/payment":
  //         setTabValue(2);
  //         break;
  //       case "/signup/payment/":
  //         setTabValue(2);
  //         break;
  //       default:
  //         setTabValue(0);
  //         break;
  //     }
  //   }, [location]);

  return <SignupComponent tabValue={tabValue} setTabValue={setTabValue} userOptions={userOptions} />;
}
