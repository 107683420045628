import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import cx from "classnames";
import * as serviceWorker from "./serviceWorker";
import Router from "./routes";
import store from "./state/store";
import "antd/dist/antd.css";
import "./style.scss";
import "./scss/common.styles.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initMixpanel } from "./mixpanel";
import LogRocket from "logrocket";
import BModal from "./common/BModal/BModal";
import KSubscriptionPopup from "./common/KSubscribePopup";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";

toast.configure({
  autoClose: 4000,
  draggable: false,
  position: toast.POSITION.TOP_LEFT,
});

initMixpanel();

ReactPixel.init("317323326133731", {}, { debug: true, autoConfig: false });
ReactPixel.pageView();
ReactPixel.fbq("track", "PageView");
ReactPixel.track("ViewContent");

const App = () => {
  const [showSubscriptionPop, setShowSubscriptionPop] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("subscribed")) {
      setShowSubscriptionPop(true);
      localStorage.setItem("subscribed", "true");
    }
  }, []);

  LogRocket.init("v9ooyp/breaker-nation");
  window.onbeforeunload = () => {
    localStorage.removeItem("access-token-verified");
  };

  return (
    <main className={cx("mainContainer")}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </Provider>
    </main>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
