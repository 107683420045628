import React from "react";

import breakerLogo from "../../../assets/landing/logo.png";
import menuIco from "../../../assets/landing/menu.svg";

import instaImage from "../../../assets/landing/insta-gold.svg";
import linkedinImage from "../../../assets/landing/linkedin-gold.svg";
import twitterImage from "../../../assets/landing/twitter-gold.svg";
import tiktokImage from "../../../assets/landing/tiktok-gold.svg";
import youtubeImage from "../../../assets/landing/youtube-gold.svg";

import "./navFooterStyle.scss";
import { Link } from "react-router-dom";

export default function HomeFooter({ onMenuClick }) {
  return (
    <section className="footer">
      <div className="footer-wrap">
        <div className="footer-menu">
          <img onClick={onMenuClick} src={menuIco} alt="" />
        </div>
        <Link to="/">
          <img src={breakerLogo} className="logo" alt="" />
        </Link>
        <ul className="footer-list">
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.notion.so/Terms-of-Service-14290b5f4ef24f4289bdf20f904fbc1b">
              Terms
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.notion.so/Privacy-Policy-ab5b0b7fea1a42bc8551a3a03dad3e35">
              Privacy
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.notion.so/Community-Guidelines-66175d3e3c444786a78220847c24ea93">
              Guidelines
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="mailto:hello@breakernation.com">
              Contact Us
            </a>
          </li>
        </ul>
        <div className="copy-right">Copyright © Breaker Nation 2022. All rights reserved.</div>
        <div className="icons">
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/1breakernation">
            <img style={{ height: 18 }} src={instaImage} alt="" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/1breakernation">
            <img src={linkedinImage} alt="" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/1breakernation">
            <img src={twitterImage} alt="" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@1breakernation">
            <img src={tiktokImage} alt="" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCC7-mLtdre3v5qAwZo-EpzA">
            <img src={youtubeImage} alt="" />
          </a>
        </div>
      </div>
    </section>
  );
}
