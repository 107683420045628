import React from "react";
import '../../../components/Musicians/musician.style.scss'
import Confirmation from "../../../components/Musicians/Confirmation";
import {ReactComponent as Breaker} from "../../../assets/img/musician/breaker_nation.svg";

const MusicianConfirm = () => {
  return (
    <div className="musician-main-container">
      <div className="musician-top-container ">
        <div className="otp-container">
          <Breaker className="breaker-nation-icon" />
          <Confirmation />
        </div>
      </div>
    </div>
  );
};

export default MusicianConfirm
