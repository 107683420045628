import React from "react";
import Scrollbars from "react-custom-scrollbars";
import "./navFooterStyle.scss";

export default function KScrollBar({ children }) {
  return (
    <Scrollbars
      universal
      autoHide
      thumbSize={50}
      renderTrackVertical={({ style, ...props }) => <div {...props} className="scrollbar__track__vertical" />}
      renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, backgroundColor: "#c8a86b", borderRadius: 10 }} />}
      className={"scrollbar-container"}
    >
      {children}
    </Scrollbars>
  );
}
