import React from "react";
import "./pricing.style.scss";
import PlanCardExploreFeatures from "./PlanCardExploreFeatures";
import PlanCardFeatures from "./PlanCardFeatures";
import PlanCardHeader from "./PlanCardHeader";
import PlanCardPrice from "./PlanCardPrice";
import { Link } from "react-router-dom";

export default function PlanCard(props) {
  const isFree = props.price === "FREE";
  const isBusiness = props.special;
  return (
    <div className={`pricing-plan__card ${isBusiness ? "pricing-purple__border" : ""}`} style={props.style}>
      {/* Header */}
      {/* Recommended */}
      {isBusiness && (
        <div className="pricing-plan__header-recommended">
          <div>Recommended</div>
        </div>
      )}

      <PlanCardHeader title={props.title} description={props.description} style={{ marginTop: isBusiness ? "-30px" : "8px" }} />

      {/* Price */}

      <PlanCardPrice price={props.price} recurring={props.recurring} style={isBusiness ? { background: "#5634C2", border: "none" } : {}} />

      {/* Features */}
      <PlanCardFeatures features={props.features} style={{ marginBottom: "1.8em" }} />

      {/* Explore Features */}
      <PlanCardExploreFeatures exploreFeatures={props.exploreFeatures} />

      {/* Subscribe button */}
      <div className="pricing-plan__subscribe-container" style={{ marginTop: "2.5em" }}>
        <Link to={`/signup?plan=${props.slug}&type=${props.pricingPlanInterval}`}>
          <button>{isFree ? "Try It" : "Subscribe"}</button>
        </Link>
      </div>
    </div>
  );
}
