import React, { useEffect, useState } from "react";
import FileDropper from "../../common/FileDropper";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
import FAQ from "../Pricing/FAQ";
import "./apply.style.scss";
import deleteIcon from "../../assets/icon/delete.svg";
import HomeFooter from "../Home/ui/HomeFooter";
import ApplicationSuccess from "./ApplicationSuccess";
import { handleInternshipFormSubmit, uploadResumeAxiosModified } from "../../state/actions/internActions";
import { toast } from "react-toastify";
import { UploadingState } from "../AddTrackComponent/AddTrackComponent";
import { Redirect } from "react-router-dom";

export default function ApplyComponent() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenuOnSidebar, setShowMenuOnSidebar] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [resumeUrl, setResumeUrl] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [addSuccess, setAddSuccess] = useState(false);
  const [qa, setQa] = useState({
    cover_letter: "",
    linkedin: "",
    instagram: "",
    twitter: "",
    other: "",
    what_is_your_dream_job_and_why: "",
    in_which_way_you_can_help_artist: "",
    indie_music_creator: "",
  });
  const [errors, setErrors] = React.useState({
    file: false,
    fileType: false,
    fileErrorMessage: "",
  });

  const handleSubmit = async () => {
    console.log("clicked");
    if (!resumeUrl) {
      setErrors({
        ...errors,
        fileErrorMessage: "You have to upload your resume.",
      });
      return;
    }
    try {
      setSubmitLoading(true);
      await handleInternshipFormSubmit(resumeUrl, [
        { question: "cover_letter", answer: qa.cover_letter },
        { question: "linkedin", answer: qa.linkedin },
        { question: "twitter", answer: qa.twitter },
        { question: "instagram", answer: qa.instagram },
        { question: "other", answer: qa.other },
        { question: "what_is_your_dream_job_and_why", answer: qa.what_is_your_dream_job_and_why },
        { question: "in_which_way_you_can_help_artist", answer: qa.in_which_way_you_can_help_artist },
        { question: "indie_music_creator", answer: qa.indie_music_creator },
      ]);
      setShowSuccess(true);
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      toast.error(error?.error?.message || "Failed to submit. Please try again!");
      throw error;
    }
  };

  const isFileOk = (name) => {
    const _name = name?.toLowerCase();
    if (_name?.endsWith(".pdf") || _name?.endsWith(".doc") || _name?.endsWith(".docx")) {
      return true;
    }
    return false;
  };

  const handleFileDrop = async (files) => {
    console.log({ files });
    if (!files?.length) {
      return;
    }
    if (files.length > 1) {
      setErrors({
        ...errors,
        fileType: true,
        file: true,
        fileErrorMessage: "You can only upload one file",
      });
      return;
    }

    const file = files[0];
    if (!isFileOk(file.name)) {
      setErrors({
        ...errors,
        fileType: true,
        file: true,
        fileErrorMessage: "This file is not a pdf or docx file. Please retry with a new file.",
      });
      return;
    }
    if (file.size / 1024 / 1024 >= 15) {
      setErrors({
        ...errors,
        fileType: true,
        file: true,
        fileErrorMessage: "File size should be within 15 MB",
      });
      return;
    }
    setErrors({
      ...errors,
      fileType: false,
    });
    try {
      const url = await uploadResumeAxiosModified(file, (progress) => {
        setUploadProgress(progress);
      });
      setResumeUrl(url.url);
      setResumeName(file?.name);
      setAddSuccess(true);
      localStorage.setItem(
        "resume_details",
        JSON.stringify({
          resume_url: url.url,
          resume_name: file?.name,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const clearResumeDetails = () => {
    setResumeUrl("");
    setResumeName("");
    setAddSuccess(false);
    localStorage.removeItem("resume_details");
    setUploadProgress(0);
  };

  useEffect(() => {
    if (!showSidebar) {
      setShowMenuOnSidebar(false);
    }
  }, [showSidebar]);

  useEffect(() => {
    const resume = localStorage.getItem("resume_details");
    if (!!resume) {
      const parsedResume = JSON.parse(resume);
      setResumeUrl(parsedResume.resume_url);
      setResumeName(parsedResume.resume_name);
      setAddSuccess(true);
    }
  }, []);

  if (showSuccess) return <Redirect to="/internship/applied" />;

  return (
    <div className="apply">
      <HomeNav
        onMenuClick={() => setShowSidebar(true)}
        onMenuClose={() => setShowSidebar(false)}
        showSidebar={showSidebar}
        // style={showVerifyPhone ? { zIndex: 999 } : {}}
      />
      <UploadingState progress={uploadProgress} visible={uploadProgress > 0 && uploadProgress < 100} />
      <div className="main-container">
        <div className="container">
          {/* build you career */}
          <div className="title-container">
            <h1>INTERNSHIP APPLICATION</h1>
            <p>
              Thank you for your interest! To be considered for an internship, please complete the application below. You can expect to
              receive a response within 7 business days.
            </p>
          </div>
          <hr />

          {/* resume */}
          <div className="resume-container">
            <h1>RESUME & COVER LETTER</h1>

            <div className="add-container">
              <span>
                Upload resume <span style={{ color: "red" }}>*</span>{" "}
              </span>

              {addSuccess && (
                <section className="selected-file">
                  <div onClick={clearResumeDetails} className="delete-selected-file">
                    <img src={deleteIcon} alt="" />
                  </div>
                  <aside className="selected-file-name">
                    <div className="selected-file-title">{resumeName}</div>
                  </aside>
                </section>
              )}

              {Boolean(errors.fileErrorMessage) && <span className="error-text">{errors.fileErrorMessage}</span>}
              {!addSuccess && (
                <section className={`file-dropper`}>
                  <FileDropper accept=".pdf,.docx,.doc" className="file-dropper-wrapper big" onDrop={handleFileDrop}>
                    <div className="file-dropper-title">
                      <p style={{ marginBottom: 0, color: "#C8A86B", fontWeight: 600 }}>Drag & Drop here</p>
                      <p style={{ marginBottom: 0, fontWeight: 600 }}>or</p>
                      <p style={{ marginBottom: 0, color: "#C8A86B", fontWeight: 600 }}>Browse file</p>
                      <p style={{ marginBottom: 0, color: "#fff", fontSize: 12, fontWeight: 300 }}>
                        Available formats: <span style={{ fontWeight: 600, color: "#fff" }}>.PDF, .DOC, .DOCX</span>
                      </p>
                    </div>
                  </FileDropper>
                </section>
              )}
            </div>

            <div className="add-container">
              <span>Cover Letter</span>
              <textarea
                className="cover-letter"
                placeholder="Please tell us why you are interested in this internship opportunity"
                value={qa.cover_letter}
                onChange={(e) => {
                  setQa((prev) => ({ ...prev, cover_letter: e.target.value }));
                }}
              />
            </div>
          </div>
          <hr />

          {/* SOCIAL LINKS */}
          <div className="social-container">
            <h1>SOCIAL LINKS (OPTIONAL)</h1>
            <div className="social-media-input__container">
              <div className="two-social-media__wrapper">
                <div className="social-media__input">
                  <span>Linkedin</span>
                  <input
                    type="text"
                    placeholder="linkedin.com/username"
                    required
                    value={qa.linkedin}
                    onChange={(e) => {
                      setQa((prev) => ({ ...prev, linkedin: e.target.value }));
                    }}
                  ></input>
                </div>
                <div className="social-media__input">
                  <span>Instagram</span>
                  <input
                    type="text"
                    placeholder="instagram.com/username"
                    required
                    value={qa.instagram}
                    onChange={(e) => {
                      setQa((prev) => ({ ...prev, instagram: e.target.value }));
                    }}
                  ></input>
                </div>
              </div>
              <div className="two-social-media__wrapper">
                <div className="social-media__input">
                  <span>Twitter</span>
                  <input
                    type="text"
                    placeholder="twitter.com/username"
                    required
                    value={qa.twitter}
                    onChange={(e) => {
                      setQa((prev) => ({ ...prev, twitter: e.target.value }));
                    }}
                  ></input>
                </div>
                <div className="social-media__input">
                  <span>Website / Other</span>
                  <input
                    type="text"
                    placeholder="yourwebsite.com "
                    required
                    value={qa.other}
                    onChange={(e) => {
                      setQa((prev) => ({ ...prev, other: e.target.value }));
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <hr />

          {/* INTERVIew Questions */}
          <div className="interview-container">
            <h1>INTERVIEW QUESTIONS</h1>
            <div>
              <div className="question-container">
                <span>
                  {" "}
                  1. What's your dream job and why? <span style={{ color: "red" }}>*</span>
                </span>
                <textarea
                  placeholder="Write your answer here"
                  required
                  value={qa.what_is_your_dream_job_and_why}
                  onChange={(e) => {
                    setQa((prev) => ({ ...prev, what_is_your_dream_job_and_why: e.target.value }));
                  }}
                ></textarea>
              </div>
              <div className="question-container">
                <span>
                  2. Our company is all about supporting musicians. In which ways do you think you can help an artist you love?{" "}
                  <span style={{ color: "red" }}>*</span>
                </span>
                <textarea
                  placeholder="Write your answer here"
                  required
                  value={qa.in_which_way_you_can_help_artist}
                  onChange={(e) => {
                    setQa((prev) => ({ ...prev, in_which_way_you_can_help_artist: e.target.value }));
                  }}
                ></textarea>
              </div>
              <div className="question-container">
                <span>
                  3. Please share a link to one indie/undiscovered music creator that the world needs to hear. Tell us why you love this
                  person and/or their song. <span style={{ color: "red" }}>*</span>
                  {/* <span className="gold-letters">
                    + Bonus points <Star />
                  </span> */}
                </span>
                <textarea
                  color="#ffffff"
                  placeholder="Write your answer here"
                  required
                  value={qa.indie_music_creator}
                  onChange={(e) => {
                    setQa((prev) => ({ ...prev, indie_music_creator: e.target.value }));
                  }}
                ></textarea>
              </div>
            </div>
            <button
              onClick={() => {
                if (!submitLoading) {
                  handleSubmit();
                }
              }}
            >
              {submitLoading ? "Submitting....." : "Submit"}
            </button>
          </div>

          <hr />

          <div className="faq-container">
            <h1 className="faq-title">FAQ</h1>
            <div className="faq-questions__container">
              <FAQ
                title={"How long will it take to find out if I’ve been accepted?"}
                desc={"You can expect a response within 7 business days."}
              />
              <FAQ
                title={"What will I be doing as an intern?"}
                desc={
                  "You will be listening to music, writing reviews, joining teams, working with artists, promoting new music, attending virtual events and more!"
                }
              />
              <FAQ
                title={"How long is the internship?"}
                desc={
                  "Most participants complete the internship in about 90 days.  It can be shorter or longer, depending upon your availability to complete the required assignments."
                }
              />
              <FAQ title={"Is the internship paid?"} desc={"No, it is not."} />
              <FAQ title={"How many hours per week are required?"} desc={"We recommend at least 10 hours per week."} />
              <FAQ
                title={"How do I get my internship certificate?"}
                desc={"Once all assignments have been submitted and approved, you can request your internship certificate."}
              />
              <FAQ
                title={"I need more information.  Who can I contact?"}
                desc={"Please contact hello@breakernation.com with any additional questions."}
              />
            </div>
          </div>

          <div className="write-us-email">
            Still have questions? <a href="mailto:hello@breakernation.com?subject=Internship Inquiry">Write us an e-mail!</a>
          </div>
        </div>
        <SideDrawer showClose={showMenuOnSidebar} open={showSidebar} onClose={() => setShowSidebar(false)} />
      </div>
      <HomeFooter
        onMenuClick={() => {
          setShowSidebar(true);
          setShowMenuOnSidebar(true);
        }}
      />
    </div>
  );
}
