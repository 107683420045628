import { Drawer } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { phoneAuthenticationVerify, registerUserAction } from "../../state/actions/userActions";
import { validateRegex } from "../../utils";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
import OtpContainer from "../Musicians/OtpContainer";
import CreateAccount from "../Signup/CreateAccount";
import "./signup.style.scss";

export default function IntershipSignUpComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(true);
  const [state, setState] = useState({
    user_name: "",
    email: "",
    display_name: "",
  });
  const [errors, setErrors] = useState({
    user_name: false,
    email: false,
    display_name: false,
    phoneNumber: false,
    otp: false,
    userExist: false,
    message: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(localStorage.getItem("country-code") || "+1");
  const [resendTime, setResendTime] = useState(0);
  const [showSidebar, setShowSidebar] = useState(false);

  const resetError = useCallback(() => {
    setErrors({ ...errors, message: "", phoneNumber: false });
  }, [errors]);

  const handleChange = (event) => {
    if (event.target.id) {
      setState({ ...state, [event.target.id]: event.target.value });
    }
  };
  const handleOnChangePhoneNumber = useCallback(
    (e) => {
      errors.phoneNumber && resetError();
      setPhoneNumber(e.target.value);
    },
    [setPhoneNumber, errors, resetError]
  );

  const handleOnChangeCountryCode = useCallback(
    (e) => {
      setCountryCode(e.target.value);
      localStorage.setItem("country-code", e.target.value);
    },
    [setCountryCode]
  );
  const handleOnSubmit = useCallback(async () => {
    const data = {
      phone_number: `${countryCode}${phoneNumber}`,
      email: state.email,
      user_name: state.user_name,
    };

    const isInValidPhoneNumber = !isValidPhoneNumber(data.phone_number);

    const isValid =
      state.user_name.trim() !== "" && state.display_name.trim() !== "" && validateRegex("email", state.email) && !isInValidPhoneNumber;
    console.log(isValid, "isValid", data);
    setErrors({
      ...errors,
      otp: false,
      user_name: state.user_name.trim() === "",
      phoneNumber: isInValidPhoneNumber,
      email: !validateRegex("email", state.email),
      display_name: state.display_name.trim() === "",
    });

    if (isValid) {
      setResendTime(60);

      try {
        const success = await dispatch(registerUserAction(data));
        if (success && isValid) {
          setShowVerifyPhone(true);
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        setErrors({
          ...errors,
          userExist: true,
          user_name: errorMessage.includes("Username"),
          phoneNumber: errorMessage.includes("Phone"),
          email: errorMessage.includes("Email"),
          message: errorMessage || "User already exists !",
        });
      }
    }
  }, [countryCode, phoneNumber, state.email, state.user_name, state.display_name, errors, dispatch]);
  const handleRegister = async (phoneNumber, otp) => {
    const data = {
      email: state.email,
      user_name: state.user_name,
      display_name: state.display_name,
      password: "password",
      phoneNumber: `${countryCode}${phoneNumber}`,
      OTP: otp,
    };

    try {
      const resp = await phoneAuthenticationVerify(data);
      if (resp.token) {
        localStorage.setItem("x-access-token", resp.token);
        // TODO: SHOW NEXT MODULE AFTER UI VERIFICATION
        // setShowGenereSelection(true);
        setShowVerifyPhone(false);
        setShowCreateAccount(false);

        history.push("/internship/apply");
      }
    } catch (error) {
      setErrors({
        ...errors,
        otp: true,
      });
    }
  };
  const resetOtpError = useCallback(() => {
    setErrors({ ...errors, otp: false });
  }, [errors]);

  useEffect(() => {
    const timer = resendTime > 0 && setInterval(() => setResendTime(resendTime - 1), 1000);
    return () => clearInterval(timer);
  }, [resendTime]);

  return (
    <div className="home">
      <HomeNav
        onMenuClick={() => setShowSidebar(true)}
        onMenuClose={() => setShowSidebar(false)}
        showSidebar={showSidebar}
        style={showVerifyPhone ? { zIndex: 999 } : {}}
      />
      {showCreateAccount && (
        <main className="internship-main__container">
          <h1 className="title">Welcome to Breaker Nation Internships!</h1>
          <CreateAccount
            style={{ borderTop: "none" }}
            state={state}
            handleChange={handleChange}
            errors={errors}
            phoneNumber={phoneNumber}
            handleOnChangePhoneNumber={handleOnChangePhoneNumber}
            handleOnChangeCountryCode={handleOnChangeCountryCode}
            countryCode={countryCode}
            handleOnSubmit={handleOnSubmit}
            resendTime={resendTime}
          />
          <div className="account-login__container">
            <div className="account-login__title">Already have an account?</div>
            <Link to="/login?return_url=/internship/apply">
              <div className="login-now__btn">Login now!</div>
            </Link>
          </div>
        </main>
      )}
      <SideDrawer showClose={false} open={showSidebar} onClose={() => setShowSidebar(false)} />

      <Drawer anchor="right" open={showVerifyPhone} onClose={() => setShowVerifyPhone(false)}>
        <OtpContainer
          onClose={() => setShowVerifyPhone(false)}
          phoneNumber={phoneNumber}
          countryCode={countryCode}
          handlePhoneVerify={handleRegister}
          onReSend={handleOnSubmit}
          resetOtpError={resetOtpError}
          errors={errors}
          resendTime={resendTime}
        />
      </Drawer>
    </div>
  );
}
