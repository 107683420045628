import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SuccessMusic from "../../assets/img/signup/music-success.png";
export default function SuccessSignup(props) {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/musicians/confirm");
    }, 1500);
  }, [history]);

  return (
    <div className="signup-success">
      <div className="signup-success__container">
        <img src={SuccessMusic} alt="success" className="music-img" />
        <div className="success-desc__container">
          <div className="success-txt">Success!</div>
          <div className="paragraph first">Your request has been received. We will be in touch soon with feedback on your music.</div>
          <div className="paragraph second">You can always find your request in a specific track details page!</div>
        </div>
        <button
          className="got-it__btn"
          onClick={() => {
            props.setShowSuccessScreen(false);
            history.push("/musicians/confirm");
          }}
        >
          GOT IT
        </button>
      </div>
    </div>
  );
}
