import api from "../config";
import {debounce} from "@material-ui/core";
import {axiosInstance} from "../utils/axiosInstance";
import {useCallback, useEffect, useRef, useState} from "react";

const PAGE_SIZE = 100
const useGetInfluencers = ({isApiCallOnMount = false}) => {
  const [isLoading, setLoading] = useState(false)
  const pageNumber = useRef(0)
  const searchRef = useRef('')
  const [influencersList, setInfluencersList] = useState([])
  const isLoadMoreAllowed = useRef(false)

  const fetchInfluencers = async (pageNumber = 0, search = '') => {
    try {
      setLoading(true)
      const params = {
        page: pageNumber,
        limit: PAGE_SIZE
      }
      if (search) {
        params.search = search
      }
      const response = await axiosInstance.get(`${api}/listeners/influencers`, {params});
      isLoadMoreAllowed.current = response?.length === PAGE_SIZE
      setInfluencersList(old => {
        return pageNumber === 0 ? response : [...old, ...response]
      })
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }


  const searchInfluencers = useCallback(debounce((search) => {
    // fetchDataHttp?.abort()
    pageNumber.current = 0
    searchRef.current = search
    fetchInfluencers(0, search)
  }, 300), [])


  const onLoadMoreInfluencers = () => {
    pageNumber.current = pageNumber.current + 1
    fetchInfluencers(pageNumber.current, searchRef.current)
  }

  useEffect(() => {
    if (isApiCallOnMount) {
      fetchInfluencers()
    }
  }, [])

  return {
    influencersList,
    searchInfluencers,
    onLoadMoreInfluencers,
    isLoading,
    isLoadingMore: isLoading && pageNumber.current > 0,
    isLoadMoreAllowed: isLoadMoreAllowed.current && !isLoading
  }
}


export default useGetInfluencers
