export default {
  interval: {
    // MONTHLY: "monthly",
    LIFE_TIME: {
      plans: {
        Free: {
          title: "Try It",
          recommended: false,
          price: "FREE",
          description: "Get music feedback",
          features: ["Promote 1 Track", "Up to 10 reviews", "Free Track Analytics", "Networking Events"],
          exploreFeatures: [
            {
              title: "Music promotion",
              description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
            },
            {
              title: "Analyze your fans ",
              description:
                "Find out how interested fans are in your music.  Read 3 reviews and discover who's willing to promote you on social media/playlists.",
            },
            {
              title: "Meet new people",
              description: "Attend virtual in-app events to meet other music creators and industry professionals.",
            },
          ],
        },
      },
    },
    MONTHLY: {
      type: "monthly",
      plans: {
        Starter: {
          title: "Starter",
          recommended: false,
          description: "Grow your audience",
          price: 4.99,
          recurring: "mo",
          features: ["Promote 1 Track", "Up to 50 Reviews", "Lite Track Analytics", "Networking Events"],
          exploreFeatures: [
            {
              title: "Music promotion",
              description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
            },
            {
              title: "Analyze your fans",
              description:
                "Find out how interested fans are in your music.  Read all reviews and discover who’s willing to promote you on social media/playlists.",
            },
            {
              title: "Meet new people",
              description: "Attend virtual in-app events to meet other music creators and industry professionals.",
            },
          ],
        },
        DreamTeam: {
          title: "Rising",
          recommended: false,
          description: "Mobilize your supporters",
          price: 14.99,
          recurring: "mo",
          features: ["Promote 5 Tracks", "Up to 250 Reviews", "Launch Teams & DM's", "Lite Track Analytics", "Networking Events"],
          exploreFeatures: [
            {
              title: "Build your team",
              description: "Interact all the new fans who want to help you promote your music in a private discussion forum.",
            },
            {
              title: "Direct message",
              description: "Talk privately with every fan on your team so strategize on how they can help you reach your goals.",
            },
            {
              title: "Music promotion",
              description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
            },
            {
              title: "Analyze your fans",
              description:
                "Find out how interested fans are in your music.  Read all reviews and discover who's willing to promote you on social media/playlists.",
            },
            {
              title: "Meet new people",
              description: "Attend virtual in-app events to meet other music creators and industry professionals.",
            },
          ],
        },
        Business: {
          title: "Business",
          recommended: true,
          description: "Build a successful brand",
          price: 29.99,
          recurring: "mo",
          features: [
            "Promote 10 Tracks",
            "Up to 1000 Reviews",
            "Go Live Audio Rooms",
            "Launch Teams & DM's",
            "Pro Track Analytics",
            "Networking Events",
            "90-Day Success Manager",
          ],
          exploreFeatures: [
            {
              title: "Go live in rooms",
              description:
                "Get your own dedicated workspace to host  events in the app. Enable audio and go live with fans, your team or collaborators. Video coming soon!",
            },
            {
              title: "Build your team",
              description: "Interact all the new fans who want to help you promote your music in a private discussion forum.",
            },
            {
              title: "Direct message",
              description: "Talk privately with every fan on your team so strategize on how they can help you reach your goals.",
            },
            {
              title: "Music promotion",
              description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
            },
            {
              title: "Analyze your fans deeper",
              description:
                "Measure the performance of each track by accessing more details about how each listener interacts with your track.",
            },
            {
              title: "Meet new people",
              description: "Attend virtual in-app events to meet other music creators and industry professionals.",
            },
          ],
        },
      },
    },
    ANNUAL: {
      type: "annual",
      plans: {
        Starter: {
          title: "Starter",
          recommended: false,
          description: "Grow your audience",
          price: 49.99,
          recurring: "year",
          features: ["Promote 1 Track (50 Reviews)", "Lite Track Analytics", "Networking Events"],
          exploreFeatures: [
            {
              title: "Music promotion",
              description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
            },
            {
              title: "Analyze your fans",
              description:
                "Find out how interested fans are in your music.  Read all reviews and discover who’s willing to promote you on social media/playlists.",
            },
            {
              title: "Meet new people",
              description: "Attend virtual in-app events to meet other music creators and industry professionals.",
            },
          ],
        },
        DreamTeam: {
          title: "Rising",
          recommended: false,
          description: "Mobilize your supporters",
          price: 149.99,
          recurring: "year",
          features: ["Promote 5 Tracks", "Get 250 Reviews", "Launch Teams & DM’s", "Lite Track Analytics", "Networking Events"],
          exploreFeatures: [
            {
              title: "Build your team",
              description: "Interact all the new fans who want to help you promote your music in a private discussion forum.",
            },
            {
              title: "Direct message",
              description: "Talk privately with every fan on your team so strategize on how they can help you reach your goals.",
            },
            {
              title: "Music promotion",
              description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
            },
            {
              title: "Analyze your fans",
              description:
                "Find out how interested fans are in your music.  Read all reviews and discover who’s willing to promote you on social media/playlists.",
            },
            {
              title: "Meet new people",
              description: "Attend virtual in-app events to meet other music creators and industry professionals.",
            },
          ],
        },
        Business: {
          title: "Business",
          recommended: true,
          description: "Build a successful brand",
          price: 299.99,
          recurring: "year",
          features: [
            "Promote 10 Tracks",
            "Get 1000 Reviews",
            "Go Live Audio Rooms",
            "Launch Teams & DM’s",
            "Pro Track Analytics",
            "Networking Events",
            "90-Day Success Manager",
          ],
          exploreFeatures: [
            {
              title: "Go live in rooms",
              description:
                "Get your own dedicated workspace to host  events in the app. Enable audio and go live with fans, your team or collaborators. Video coming soon!",
            },
            {
              title: "Build your team",
              description: "Interact all the new fans who want to help you promote your music in a private discussion forum.",
            },
            {
              title: "Direct message",
              description: "Talk privately with every fan on your team so strategize on how they can help you reach your goals.",
            },
            {
              title: "Music promotion",
              description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
            },
            {
              title: "Analyze your fans deeper",
              description:
                "Measure the performance of each track by accessing more details about how each listener interacts with your track.",
            },
            {
              title: "Meet new people",
              description: "Attend virtual in-app events to meet other music creators and industry professionals.",
            },
          ],
        },
      },
    },
  },
  faqs: [
    {
      question: "Who reviews my music?",
      answer:
        "Our community is full of music lovers, aspiring executives, creators, influencers and industry professionals.  These are real people who want to discover and support musicians however they can.",
    },
    {
      question: "How many tracks can I upload?",
      answer:
        "Each month you can upload to the limit set by your membership plan.  Upgrade at any time if you’d like to upload more tracks.",
    },
    {
      question: "How long does it take to get reviews?",
      answer:
        "We’re a relatively new service so it’s hard to say.  We’re on a mission to find the best fans we can and hope to get you more than enough reviews each month to help you grow your brand.",
    },
    {
      question: "How do I access the reviews?",
      answer:
        "Login to the website and navigate to the dashboard where you’ll see each track you uploaded.  From there you can navigate to read reviews.  To protect your privacy, reviews are not displayed publicly.",
    },
    {
      question: "What is a 90-Day Success Manager?",
      answer:
        "We genuinely want you to win!  So, we have a team of professionals on hand to help you navigate using our platform.  You’ll receive complimentary support from them during your first 90 days on the platform.",
    },
    {
      question: "Can I upgrade or downgrade / switch plans at any time?",
      answer: "Absolutely!  Just login on the website and visit the settings page.",
    },
    {
      question: "Do you offer refunds?",
      answer:
        "As a company policy, we do not issue refunds unless a system malfunction caused a problem or your account was terminated without cause.",
    },
  ],
};
