import React, { useEffect, useState } from "react";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
import Lottie from "react-lottie";
import "./landing.style.scss";
import internshipLandingAnimationData from "../../animations/internshiplanding/data";
import comma from "comma-number";
import { onGetTotalUserCount } from "../../state/actions/userActions";
import FModal from "../Home/ui/FModal";
// import RightGrewArrow from "../../assets/img/home/rightGreyArrow.png";
import RightGrewArrow from "../../assets/img/home/rightGreyArrow.png";
import crowdImage from "../../assets/landing/crowd.png";
import neuralIcon from "../../assets/img/internship/neural.svg";
import graphIcon from "../../assets/img/internship/graph.svg";
import ideaIcon from "../../assets/img/internship/idea.svg";
import certificateImage from "../../assets/img/internship/certificate.png";
import purpleLine from "../../assets/img/internship/purpleline.svg";
import Program from "./Program";
import { Link } from "react-router-dom";
import HomeFooter from "../Home/ui/HomeFooter";
import FInternshipModal from "./FInternshipModal";

export default function InternshipLandingPage() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenuOnSidebar, setShowMenuOnSidebar] = useState(false);
  const [userCount, setUserCount] = useState(localStorage.getItem("user-count") || 1150);
  const [showFModal, setShowFModal] = useState(false);
  const fetchCount = async () => {
    try {
      const res = await onGetTotalUserCount();
      localStorage.setItem("user-count", res?.users);
      setUserCount(res?.users || 1137);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCount();
  }, []);
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: internshipLandingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    if (!showSidebar) {
      setShowMenuOnSidebar(false);
    }
  }, [showSidebar]);

  return (
    <>
      <HomeNav
        onMenuClick={() => setShowSidebar(true)}
        onMenuClose={() => setShowSidebar(false)}
        showSidebar={showSidebar}
        style={{ background: "#18181a" }}
      />
      <div className="internship-landing">
        <main className="container">
          <div className="top-container">
            <div className="main-content">
              <div className="first-container">
                <div className="apply-now">
                  <h1>AN INTERNSHIP PROGRAM</h1>
                  <div className="desc-container">
                    <div className="desc">The # 1 way to get the experience you need to work in the music industry</div>
                    <Link to="/internship/apply">
                      <button>APPLY NOW</button>
                    </Link>
                  </div>
                </div>
                <div className="intern-lottie">
                  <Lottie options={defaultOptions1} speed={1.5} />
                </div>
              </div>
              <div className="steps">
                <div className="step">
                  <h1 className="index">01</h1>
                  <div>
                    <h1 className="desc">LISTEN</h1>
                    <ul>
                      <li>Discover new artists</li>
                      <li>Give your opinion on tracks</li>
                      <li>Invite talented artists to our community</li>
                    </ul>
                  </div>
                  <div className="arrow hide-on-mobile">
                    <div className="line"></div>
                    <div className="point"></div>
                  </div>
                </div>
                <div className="step" style={{ margin: "0 5em" }}>
                  <h1 className="index">02</h1>
                  <div>
                    <h1 className="desc">SUPPORT</h1>
                    <ul>
                      <li>Join teams and promote artists</li>
                      <li>Spread the word about new talent</li>
                      <li>Share your fav tracks on social media</li>
                    </ul>
                  </div>
                  <div className="arrow hide-on-mobile">
                    <div className="line2"></div>
                    <div className="point2"></div>
                  </div>
                </div>
                <div className="step">
                  <h1 className="index purple">03</h1>
                  <div>
                    <h1 className="desc purple">NETWORK</h1>
                    <ul>
                      <li>Meet other aspiring music pros</li>
                      <li>Cohost & organize music meetups</li>
                      <li>Attend in-app networking events</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="crowd-container">
            <p className="hide-on-mobile">Preparation is the key to success</p>
            <div className="crowd">
              <img src={crowdImage} alt="" />
            </div>

            <div className="info-text">
              <span>
                Join {comma(userCount)} music creators, fans & professionals <br />
                <br />
                <u style={{ cursor: "pointer" }} onClick={() => setShowFModal(true)}>
                  Read Felisha's letter to you{" "}
                </u>
                <img src={RightGrewArrow} />
              </span>
            </div>
          </div>
          <hr style={{ width: "100%" }} />
          <div className="launch-text ">Keys Open Doors at Breaker Nation</div>
          <div className="certification">
            <img className="certificate2 hide-on-desktop" src={certificateImage} />
            <div className="process-container">
              <div className="process">
                <img src={neuralIcon} className="icon" />
                <div className="about-container">
                  <h1>Make Connections</h1>
                  <p>Meet and network with like minded professionals in the music industry</p>
                </div>
              </div>
              <img src={purpleLine} className="purpleline" />
              <div className="process">
                <img src={graphIcon} className="icon" />
                <div className="about-container">
                  <h1>Develop Skills</h1>
                  <p>Apply your dream skillset, develop new ones and work with actual artists</p>
                </div>
              </div>
              <img src={purpleLine} className="purpleline" />
              <div className="process">
                <img src={ideaIcon} className="icon" />
                <div className="about-container">
                  <h1>Be Creative</h1>
                  <p>Have the freedom to contribute original ideas that showcase your strengths</p>
                </div>
              </div>
            </div>
            <img className="certificate hide-on-mobile" src={certificateImage} />
          </div>
          <hr style={{ width: "100%" }} />
          <div className="program-benifits">
            <h1>An Internship Experience That Works For Everyone </h1>
            <div className="programs">
              <div className="first-container">
                <Program number="01" title="Flexible Schedules" desc="Set your schedule on your terms for 90% of the work." />
                <Program
                  number="02"
                  title="Work From Anywhere"
                  desc="No travel required.  The entire program is 100% remote."
                  style={{ margin: "0 60px" }}
                />
                <Program
                  number="03"
                  title="No Cost To Apply
"
                  desc="It’s free!  You don’t need to pay an application fee."
                />
              </div>
              <div className="second-container">
                <Program
                  number="04"
                  title="Need College Credit?"
                  desc="No problem! After enrolling, just send over documentation and we’ll sign off as needed."
                  style={{ marginRight: "30px" }}
                />
                <Program
                  number="05"
                  style={{ marginLeft: "30px" }}
                  title="Rolling Admission"
                  desc="We review applications weekly and allow you to start when you’re ready."
                />
              </div>
            </div>
          </div>
          <div className="join">
            <div className="container">
              <div className="desc">
                We are now seeking bright and self motivated interns to work hard, have fun and be apart of creating the future of the
                entire music industry!
              </div>
              <div className="join-text">Join us!</div>
              <Link to="/internship/apply">
                <button>Apply Now</button>
              </Link>
              <div className="write-us-email">
                Still have questions? <a href="mailto:hello@breakernation.com?subject=Internship Inquiry">Write us an e-mail!</a>
              </div>
            </div>
          </div>
        </main>
        <FInternshipModal open={showFModal} onClose={() => setShowFModal(false)}>
          <h1>Modal New</h1>
        </FInternshipModal>
        <SideDrawer showClose={showMenuOnSidebar} open={showSidebar} onClose={() => setShowSidebar(false)} />
        <HomeFooter
          onMenuClick={() => {
            setShowSidebar(true);
            setShowMenuOnSidebar(true);
          }}
        />
      </div>
    </>
  );
}
