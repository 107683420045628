import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import '../../../components/Musicians/musician.style.scss'
import OtpContainer from "../../../components/Musicians/OtpContainer";
import {musicianInfoSelector} from "../../../state/selectors/preferences";
import {
  phoneAuthenticationVerify,
  registerUserAction
} from "../../../state/actions/userActions";
import {setMusicianFlowInfo} from "../../../state/actions/preferencesActions";
import {ReactComponent as Breaker} from "../../../assets/img/musician/breaker_nation.svg";
import {
  submitPayment,
  uploadTrackToS3BucketAxiosModified
} from "../../../state/actions/orderActions";
import {toast} from "react-toastify";

const MusicianVerification = (props) => {
  const {
    countryCode,
    selectedGenreMultiple,
    selectedCover,
    selectedFile,
    artistName,
    emailAddress,
    trackName,
    displayName,
    userName,
    phoneNumber,
  } = useSelector(musicianInfoSelector)
  const number = `${countryCode}${phoneNumber.trim()}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({phoneNumber: false, otp: false});
  const [submitLoading, setSubmitLoading] = useState(false);

  const [resentCode, setResentCode] = useState(true);
  const [resendTime, setResendTime] = useState(0);

  const handleOnSubmit = async (resent = false) => {
    try {
      setResendTime(60);
      await dispatch(
        registerUserAction({
          phone_number: number,
          email: emailAddress,
          user_name: userName,
        })
      );
    } catch ({message}) {
      toast.error(message)
    }
  };

  const handlePhoneVerify = async (phoneNumber, otp) => {
    const payload = {
      email: emailAddress.trim(),
      user_name: userName.trim(),
      display_name: displayName.trim(),
      phoneNumber: number,
      OTP: otp,
    };

    try {
      setSubmitLoading(true);
      const resp = await phoneAuthenticationVerify(payload)
      if (!resp) {
        return
      }
      localStorage.setItem("x-access-token", resp.token);
      const data = await submitPayment({
        trackTitle: trackName,
        trackAuthor: artistName,
        paymentToken: "CONNECT",
        genreId: selectedGenreMultiple,
      })
      dispatch(setMusicianFlowInfo({feedbackId: data?.feedbackId}))
      await uploadTrackToS3BucketAxiosModified(data?.feedbackId, selectedFile, selectedCover)
      history.replace('/musicians/congrats')
    } catch (e) {
      setErrors({...errors, otp: true});
    } finally {
      setSubmitLoading(false);
    }
  };

  const resetOtpError = useCallback(() => {
    setErrors({...errors, otp: false});
  }, []);

  useEffect(() => {
    const timer = resendTime > 0 && setInterval(() => setResendTime(resendTime - 1), 1000);
    return () => clearInterval(timer);
  }, [resendTime]);

  return (
    <div className="musician-main-container">
      <div className="musician-top-container ">
        <div className="otp-container">
          <Breaker className="breaker-nation-icon"/>
          <OtpContainer
            countryCode={countryCode}
            resendTime={resendTime}
            resentCode={resentCode}
            phoneNumber={phoneNumber}
            handlePhoneVerify={handlePhoneVerify}
            onReSend={() => handleOnSubmit(true)}
            resetOtpError={resetOtpError}
            errors={errors}
            submitLoading={submitLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default MusicianVerification
