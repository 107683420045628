import React from "react";
import styled from "styled-components";

const StyledListItem = styled.div`
  display: flex;
  column-gap: 50px;
  align-items: center;
  justify-content: center;

  .check {
    height: auto;
    object-fit: contain;
    width: 38px !important;
  }

  .title {
    color: white;
    text-align: left;
    font-size: 20px;
    font-weight: 300;
    min-width: 150px;
  }

  @media (min-width: 800px) {
    column-gap: 60px;
  }
`;

const ListItem = ({ title }) => {
  return <StyledListItem>
    <img src="/svg/check.png" alt="" className="check" />
    <span className="title">{title}</span>
  </StyledListItem>;
};


const StyledIntern = styled.div`
  display: grid;
  color: white;
  column-gap: 140px;
  padding-top: 60px;
  grid-template-columns: 1fr;
  
  .right-container {
    row-gap: 16px;
    display: grid;
    padding-top: 30px;
    grid-template-columns: 1fr;
  }
  
  @media (min-width: 800px) {
    grid-template-columns: 1fr auto;
  }
`;

export const Intern = () => {
  return <StyledIntern>
    <img src="/svg/intern_fans_1.png" alt="" />
    <div className="right-container">
      <ListItem title="Get Exposure" />
      <ListItem title="Perfect Your Music" />
      <ListItem title="Brainstorm Ideas" />
      <ListItem title="Share Your Music" />
      <ListItem title="Grow A Fanbase" />
      <ListItem title="Build Your Team" />
    </div>
  </StyledIntern>;
};
