import React from "react";
import { Link } from "react-router-dom";
// import { AppStore, GooglePlay } from "../../utils/imgLoader";
import "./404.styles.scss";
import FourOFourLayout from "./Four0FourLayout";

const FourOFour = () => {
  return (
    <FourOFourLayout>
      <div className="fourofour-container">
        <div className="text-container">
          <h1 className="text">404</h1>
          <p className="text-desc">Oops! Something isn’t quite working properly. Hit this super magical magic button to fix it!</p>
          <Link to="/">
            <button className="magic-btn">
              <span role="img" aria-label="magic">
                ✨ MAGIC BUTTON ✨
              </span>
            </button>
          </Link>
        </div>
        {/* <div className="app-store">
          <a className="fof-app-store1" href="https://apps.apple.com/us/app/breaker-nation/id1533950005">
            <img src={AppStore} alt="download app" />
          </a>
          <a className="fof-google-play1" href="https://play.google.com/store/apps/details?id=com.breakernation">
            <img src={GooglePlay} alt="download app" />
          </a>
        </div> */}
        {/* <footer className="mobile-footer">
          <div className="mobile-footer__container">
            <div className="home-footer-text">
              BREAK NEW MUSIC.
              <br className="home-footer-text__breaker" /> BREAK OLD TRADITIONS.
              <br className="home-footer-text__breaker" /> BREAK YOURSELF — YOUR WAY!
            </div>
            <div className="mobile-footer__btn-wrapper">
              <a className="mobile-app-store1" href="https://apps.apple.com/us/app/breaker-nation/id1533950005">
                <img src={AppStore} alt="download app" />
              </a>
              <a className="mobile-google-play1" href="https://play.google.com/store/apps/details?id=com.breakernation">
                <img src={GooglePlay} alt="download app" />
              </a>
            </div>
          </div>
        </footer> */}
      </div>
    </FourOFourLayout>
  );
};

export default FourOFour;
