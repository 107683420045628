import React, { useState } from "react";
import "./pricing.style.scss";
import addSmallIcon from "../../assets/icon/add-small.svg";
import minusIcon from "../../assets/icon/minus.svg";

export default function ExploreFeature(props) {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((open) => !open);
  return (
    <>
      <div className="explore-feature__wrap" onClick={handleClick}>
        <span>{props.title}</span>
        <span>{!open ? <img src={addSmallIcon} alt="add icon" /> : <img src={minusIcon} alt="add icon" />}</span>
      </div>
      {/* Desc */}
      {open && (
        <div className="explore-feature__desc">
          <div>{props.description}</div>
        </div>
      )}
    </>
  );
}
