import React from "react";
import starIcon from "../../assets/icon/purple-star.svg";
import starAltIcon from "../../assets/icon/star-alt.svg";
import sadIcon from "../../assets/icon/sad.svg";

const HowItWorksRating = ({ rating, onClick, width, marginRight = 16 }) => {
  return (
    <div className="rate-icons">
      {rating >= 0 &&
        [...Array(5)].map((item, index) => {
          return rating < index + 1 ? (
            <img
              style={{ marginRight: index !== 4 ? marginRight : 0, width: width || 22 }}
              key={index}
              onClick={() => onClick(index + 1)}
              src={starAltIcon}
              alt=""
            />
          ) : (
            <img
              style={{ marginRight: index !== 4 ? marginRight : 0, width: width || 22 }}
              key={index}
              onClick={() => onClick(index + 1)}
              src={starIcon}
              alt=""
            />
          );
        })}

      {rating < 0 && <img src={sadIcon} alt="" />}
    </div>
  );
};

export default HowItWorksRating;
