import React, { useState } from "react";
import Breaker from "../../assets/img/home/brand.png";
import UserDashboard from "../../assets/img/landing/user-dashboard.png";
import UsersReviews from "../../assets/img/landing/users-reviews.png";
import Mob1 from "../../assets/img/landing/mob1.png";
import Mob2 from "../../assets/img/landing/mob2.png";
import Mob3 from "../../assets/img/landing/mob3.png";
import "./landing.style.scss";
import HowItWorksRating from "../../common/HowItWorksRating";
import MobileComponent from "./MobileComponent";
import SocialMediaGroup from "../../assets/img/landing/social-media-group.png";
import { ReactComponent as BreakerLogo2 } from "../../assets/icon/breaker-logo-2.svg";
import promoAnimation from "../../animations/promo-team/data";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";

export default function LandingComponent() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: promoAnimation,
  };

  return (
    <div className="landing-page">
      {/* Header */}
      <section className="landing-page__header">
        <img src={Breaker} className="breaker-nation-icon" alt="brand-logo" />
      </section>

      <section className="landing-page__header-mobile">
        {/* <img src={Breaker} className="breaker-nation-icon" alt="brand-logo" /> */}
        <Link to="/pricing">
          <div className="header-text">Get Started</div>
        </Link>
      </section>

      {/* Hero */}
      <div className="landing-page__hero">
        <div className="landing-page__hero-title hide-on-mobile">
          GROW YOUR <br /> FOLLOWING & BUILD
          <br />
          <div>
            YOUR
            <Lottie className="promo-animation" options={defaultOptions} />
          </div>
          {/* PROMO TEAM */}
        </div>
        <div className="landing-page__hero-title show-on-mobile">
          GROW YOUR <br /> FOLLOWING & BUILD YOUR
          <br />
          <div>
            <Lottie className="promo-animation" options={defaultOptions} />
          </div>
          {/* PROMO TEAM */}
        </div>
        <div className="landing-page__hero-desc">
          Find new fans to join your team and promote your music — because no one makes it to the top by themselves!
        </div>
        <div className="landing-page__signup">
          <Link to="/pricing">
            <button>SAY LESS — SIGN ME UP!</button>
          </Link>
        </div>
      </div>

      <div className="landing-page__user-dashboard">
        {/* TODO: Replase below with figma image */}
        <img src={UserDashboard} alt="user dashboard" className="landing-page__user-dashboard__img" />
      </div>

      {/* Reviews */}
      <div className="landing-page__reviews">
        <div className="landing-page__review">
          <div className="hide-on-mobile">
            <HowItWorksRating rating={5} width={24} marginRight={35} />
          </div>
          <div className="show-on-mobile">
            <HowItWorksRating rating={5} width={10} marginRight={6} />
          </div>
          <div className="landing-page-review__text">
            <i>
              “As a manager for a Grammy award winning artist, I used to think Social Media was the ultimate engagement tool for musicians.
              Now my bets are on Breaker Nation all the way!”
            </i>
            — Eli Davis
          </div>
        </div>
        <div className="landing-page__review landing-page__review-second">
          {/* <HowItWorksRating rating={5} width={24} marginRight={35} /> */}
          <div className="hide-on-mobile">
            <HowItWorksRating rating={5} width={24} marginRight={35} />
          </div>
          <div className="show-on-mobile">
            <HowItWorksRating rating={5} width={10} marginRight={6} />
          </div>
          <div className="landing-page-review__text">
            <i>“Enim lorem aliquam, orci interdum rhoncus. A consectetur cras id risus in”</i>
          </div>
        </div>
      </div>

      {/* Find new Fans */}
      <div className="landing-page__find-new-fans">
        <h1 className="landing-page__find-new-fans__title">FIND NEW FANS</h1>
        <div className="landing-page__find-new-fans__desc">
          Porttitor in ut felis nec sed metus, semper mi ante. Sit eget proin potenti scelerisque. Mauris at vel et ipsum. Gravida pulvinar
          vitae ultrices id consequat ac. Sem in sed enim adipiscing neque, rutrum sagittis, adipiscing.{" "}
        </div>
        <div className="landing-page__find-new-fans__image-container">
          <img src={UsersReviews} alt="user reviews" className="landing-page__find-new-fans__image" />
        </div>
      </div>

      {/* Build Your Team */}
      <div className="landing-page__build-your-team">
        <h1 className="landing-page__build-your-team__title">BUILD YOUR TEAM</h1>
        <div className="landing-page__build-your-team__desc">
          Porttitor in ut felis nec sed metus, semper mi ante. Sit eget proin potenti scelerisque. Mauris at vel et ipsum. Gravida pulvinar
          vitae ultrices id consequat ac. Sem in sed enim adipiscing neque, rutrum sagittis, adipiscing.
        </div>
        <div className="landing-page__build-your-team__image">
          <div className="mobile-container">
            <MobileComponent img={Mob1} text="Meet Your Team" textInTop={false} className="mobile-component__container-first" />
            <MobileComponent img={Mob2} text="Discuss PROMO PLANS" textInTop={true} />
            {/* <div className="hide-on-mobile"> */}
            <MobileComponent img={Mob3} text="Go Live & Direct" textInTop={false} />
            {/* </div> */}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="landing-page__footer">
        <div className="footer-bg"></div>
        <div className="footer-bg2"></div>
        <div className="landing-page__footer-first-container">
          <img src={SocialMediaGroup} className="landing-page__footer-first-container__img hide-on-mobile" alt="social media share" />
          <div className="landing-page__footer-first-container__start-now">
            <div className="start-now__text">GET MORE STREAMS, LIKES & SHARES ORGANICALLY</div>

            <Link to="/pricing">
              <button className="start-now__btn">START NOW</button>
            </Link>
          </div>
          <img src={SocialMediaGroup} className="landing-page__footer-first-container__img show-on-mobile" alt="social media share" />
        </div>

        <div className="landing-page__footer-second-container__wrapper">
          <div className="landing-page__footer-second-container">
            <div className="landing-page__footer-second-container-dream">
              <div className="dream__text">
                THE ONLY WAY TO <span className="purple">LIVE THE DREAM</span> IS TO GET A TEAM
              </div>
              <Link to="/pricing">
                <button className="level-up__btn">I’M READY TO LEVEL UP</button>
              </Link>
            </div>
          </div>

          <div className="landing-page__footer-third-container">
            <div className="copyright-container">
              <BreakerLogo2 style={{ height: "69.77px" }} />
              <div className="footer__rights">Copyright © 2001-2022 Breaker Nation. All Rights Reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
