import {
  GENRES_REQUEST_SUCCESS,
  RESET_MUSICIAN_FLOW_INFO,
  SELECTED_GENRES_UPDATE,
  SELECTED_STYLE_UPDATE,
  SELECTED_TAGS_UPDATE,
  SET_MUSICIAN_FLOW_INFO,
  STYLE_REQUEST_SUCCESS,
  TAGS_REQUEST_SUCCESS,
} from "../actions/preferencesActions";
import {LOGOUT_SUCCESS} from "../actions/userActions";

const initialState = {
  genres: [],
  tags: [],
  selectedGenres: [],
  selectedTags: [],
  styles: [],
  selectedStyles: [],
  musicianFlowInfo: {
    countryCode: '',
    selectedStyles: [],
    selectedGenreMultiple: [],
    selectedGenre: {},
    selectedCover: {},
    selectedFile: {},
    email: '',
    artistName: '',
    emailAddress: '',
    trackName: '',
    displayName: '',
    userName: '',
    phoneNumber: '',
    feedbackId: '',
  }
};

export default (state = initialState, action) => {
  const handlers = {
    [GENRES_REQUEST_SUCCESS]: {...state, genres: action.payload},
    [TAGS_REQUEST_SUCCESS]: {...state, tags: action.payload},
    [STYLE_REQUEST_SUCCESS]: {...state, styles: action.payload},
    [SELECTED_GENRES_UPDATE]: {...state, selectedGenres: action.payload},
    [SELECTED_TAGS_UPDATE]: {...state, selectedTags: action.payload},
    [SELECTED_STYLE_UPDATE]: {...state, selectedStyles: action.payload},
    [SET_MUSICIAN_FLOW_INFO]: {
      ...state,
      musicianFlowInfo: {
        ...(state?.musicianFlowInfo || {}),
        ...(action?.payload || {})
      }
    },
    [RESET_MUSICIAN_FLOW_INFO]: {...state, musicianFlowInfo: {}},
    [LOGOUT_SUCCESS]: initialState,
  };

  return handlers[action.type] || state;
};
