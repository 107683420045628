import React from "react";
import styled from "styled-components";

// components
import { AdvantagesCard } from "../../components/NewInternship";

const SecondParagraph = () => {
  return (
    <Wrapper>
      <Title>Launch Your Career on Breaker Nation</Title>
      <InnerWrapper>
        <AdvantagesCard />
        <Certificate src="assets/certificate.svg" />
      </InnerWrapper>
    </Wrapper>
  );
};

export default SecondParagraph;

const Wrapper = styled.div`
  margin: 15vh 2.77vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    margin: 100px 36px;
  }
`;

const Title = styled.text`
  font-style: normal;
  font-weight: 900;
  font-size: 2vw;
  line-height: 5.8vh;
  text-align: center;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 40px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8vh;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 40px;
  }
`;

const Certificate = styled.img`
  width: 44.44vw;
  height: 31.38vw;
  margin-left: 8.66vw;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-bottom: 52px;
  }
`;
