import "./internpayment.style.scss";
import React, { useState } from "react";
import { CardNumberElement, CardCVCElement, CardExpiryElement, injectStripe } from "react-stripe-elements";
import content from "../PaymentDrawer/content";
import InputField from "../../common/InputField";
import ToggleSwitch from "../../common/ToggleSwitch";
import Button from "../../common/Button";
import { handleEnrollment } from "../../state/actions/internActions";
import { toast } from "react-toastify";

const InternPayment = ({
  stripe,
  handlePaymentTokenReceived,
  amount,
  rememberPaymentMethod,
  handleRememberPayment,
  addPaymentMethod,
  selectedCard,
  isFree,
  setShowEnrollmentComplete,
}) => {
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePay = async () => {
    setLoading(true);
    if (isFree) {
      try {
        await handleEnrollment({
          payment_token: "",
          save_card: false,
          executive_internship: false,
          creative_internship: false,
        });
        setShowEnrollmentComplete(true);
      } catch (err) {
        toast.error("Couldn't complete your request, please try again");
        throw err;
      }
    }
    if (!addPaymentMethod && selectedCard) {
      handlePaymentTokenReceived(selectedCard);
      return;
    }
    if (!accountName) {
      return;
    }
    const { token } = await stripe.createToken({ name: accountName });
    if (token) {
      handlePaymentTokenReceived(token);
    }
    setLoading(false);
  };

  const onInputChange = (e) => {
    setAccountName(e.target.value);
  };

  return (
    <div className="payment-container">
      <div className="payment-main-container">
        {addPaymentMethod && (
          <>
            <section className="card-details-entry">
              <div className="card-name-title">{content.NAME_ON_CARD}</div>
              <InputField
                id="accountName"
                value={accountName}
                onChange={onInputChange}
                className="card-name-title-input"
                placeholder={content.NAME_PLACEHOLDER}
                style={{ base: { color: "#fff" } }}
              />
              <div className="card-name-title pt24">{content.CARD_NUMBER_LABEL}</div>
              <CardNumberElement
                className="card-name-title-input"
                placeholder={content.CARD_NUMBER_PLACEHOLDER}
                style={{ base: { color: "#fff" } }}
              />
              <section className="card-expiry-cvc">
                <aside className="left">
                  <div className="card-name-title">{content.EXPIRY_DATE_LABEL}</div>
                  <CardExpiryElement
                    className="card-name-title-input"
                    placeholder={content.EXPIRY_DATE_PLACEHOLDER}
                    style={{ base: { color: "#fff" } }}
                  />
                </aside>
                <aside className="right">
                  <div className="card-name-title">{content.CVC_LABEL}</div>
                  <CardCVCElement
                    className="card-name-title-input"
                    style={{ base: { color: "#fff" } }}
                    placeholder={content.CVC_PLACEHOLDER}
                  />
                </aside>
              </section>
            </section>

            <section className="remember-card">
              <aside className="left">{content.REMEMBER_CARD_TITLE}</aside>
              <aside className="right">
                <ToggleSwitch checked={rememberPaymentMethod} onChange={handleRememberPayment} />
              </aside>
            </section>
          </>
        )}

        <section className="pay-button">
          <Button onClick={handlePay} loading={loading} className="pay-button-element" buttonText={"PAY $ " + amount + " & ENROLL"} />
        </section>
      </div>
    </div>
  );
};

// const CardNumber = ({ cardDetails, selected, handleClick }) => {
//   return (
//     <div className="card-item">
//       <aside>
//         <CustomCheckbox onClick={handleClick} selected={selected} />
//       </aside>
//       <aside className="card-item-right">****{cardDetails?.last4}</aside>
//     </div>
//   );
// };

export default injectStripe(InternPayment);
