import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import Button from "../../common/Button";
import { STRIPE_KEY } from "../../config";
import { getPaymentMethods } from "../../state/actions/userActions";
import { free, rising, risingYearly } from "../Pricing/PricingComponent";
import pricingContent from "../Pricing/pricingContent";
import PaymentPlanCard from "./PaymentPlanCard";
import { PLANS } from "./plans";
import StripePaymentComponent from "./StripePaymentComponent";

export default function SignUpPayment(props) {
  const { MONTHLY, ANNUAL, LIFE_TIME } = pricingContent.interval;

  const { Starter: MONTHLY_STARTER, DreamTeam: MONTHLY_DREAM_TEAM, Business: MONTHLY_BUSINESS } = MONTHLY.plans;
  const { Starter: ANNUAL_STARTER, DreamTeam: ANNUAL_DREAM_TEAM, Business: ANNUAL_BUSINESS } = ANNUAL.plans;
  const { Free: FREE } = LIFE_TIME.plans;

  const [pricingPlanInterval, setPricingPlanInterval] = useState(ANNUAL.type);

  const isAnnual = pricingPlanInterval === ANNUAL.type;
  const isMonthly = pricingPlanInterval === MONTHLY.type;

  const STARTER = isAnnual ? ANNUAL_STARTER : MONTHLY_STARTER;
  const DREAM_TEAM = isAnnual ? ANNUAL_DREAM_TEAM : MONTHLY_DREAM_TEAM;
  const BUSINESS = isAnnual ? ANNUAL_BUSINESS : MONTHLY_BUSINESS;

  //   // PLAN IDs
  //   const FREE_ID = PLANS.FREE["id"];
  //   const DREAM_TEAM_ID = PLANS.DREAM_TEAM["id"];
  //   const STARTER_ID = PLANS.STARTER["id"];
  //   const BUSINESS_ID = PLANS.BUSINESS["id"];

  // PLAN {price: 49.99, priceId: '', title: ''}
  const FREE_PLAN = PLANS.FREE[`${pricingPlanInterval}`];
  const STARTER_PLAN = PLANS.STARTER[`${pricingPlanInterval}`];
  const DREAM_TEAM_PLAN = PLANS.DREAM_TEAM[`${pricingPlanInterval}`];
  const BUSINESS_PLAN = PLANS.BUSINESS[`${pricingPlanInterval}`];

  const [selectedPlan, setSelectedPlan] = useState(DREAM_TEAM_PLAN);
  const history = useHistory();
  //   const [id, setId] = useState(FREE_ID);

  const handlePricingPlanIntervalChange = (type) => {
    if (type === MONTHLY.type) {
      setPricingPlanInterval(MONTHLY.type);
    } else if (type === ANNUAL.type) {
      setPricingPlanInterval(ANNUAL.type);
    }
    // setSelectedPlan(FREE_PLAN);
  };

  const handleChange = (plan) => {
    setSelectedPlan(plan);
    // setId(id);
    // console.log({
    //   plan,
    //   id,
    // });
  };

  const userOptions = React.useMemo(() => props.userOptions, [props.userOptions]);

  useEffect(() => {
    if (userOptions.interval === MONTHLY.type) {
      setPricingPlanInterval(MONTHLY.type);
    } else if (userOptions.interval === ANNUAL.type) {
      setPricingPlanInterval(ANNUAL.type);
    } else {
      setPricingPlanInterval(ANNUAL.type);
    }
    if (userOptions.plan === FREE_PLAN.title) {
      setSelectedPlan(FREE_PLAN);
    } else if (userOptions.plan === STARTER_PLAN.title) {
      setSelectedPlan(STARTER_PLAN);
    } else if (userOptions.plan === DREAM_TEAM_PLAN.title) {
      setSelectedPlan(DREAM_TEAM_PLAN);
    } else if (userOptions.plan === BUSINESS_PLAN.title) {
      setSelectedPlan(BUSINESS_PLAN);
    } else {
      setSelectedPlan(DREAM_TEAM_PLAN);
    }
    console.log(userOptions, FREE_PLAN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ANNUAL.type, MONTHLY.type, userOptions.interval, userOptions.plan]);

  //   const [addPaymentMethod, setAddPaymentMethod] = React.useState(false);
  //   const paymentMethods = useSelector((state) => state.userDetails.paymentMethods);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPaymentMethods());
  }, [dispatch]);

  return (
    <div className="sign-up__payment">
      <br />
      <br />
      <di className="sign-up__cards">
        <PaymentPlanCard {...free} handleChange={handleChange} selectedPlan={selectedPlan} plan={FREE_PLAN} />
        <PaymentPlanCard {...rising} handleChange={handleChange} selectedPlan={selectedPlan} plan={STARTER_PLAN} />
        <PaymentPlanCard {...risingYearly} special handleChange={handleChange} selectedPlan={selectedPlan} plan={DREAM_TEAM_PLAN} />
      </di>

      {selectedPlan.title === "Free plan" ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 16,
            }}
            className="payment-pay-btn__container"
          >
            <Button onClick={() => history.push("/dashboard")} className="pay-button-element payment-pay-btn" buttonText={"Continue"} />
          </div>
        </div>
      ) : (
        <div className="stripe-container">
          <div className="stripe-comp">
            <StripeProvider apiKey={STRIPE_KEY}>
              <Elements>
                <StripePaymentComponent
                  setShowSuccessScreen={props.setShowSuccessScreen}
                  setShowFailureScreen={props.setShowFailureScreen}
                  plan={selectedPlan}
                  interval={pricingPlanInterval}
                />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      )}
    </div>
  );
}
