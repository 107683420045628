import React, { useState } from "react";
import { Link } from "react-router-dom";
import RightGrewArrow from "../../assets/img/home/rightGreyArrow.png";
import "./home.styles.scss";
import Button from "../../common/Button";
import FModal from "./ui/FModal";
import comma from "comma-number";

import crowdImage from "../../assets/landing/crowd.png";
import { useRef } from "react";
import SideDrawer from "./ui/SideDrawer";
import HomeNav from "./ui/HomeNav";
import HomeFooter from "./ui/HomeFooter";
import { onGetTotalUserCount } from "../../state/actions/userActions";
import { useEffect } from "react";
import { axiosInstance } from "../../utils/axiosInstance";
import VModal from "./ui/VModal";
import styled from "styled-components";
import { Intern } from "./Intern";

const networkItems = ["umg", "tidal", "sony", "beatroot", "disnep", "aftermath"];

const HomeLayout = ({ children }) => {
  const scrollRef = useRef();
  const [showFModal, setShowFModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenuOnSidebar, setShowMenuOnSidebar] = useState(false);
  const [userCount, setUserCount] = useState(localStorage.getItem("user-count") || 1150);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [showVideo, setShowVideo] = useState(false);

  const [email, setEmail] = useState("");
  const [subLoading, setSubLoading] = useState(false);

  const onSubscribe = async () => {
    if (subLoading) return;
    setSubLoading(true);
    try {
      await axiosInstance.post("/v1/users/subscribe", { email });
      setEmail("");
      setSubLoading(false);
    } catch (err) {
      setSubLoading(false);
    }
  };

  useEffect(() => {
    if (!showSidebar) {
      setShowMenuOnSidebar(false);
    }
  }, [showSidebar]);

  const fetchCount = async () => {
    try {
      const res = await onGetTotalUserCount();
      localStorage.setItem("user-count", res?.users);
      setUserCount(res?.users || 1137);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCount();
  }, []);

  const handleLearnMore = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <HomeLayoutWrapper>
      <div className="home-wrapper">
        <HomeNav onMenuClick={() => setShowSidebar(true)} onMenuClose={() => setShowSidebar(false)} showSidebar={showSidebar} />

        <main>
          <section className="hero">
            <div className="container-wrap">
              <div className="title">
                Build a music career <br /> with people ready <br /> to help you <span>WIN</span>
              </div>

              <div className="subtitle">We connect you with fans to help promote your music.</div>

              <div className="button-row">
                <Link to="/pricing">
                  <Button className="btn-signup" buttonText={"GET STARTED NOW"} />
                </Link>
                <Button onClick={handleLearnMore} className="btn-learnmore" buttonText={"LEARN MORE"} />
              </div>

              <div className="crowd">
                <img src={crowdImage} alt="" />
              </div>

              <div className="info-text">
                <span>
                  Join {comma(userCount)} music creators, fans & professionals <br />
                  <u style={{ cursor: "pointer" }} onClick={() => setShowFModal(true)}>
                    Read Felisha's letter to you{" "}
                  </u>
                  <img src={RightGrewArrow} />
                </span>
              </div>
            </div>
          </section>
          <div className="container-wrap">
            <section className="full-picture">
              <HomeTitle m="70px 0" title="What's Keeping You From Success in Music?" />
              <img src="/svg/section_2.png" alt="" />
              <div style={{ marginTop: -30 }}>
                <Link to="/pricing">
                  <button className="get-started">GET STARTED NOW</button>
                </Link>
              </div>
            </section>

            <section className="interns-fans full-picture">
              <HomeTitle m="70px 0 0 0" title="Get Fans & Interns On Your Team" />
              <div className="subtitle center">We expose you to music lovers that help you to...</div>
              <Intern/>
              <div>
                <Link to="/pricing">
                  <button className="get-started">GET STARTED NOW</button>
                </Link>
              </div>
            </section>
          </div>

          <section className="only-text">
            <HomeTitle m="70px 0 70px 0" title="We’re Driven By Your Pursuit" />
            <div className="texts-border">
              <div className="texts">
                <div className="container-wrap">
                  At Breaker Nation our goal is to help musicians build a marketing and street team to help promote their music. We do this
                  by scouting music fans who also want to work in the music industry but lack “formal” experience. They enter into our
                  virtual internship experience. To complete the program, they support musicians on our platform..
                </div>
              </div>
            </div>
          </section>

          <div className="container-wrap">
            <section className="what-makes-different">
              <HomeTitle m="70px 0 70px 0" title="What makes us different?" />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                  gridGap: 16,
                  alignItems: "center",
                }}
              >
                <div>
                  <div className="box-item-border">
                    <div className="box-item">
                      <div className="box-title">Uniqueness</div>
                      <div className="box-desc">We are a single platform that offers a new level of authentic engagement</div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box-item-border">
                    <div className="box-item">
                      <div className="box-title">Stability</div>
                      <div className="box-desc">
                        We’ve spent over 20 years working in music and now we’re designing a new way to succeed
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box-item-border">
                    <div className="box-item">
                      <div className="box-title">Innovation</div>
                      <div className="box-desc">
                        We think outside the box and we’re always remixing the status quo so that it works for you
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section ref={scrollRef} className="how-works">
              <HomeTitle m="70px 0 70px 0" title="How it works?" />
              <div className="phone-grid">
                <div>
                  <img className="phone" src="/svg/works_1.png" alt="" />
                  <div className="phone-title">1. You Share Music</div>
                  <div className="phone-desc">
                    Upload and promote your music for everyone to hear. <br /> <br /> Get feedback on your tracks from a community of music
                    fans, interns and professionals.
                  </div>
                </div>
                <div>
                  <img className="phone" src="/svg/works_2.png" alt="" />
                  <div className="phone-title">2. We Bring Interns</div>
                  <div className="phone-desc">
                    We boost your music to an active community of fans. <br /> <br /> Individuals on our platform are here to support
                    musicians like you and see them win — big!
                  </div>
                </div>
                <div>
                  <img className="phone" src="/svg/works_3.png" alt="" />
                  <div className="phone-title">3. You start headlining</div>
                  <div className="phone-desc">
                    You’re in control, so take the wheel & run it all the way up! <br /> <br /> Surround yourself with people invested in
                    you. You’ll go further and do more than you ever imagined.
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to="/pricing">
                  <button className="get-started">GET STARTED NOW</button>
                </Link>
              </div>
            </section>

            <audio
              ref={audioRef}
              style={{ display: "none" }}
              autoPlay={0}
              src="https://hearbk-upload.s3.amazonaws.com/BobbyonBreakerNation.mp3"
            ></audio>

            <section className="boby-intro">
              <HomeTitle m="70px 0 0 0" title="You're In Good Company" />
              <div className="subtitle center">
                We work with music professionals all over, who recognize our process as the true path to success.
              </div>
              <div className="intro-grid">
                <div style={{ position: "relative" }}>
                  <img src="/svg/boby.png" alt="" />
                  {!isPlaying && (
                    <img
                      onClick={() => {
                        setIsPlaying(true);
                        audioRef.current.play();
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 64,
                        height: 64,
                        cursor: "pointer",
                      }}
                      src="/svg/playIcon.svg"
                      alt=""
                    />
                  )}
                  {isPlaying && (
                    <img
                      onClick={() => {
                        setIsPlaying(false);
                        audioRef.current.pause();
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 64,
                        height: 64,
                        cursor: "pointer",
                      }}
                      src="/svg/pauseIcon.svg"
                      alt=""
                    />
                  )}
                </div>
                <div className="quote-side">
                  <div className="quote">
                    ”We can always ask for help but We can’t always depends on it. Having people dedicated to the artist —
                    <br />
                    <br />I think it’s an amazing idea!!“
                    <span>Bobby Zepplin</span>
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to="/pricing">
                  <button className="get-started">GET STARTED NOW</button>
                </Link>
              </div>
            </section>

            <section className="network">
              <HomeTitle m="70px 0 0 0" title="Our Network" />
              <div className="network-grid">
                {networkItems.map((el) => (
                  <div key={el} className="img-item">
                    <img src={`/svg/${el}.png`} alt="" />
                  </div>
                ))}
              </div>
            </section>

            <section className="cheat-code">
              <div className="cheat-wrapper">
                <div className="content">
                  <div className="cheat-title">DISCOVER THE CHEAT CODE</div>
                  <div className="cheat-desc">
                    The best musicians don’t know everything, but they surely know the cheat code. Subscribe to hear the trailer for The
                    Cheat Code To Promoting Music That Goes Viral, an upcoming podcast launching soon.{" "}
                  </div>

                  <img className="s-mob" src="/images/dark_mob_web.png" alt="" />

                  <div className="actions">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      placeholder="Email Address"
                      className="sub-email"
                    />

                    <div onClick={onSubscribe} className="btn-listen">
                      {subLoading ? "Please wait..." : "Listen Now"}
                    </div>
                  </div>
                </div>
                <img className="h-mob" src="/images/dark_mob_web.png" alt="" />
              </div>
            </section>
          </div>

          {children}

          <HomeFooter
            onMenuClick={() => {
              setShowSidebar(true);
              setShowMenuOnSidebar(true);
            }}
          />
        </main>

        <FModal open={showFModal} onClose={() => setShowFModal(false)}>
          <h1>Modal New</h1>
        </FModal>
        <VModal open={showVideo} onClose={() => setShowVideo(false)} />
        <SideDrawer showClose={showMenuOnSidebar} open={showSidebar} onClose={() => setShowSidebar(false)} />
      </div>
    </HomeLayoutWrapper>
  );
};

export const HomeTitle = ({ title, m }) => {
  return <HomeTitleWrapper m={m}>{title}</HomeTitleWrapper>;
};

const HomeTitleWrapper = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 900;
  color: #c8a86b;
  margin: ${(props) => (props.m ? props.m : "0px")};
`;

const HomeLayoutWrapper = styled.div`
  background-color: #18181a;

  section.full-picture {
    text-align: center;
    img {
      width: 100%;
    }
  }

  .subtitle {
    font-size: 20px;
    font-weight: 300;
    color: #f9f9f9;
    margin-top: 30px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
    &.center {
      text-align: center;
    }
  }

  section.hero {
    margin: 40px 0;
    padding: 70px 0;
    background: url("/svg/hero_bg.png") no-repeat;
    background-position: center;

    .title {
      font-size: 60px;
      font-weight: 900;
      line-height: 71px;
      color: white;
      span {
        color: #c8a86b;
      }

      @media (max-width: 767px) {
        font-size: 36px;
        line-height: 44px;

        br {
          display: none;
        }
      }
    }

    .button-row {
      margin-top: 40px;
      margin-bottom: 40px;
      max-width: calc(100vw - 40px);
      display: flex;

      @media (max-width: 767px) {
        display: block;
      }

      button {
        font-weight: 600 !important;
        color: #000;

        @media (max-width: 767px) {
          margin-top: 20px;
          width: 100%;
        }
      }

      .btn-learnmore {
        background-color: #18181a;
        border: 1px solid #c8a86b;
        color: white;
        height: 55px;
      }
      .btn-signup {
        height: 55px;
        margin-right: 30px;
      }
    }

    .info-text {
      color: #8b8b8c;
      font-weight: 500;

      img {
        height: 8px;
        margin-left: 12px;
      }
      u {
        display: inline-block;
        margin-top: 6px;
      }
    }

    .crowd {
      margin-bottom: 16px;
      img {
        height: 40px;
      }
    }
  }

  section.interns-fans {
  }

  section.only-text {
    .texts-border {
      background: linear-gradient(to bottom, #fe9c28, #2c22d7);
      padding: 1px 0;
    }
    .texts {
      padding: 75px;
      background: #000;
      color: #f9f9f9;
      font-size: 24px;
      line-height: 36px;
      text-align: center;

      @media (max-width: 767px) {
        padding: 75px 10px;
        font-size: 16px;
      }
    }
  }

  section.what-makes-different {
    .box-item-border {
      background: linear-gradient(to bottom, #fe9c28, #2c22d7);
      padding: 0.5px;
      border-radius: 10px;
    }
    .box-item {
      padding: 40px;
      border-radius: 10px;
      background: #000;
      text-align: center;
      color: #f9f9f9;
      font-size: 20px;
      .box-title {
        color: #c8a86b;
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 16px;
      }
    }
  }

  section.how-works {
    .phone-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      grid-gap: 36px;
      justify-items: center;
      text-align: center;
      color: #f9f9f9;
      font-size: 16px;

      img.phone {
        max-width: 230px;
      }
      .phone-title {
        color: #c8a86b;
        font-size: 20px;
        font-weight: 900;
        margin: 20px 0 12px 0;
        max-width: 260px;
      }

      .phone-desc {
        max-width: 260px;
      }
    }
  }

  section.boby-intro {
    .intro-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: #000;
      margin: 40px 0 20px 0;
      border-radius: 10px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }

      img {
        width: 100%;
      }

      .quote-side {
        color: #f9f9f9;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        .quote {
          max-width: 396px;
          span {
            display: block;
            text-align: right;
            margin-top: 16px;
          }
        }
      }
    }
  }

  section.network {
    .network-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-gap: 36px;
      margin: 40px 0;
      justify-items: center;

      img {
        height: 50px;
      }
    }
  }

  section.cheat-code {
    background: linear-gradient(248.85deg, #fe9c28 36.05%, #2c22d7 63.95%);
    padding: 0.5px;
    border-radius: 10px;
    margin: 100px 0 0 0;
    .cheat-wrapper {
      border-radius: 10px;
      background: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 80px 0;

      @media (max-width: 767px) {
        display: block;
        img {
          margin-top: 40px;
          padding: 30px 0;
        }
      }

      .content {
        color: #f9f9f9;
        padding: 75px;
        max-width: 650px;

        @media (max-width: 767px) {
          padding: 25px;
        }
      }

      .cheat-title {
        font-size: 24px;
        color: #c8a86b;
        font-weight: 900;
      }

      .cheat-desc {
        font-size: 18px;
        margin-top: 20px;
        font-weight: 700;
      }

      .actions {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
          display: block;

          input,
          .btn-listen {
            width: 100%;
            margin-bottom: 16px;
          }
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .container-wrap {
    max-width: 80%;
    margin: 0 auto;
    @media (max-width: 767px) {
      max-width: 90%;
    }
  }
`;

export default HomeLayout;
