import React from "react";
import history from "./history";
import Fan from "./containers/Fan";
import Home from "./components/Home";
import Login from "./containers/Login";
import Pricing from "./containers/Pricing";
import FourOFour from "./components/FourOFour";
import RoomPreview from "./components/RoomPreview";
import InitialSetup from "./containers/InitialSetup";
import { Route, Router, Switch } from "react-router-dom";
import DashboardContainer from "./containers/Dashboard";
import { getTokenDetails } from "./state/actions/userActions";
import MusicianConfirm from "./containers/Musician/Confirm";
import MusicianCongrats from "./containers/Musician/Congrats";
import MusicianVerification from "./containers/Musician/PhoneVerify";
import MusicianPaymentSuccess from "./containers/Musician/PaymentSuccess";
import Landing from "./containers/Landing";
import Signup from "./containers/Signup";
import Podcast from "./containers/Podcast";
import InternshipSignUpPage from "./containers/Internship/Signup";
import ApplyPage from "./containers/Internship/Apply";
import Congratulations from "./components/Internship/Congratulations";
import MerchPage from "./containers/Merch";
import InternshipLandingPage from "./components/Internship/Landing";
import ApplicationSuccess from "./components/Internship/ApplicationSuccess";
import EnrollmentComplete from "./components/Internship/EnrollmentComplete";
import EnrollmentDeclined from "./components/Internship/EnrollmentDeclined";
import NewInternship from "./containers/NewInternship";

export const MenuHandlerContext = React.createContext();

export default () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/home" component={Fan} exact />
        <Route path="/pricing" component={Pricing} exact />
        <Route path="/musicians" component={Landing} exact />
        <Route path="/musicians/verify" component={MusicianVerification} exact />
        <Route path="/musicians/congrats" component={MusicianCongrats} exact />
        <Route path="/musicians/confirm" component={MusicianConfirm} exact />
        <Route path="/musicians/payment/success" component={MusicianPaymentSuccess} exact />
        <Route path="/event/:roomId" component={RoomPreview} exact />
        <Route path="/dashboard" component={withValidToken(DashboardContainer)} />
        <Route path="/initial-setup" component={withValidToken(InitialSetup)} />
        <Route path="/login" component={Login} exact />
        <Route path="/signup" component={Signup} />
        <Route path="/music-internships" component={InternshipLandingPage} exact />
        <Route path="/internship/signup" component={InternshipSignUpPage} />
        <Route path="/internship/apply" component={withValidToken(ApplyPage, "/internship/signup")} />
        <Route path="/internship/applied" component={withValidToken(ApplicationSuccess)} />
        <Route path="/internship/enroll" component={withValidToken(Congratulations)} />
        <Route path="/internship/success" component={withValidToken(EnrollmentComplete)} />
        <Route path="/internship/complete" component={withValidToken(EnrollmentDeclined)} />
        <Route path="/podcast" component={Podcast} />
        <Route path="/merch" component={MerchPage} />
        {/* <Route path="/internship" component={NewInternship} /> */}
        <Route path="*" component={FourOFour} />
      </Switch>
    </Router>
  );
};

const withValidToken = (WrappedComponent, redirect_url) => {
  const returnUrl = window.location.pathname;

  return class extends React.Component {
    componentDidMount() {
      const _url = redirect_url ? redirect_url : "/login?return_url=" + returnUrl;
      window.scrollTo(0, 0);
      // const isAccessTokenEntered = localStorage.getItem("access-token-entered");
      // if (!isAccessTokenEntered) {
      //   history.push("/access-code");
      //   return;
      // }
      if (!localStorage.getItem("x-access-token")) {
        history.push(_url);
      }
      if (!localStorage.getItem("access-token-verified")) {
        (async () => {
          try {
            const response = await getTokenDetails();
            if (!response.ok) {
              localStorage.removeItem("x-access-token");
              localStorage.removeItem("isPremiumUser");
              localStorage.removeItem("isFirstUserLogin");
              localStorage.removeItem("access-token-verified");
              history.push(_url);
            } else {
              localStorage.setItem("access-token-verified", "true");
            }
          } catch (e) {}
        })();
      }
    }
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};
