import React from "react";
import styled from "styled-components";

const AdvantagesCard = () => {
  return (
    <Wrapper>
      <SingleCardWrapper>
        <Icon src="assets/Vector.svg" />
        <TextWrapper>
          <Title>Make Connections</Title>
          <Paragraph>
            Meet and network with like <br />
            minded professionals <br />
            in the music industry
          </Paragraph>
        </TextWrapper>
      </SingleCardWrapper>
      <Seperator />
      <SingleCardWrapper>
        <Icon src="assets/Group.svg" />
        <TextWrapper>
          <Title>Develop Skills</Title>
          <Paragraph>
            Apply your dream skillset, <br />
            develop new ones and <br />
            work with actual artists
          </Paragraph>
        </TextWrapper>
      </SingleCardWrapper>
      <Seperator />
      <SingleCardWrapper>
        <Icon src="assets/business.svg" />
        <TextWrapper>
          <Title>Be Creative</Title>
          <Paragraph>
            Have the freedom to <br />
            contribute original ideas that <br />
            showcase your strengths
          </Paragraph>
        </TextWrapper>
      </SingleCardWrapper>
    </Wrapper>
  );
};

export default AdvantagesCard;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SingleCardWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 1.77vw;
  @media (max-width: 425px) {
    margin-right: 17.6px;
    height: 46px;
    width: 46px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 1.11vw;
  line-height: 2.4vh;
  text-transform: uppercase;
  color: #c8a86b;
  margin-bottom: 2.4vh;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
  }
`;

const Paragraph = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 140%;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const Seperator = styled.div`
  margin: 5vh 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #c8a86b, #5634c2);
  @media (max-width: 768px) {
    width: 60%;
    margin: 24px 0;
  }
  @media (max-width: 425px) {
    margin: 24px 0;
    width: 100%;
  }
`;
