import React from "react";
import felishaImage from "../../assets/landing/felisha.png";
import arrowIco from "../../assets/landing/arrow.svg";
import closeIco from "../../assets/landing/close.svg";
import { Link } from "react-router-dom";
import HModal from "../Home/ui/HModal";
import KScrollBar from "../Home/ui/KScrollBar";

export default function FInternshipModal({ open, onClose }) {
  return (
    <HModal open={open} onClose={onClose}>
      <div className="image-section">
        <img className="feli-img" src={felishaImage} alt="" />
      </div>
      <div className="f-modal-wrapper">
        <KScrollBar>
          <div onClick={onClose} className="close">
            <img src={closeIco} alt="" />
          </div>
          <h1 className="title">
            Hey 👋 I'm Felisha, the founder <br /> of Breaker Nation
          </h1>

          <div className="content">
            <p>
              Learning about the music business is easy. Getting experience, so that you can get your foot in the door is the hard part.
              <br />
              <br />
              No matter when you discovered your passion to work in music: There has never been an easy path for a newcomer to build skills,
              network and showcase themselves. But of course, you know that, otherwise you wouldn't be here. 😉
              <br />
              <br />
              As a person with no experience, getting hired in the music industry is extremely difficult. Jobs in this space are highly
              coveted. Recruiters receive thousands of applications for every position and standing out from everyone else is the hardest
              it's ever been.
              <br />
              <br />
              And that's why we're here to help.
              <br />
              <br />
              As an entrepreneur who's been in the music industry for over 20 years and literally went from having no connections to major
              connections — I know how the culture of this business operates. I've educated, connected, promoted and given opportunities to
              thousands of professionals all around the globe — a few I could even name drop, but I'm not really into all that. 🙂
              <br />
              <br />
              Empowering the next generation of professionals means everything to me; it's the only way the music industry will evolve for
              the better.
              <br />
              <br />
              That's why I created Breaker Nation — to take everything I've learned about the entire music ecosystem and use that
              intelligence to build a new type of social network that helps you get credible work experience.
              <br />
              <br />
              You won't find an innovative platform like this anywhere else. If you have a little experience or none at all, I know Breaker
              Nation can help you get your next level. If this strikes a chord with you, submit your application today! 🙏
            </p>

            <Link to="/internship/signup">
              <button className="btn-grow-fanbase">
                <span>APPLY FOR INTERNSHIP</span>
                <img src={arrowIco} alt="" />
              </button>
            </Link>
          </div>
        </KScrollBar>
      </div>
    </HModal>
  );
}
