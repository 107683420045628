import React from "react";
import styled from "styled-components";

const Menu = () => {
  return (
    <Wrapper>
      <Line />
      <Line />
      <Line />
    </Wrapper>
  );
};

export default Menu;

const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.22vw;
  height: 1.44vw;
  border: none;
  padding: 0;
  background: transparent;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 0.2s linear;
  :hover {
    transform: scale(1.5);
  }
  @media (max-width: 768px) {
    width: 30px;
    height: 20px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: #c8a86b;
`;
