export default {
  INFLUENCER_SETTINGS: "Professional settings",
  APPROVAL_RATING: "Quality Control Filter",
  APPROVAL_FILTER_CONTENT: "In order to control the volume and quality of direct requests you receive, please set your filter.",
  SPECIFY_REVIEW_PRICE: "Specify your price for a review",
  SEND_ME: "Send me",
  PAYMENT_RECEIVE_METHOD: "Payment receiving method",
  TRANSACTION_HISTORY: "Transaction history",
  NAME_ON_CARD: "Cardholder name",
  NAME_PLACEHOLDER: "JOHN DOE",
  CARD_NUMBER_LABEL: "Card number",
  CARD_NUMBER_PLACEHOLDER: "1234 4567 7890 1234",
  EXPIRY_DATE_LABEL: "Valid until",
  EXPIRY_DATE_PLACEHOLDER: "MM/YY",
  CVC_LABEL: "CVC",
  CVC_PLACEHOLDER: "111",
  SAVE_CARD_TEXT: "Save this card for future use",
  ADD_PAYMENT: "Add payment method",
  GET_ACCESS: "You will get access to this section as soon as you receive professional status.",
};
