import React from "react";
import "./navFooterStyle.scss";
import breakerLogo from "../../../assets/landing/logo.png";
import menuIco from "../../../assets/landing/menu.svg";
import { Link } from "react-router-dom";
import AnimatedMenu from "./AnimatedMenu";

export default function HomeNav({ onMenuClick, onMenuClose, showSidebar, showMenu = true, ...rest }) {
  return (
    <header className="nav-wrap" style={rest.style}>
      <Link to="/">
        <div className="logo">
          <img src={breakerLogo} alt="" />
          {/* <p className="h-mob tagline">Fanbase Marketing Machine</p> */}
        </div>
      </Link>

      <div className="links h-mob">
        <ul>
          <li>
            <Link to="/music-internships">Virtual Internships</Link>
          </li>
          <li>
            <Link to="/pricing">Membership Plans</Link>
          </li>
          <li>
            <Link to="/login">Log In</Link>
          </li>
        </ul>
      </div>

      {/* <img onClick={onMenuClick} className="menu" src={menuIco} alt="" /> */}
      {showMenu && <AnimatedMenu onMenuClick={onMenuClick} onMenuClose={onMenuClose} showSidebar={showSidebar} {...rest} />}
    </header>
  );
}
