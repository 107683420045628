import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

// components
import { HeaderText, Members, StepsCard } from "../../components/NewInternship";
import { StyledButton } from "../../common";

// animation
import internshipLandingAnimationData from "../../animations/internshiplanding/data";

const FirstParagraph = () => {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: internshipLandingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Wrapper>
      <Animation>
        <Lottie options={defaultOptions1} speed={1.5} />
      </Animation>
      <HeaderText />
      <StyledButton title={"Apply Now"} onClick={() => {}} />
      <StepsCard />
      <Members />
    </Wrapper>
  );
};

export default FirstParagraph;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 20;
  padding-top: 7vh;
  @media (max-width: 768px) {
    padding-top: 2vh;
    align-items: center;
  }
`;

const Animation = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  height: 100vh;
  width: 70vw;

  @media (max-width: 768px) {
    height: 100vw;
    width: 100vw;
    top: 5vh;
  }

  @media (max-width: 425px) {
    height: 110vw;
    width: 100vw;
    top: 10vh;
  }
`;
