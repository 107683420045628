import React, { useState } from "react";
import ExploreFeature from "./ExploreFeature";
import downArrow from "../../assets/img/pricing/down-arrow.svg";
import "./pricing.style.scss";

export default function PlanCardExploreFeatures(props) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((open) => !open);
  };
  return (
    <div className="pricing-plan__explore-features" style={{ ...props.style, marginTop: open ? 0 : props?.style?.marginTop }}>
      <span className="pricing-plan__explore-features__title" onClick={handleClick}>
        Explore all features
        <span className="faq-arrow">
          <img src={downArrow} alt="up-arrow" className={`faq-arrow__img ${open ? "faq-arrow__down" : "faq-arrow__up"}`} />
        </span>
      </span>
      <div className="pricing-plan__explore-features__container">
        {open && (
          <div className="explore-features">
            {props.exploreFeatures.map((item, i) => (
              <div className="explore-feature" key={`priceexpfeat${i}`}>
                <ExploreFeature title={item.title} description={item.description} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
