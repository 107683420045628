import React from "react";
import "./musician.style.scss";
import "./musician.style.scss";
import content from "./content";
import { ReactComponent as Breaker } from "../../assets/img/musician/breaker_nation.svg";
import "react-phone-number-input/style.css";
import ReviewCard from "./ReviewCard";
import PaymentPlan from "./PaymentPlan";

export default function Congratulations(props) {
  return (
    <>
      <section className="beta-txt-container">
        <div className="beta-txt-sub-container">
          <span className="beta-txt">CONGRATULATIONS!</span>
        </div>
        <p className="congratulation-txt">
          <b>YOU</b> took the first step to getting your music heard by the right people! Your track is now previewing in our Queue for 100
          music fans, creators and professionals <b>WHO WANT TO DISCOVER NEW MUSIC</b>. Listeners will rate your track and optionally leave
          a review.
          <br />
          <br />
          Since we’re a <b>MUSICIAN'S FIRST</b> company, for your first upload, we’ll share some of the analytics with you <b>FREE</b> of
          charge! If you’d like full access to the entire track report, you can <u>buy it</u> or <u>go premium</u> for <b>INSTANT ACCESS</b>
          . The full report includes every single review we’ve collected from listeners. If you subscribe, you’ll unlock access to premium
          member benefits, discounts of future uploads and much more!
        </p>
      </section>
      <section className="congratulation-payment-section">
        <PaymentPlan
          handlePaymentSelection={props.handlePaymentSelection}
          title="STARTER-CURRENT PLAN"
          description={
            <span>
              NO THANKS <br /> I’ll stick with the free report for now
            </span>
          }
          features={["All future uploads are $9"]}
          plans={[{ label: "$0 KEEP CURRENT PLAN", value: 0 }]}
        />
        <PaymentPlan
          handlePaymentSelection={props.handlePaymentSelection}
          title="I’M CURIOUS - BUY REPORT"
          description={<span>SHOW ME ALL THE REVIEWS!</span>}
          features={["All future uploads are $9"]}
          plans={[{ label: "$9 Track report", value: 9, isAddPremium: false, isYearlySubscription: false }]}
        />
        <PaymentPlan
          handlePaymentSelection={props.handlePaymentSelection}
          title="I’M COMMITTED - GO PREMIUM"
          description={<span>SIGN ME UP</span>}
          features={["Read all reviews", "Premium member benefits", "All future uploads are only $5"]}
          plans={[
            { label: "$15 Monthly", value: 15, isAddPremium: true, isYearlySubscription: false },
            { label: "$100 yearly", value: 100, isAddPremium: true, isYearlySubscription: true },
          ]}
        />
      </section>
    </>
  );
}
