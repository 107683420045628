import React from "react";
import styled from "styled-components";

// components
import { StyledButton } from "../../common";

const FourthParagraph = () => {
  return (
    <Wrapper>
      <Title>
        We are now seeking bright and self motivated interns to work hard, have fun and be apart of creating the future of the entire music
        industry! <br />
        <br />
        Join us!
      </Title>
      <StyledButton title="APPLY NOW" onClick={() => {}} />
      <Text>
        Still have questions?
        <Link>Write us an e-mail!</Link>
      </Text>
    </Wrapper>
  );
};

export default FourthParagraph;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 13vh 2.77vw;
  background-color: rgba(0, 0, 0, 0.6);
  @media (max-width: 768px) {
    padding: 100px 36px;
  }
`;

const Title = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.55vw;
  line-height: 148.06%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 4.4vh;
  max-width: 57.77vw;
  @media (max-width: 768px) {
    max-width: 80vw;
    font-size: 16px;
  }
`;

const Text = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.88vw;
  line-height: 2.4vh;
  color: #ffffff;
  margin-top: 6vh;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const Link = styled.a`
  color: #c8a86b;
  margin-left: 5px;
  :hover {
    font-weight: bold;
    color: #c8a86b;
  }
`;
