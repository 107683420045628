import React, { useCallback, useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as BreakerLogo2 } from "../../assets/icon/breaker-logo-2.svg";
import { setPeopleMixpanel, trackMixpanel } from "../../mixpanel";
import { phoneAuthenticationVerify, registerUserAction } from "../../state/actions/userActions";
import { validateRegex } from "../../utils";
import { INITIAL_SETUP_TABS, TAB_DETAILS } from "./constants";
import CreateAccount from "./CreateAccount";
import ModifiedCustomSliderTab from "./ModifiedCustomSliderTab";
import SignUpPayment from "./Payment";
import "./signup.style.scss";
import VerifyPhone from "./VerifyPhone";
import mixpanel_constant from "../../mixpanel/mixpanel.constants";
import SuccessSignup from "./SuccessSignup";
import ErrorSignup from "./ErrorSignup";

export default function SignupComponent({ tabValue, setTabValue, userOptions }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    user_name: "",
    email: "",
    display_name: "",
  });

  const [resendTime, setResendTime] = useState(0);

  const [errors, setErrors] = useState({
    user_name: false,
    email: false,
    display_name: false,
    phoneNumber: false,
    otp: false,
    userExist: false,
    message: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(localStorage.getItem("country-code") || "+1");

  const resetError = useCallback(() => {
    setErrors({ ...errors, message: "", phoneNumber: false });
  }, [errors]);

  const handleChange = (event) => {
    if (event.target.id) {
      setState({ ...state, [event.target.id]: event.target.value });
    }
  };

  const handleOnChangePhoneNumber = useCallback(
    (e) => {
      errors.phoneNumber && resetError();
      setPhoneNumber(e.target.value);
    },
    [setPhoneNumber, errors, resetError]
  );

  const handleOnChangeCountryCode = useCallback(
    (e) => {
      setCountryCode(e.target.value);
      localStorage.setItem("country-code", e.target.value);
    },
    [setCountryCode]
  );

  const handleOnSubmit = useCallback(async () => {
    const data = {
      phone_number: `${countryCode}${phoneNumber}`,
      email: state.email,
      user_name: state.user_name,
    };

    const isInValidPhoneNumber = !isValidPhoneNumber(data.phone_number);

    const isValid =
      state.user_name.trim() !== "" && state.display_name.trim() !== "" && validateRegex("email", state.email) && !isInValidPhoneNumber;
    console.log(isValid, "isValid", data);
    setErrors({
      ...errors,
      otp: false,
      user_name: state.user_name.trim() === "",
      phoneNumber: isInValidPhoneNumber,
      email: !validateRegex("email", state.email),
      display_name: state.display_name.trim() === "",
    });

    if (isValid) {
      setResendTime(60);

      try {
        const success = await dispatch(registerUserAction(data));
        if (success && isValid) {
          setTabValue(TAB_DETAILS.VERIFY_PHONE);
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        setErrors({
          ...errors,
          userExist: true,
          user_name: errorMessage.includes("Username"),
          phoneNumber: errorMessage.includes("Phone"),
          email: errorMessage.includes("Email"),
          message: errorMessage || "User already exists !",
        });
      }
    }
  }, [countryCode, phoneNumber, state.email, state.user_name, state.display_name, errors, dispatch, setTabValue]);

  const handleRegister = async (phoneNumber, otp) => {
    const data = {
      email: state.email,
      user_name: state.user_name,
      display_name: state.display_name,
      password: "password",
      phoneNumber: `${countryCode}${phoneNumber}`,
      OTP: otp,
    };

    try {
      const resp = await phoneAuthenticationVerify(data);
      if (resp.token) {
        localStorage.setItem("x-access-token", resp.token);
        // history.push("/initial-setup/genres");
        setTabValue(TAB_DETAILS.PAYMENT);
        // setShowPhoneModal(false);
        const { email, user_name } = state || {};
        setPeopleMixpanel({
          email: state.email,
          full_name: state.user_name,
          username: state.display_name,
        });
        trackMixpanel(mixpanel_constant.new_account_created, { email, user_name, platform: "web" });
      }
    } catch (error) {
      setErrors({
        ...errors,
        otp: true,
      });
    }
  };

  const resetOtpError = useCallback(() => {
    setErrors({ ...errors, otp: false });
  }, [errors]);

  useEffect(() => {
    const timer = resendTime > 0 && setInterval(() => setResendTime(resendTime - 1), 1000);
    return () => clearInterval(timer);
  }, [resendTime]);

  //   on success full signup make setShowSuccessScreen(true) & setTabValue(100) & same for error
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [showFailureScreen, setShowFailureScreen] = useState(false);
  return (
    <div className="signup-main-container">
      <div className="signup-header-container">
        <BreakerLogo2 style={{ height: "34px" }} />
        <span className="header-signUp-txt">Sign Up</span>
      </div>
      {showSuccessScreen && <SuccessSignup setShowSuccessScreen={setShowSuccessScreen} />}
      {showFailureScreen && <ErrorSignup setShowFailureScreen={setShowFailureScreen} />}
      <div className="signup-tab-container">
        {!showSuccessScreen && !showFailureScreen && (
          <ModifiedCustomSliderTab tabList={INITIAL_SETUP_TABS} value={tabValue} handleOnChange={() => {}} />
        )}
      </div>
      {showSuccessScreen || showFailureScreen ? null : tabValue === TAB_DETAILS.CREATE_ACCOUNT ? (
        <CreateAccount
          tabValue={tabValue}
          setTabValue={setTabValue}
          state={state}
          handleChange={handleChange}
          errors={errors}
          phoneNumber={phoneNumber}
          handleOnChangePhoneNumber={handleOnChangePhoneNumber}
          handleOnChangeCountryCode={handleOnChangeCountryCode}
          countryCode={countryCode}
          handleOnSubmit={handleOnSubmit}
          resendTime={resendTime}
        />
      ) : tabValue === TAB_DETAILS.VERIFY_PHONE ? (
        <VerifyPhone
          tabValue={tabValue}
          setTabValue={setTabValue}
          phoneNumber={phoneNumber}
          countryCode={countryCode}
          handlePhoneVerify={handleRegister}
          onReSend={handleOnSubmit}
          resetOtpError={resetOtpError}
          errors={errors}
          resendTime={resendTime}
        />
      ) : tabValue === TAB_DETAILS.PAYMENT ? (
        <SignUpPayment
          setShowSuccessScreen={setShowSuccessScreen}
          setShowFailureScreen={setShowFailureScreen}
          tabValue={tabValue}
          setTabValue={setTabValue}
          userOptions={userOptions}
        />
      ) : null}
    </div>
  );
}
