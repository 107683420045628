import React, { useLayoutEffect, useState } from "react";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
import breakerLogo from "../../assets/landing/logo.png";
import appStore from "../../assets/landing/app_store.png";
import playStore from "../../assets/landing/play_store.png";

import "./applicationSuccess.style.scss";
import { Link } from "react-router-dom";

export default function ApplicationSuccess() {
  const [showSidebar, setShowSidebar] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="application-success">
      <HomeNav onMenuClick={() => setShowSidebar(true)} onMenuClose={() => setShowSidebar(false)} showSidebar={showSidebar} />
      <div className="container">
        <div className="success-wrapper">
          <div className="received-text__container">
            <h1>Application Received!</h1>
            {/* <span className="desc">We will send you an answer as soon as possible.</span> */}
            <span className="desc-2">
              Thank you for applying to Breaker Nation's Internship Program. Your application is now under review. Our goal is to respond to
              all applicants within 7 business days. Stay tuned to your email for updates!
              <br /> <br />
              If you haven't already downloaded our mobile app, be sure to do so!
            </span>
          </div>
          <div className="footer-container">
            <div className="breaker-quote">
              BREAK NEW MUSIC.
              <br />
              BREAK OLD TRADITIONS.
              <br />
              BREAK YOURSELF — YOUR WAY!
            </div>
            <div className="mobile-app">
              <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/breaker-nation/id1533950005">
                <img src={appStore} alt="" />
              </a>

              <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.breakernation">
                <img src={playStore} alt="" />
              </a>
            </div>
            <div className="logo-container">
              <Link to="/">
                <img src={breakerLogo} alt="" className="logo" />
              </Link>
              <div className="sidebar-tagline">Fanbase Marketing Machine</div>
            </div>
          </div>
        </div>
      </div>
      <SideDrawer showClose={false} open={showSidebar} onClose={() => setShowSidebar(false)} />
    </div>
  );
}
