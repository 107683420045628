import React from "react";
// import { ReactComponent as MusicSVG } from "../../assets/icon/music-sparkle.svg";
import CardError from "../../assets/img/signup/card-error.png";
export default function ErrorSignup(props) {
  return (
    <div className="signup-error">
      <div className="signup-error__container">
        <img src={CardError} alt="success" className="music-img" />
        <div className="error-desc__container">
          <div className="error-txt">An error occurred while processing the card!</div>
          <div className="paragraph">Please check the correctness of the specified data, or specify another card.</div>
          {/* <div className="got-it__btn"></div> */}

          <div className="got-it__btn" onClick={() => props.setShowFailureScreen(false)}>
            GOT IT
          </div>
        </div>
      </div>
    </div>
  );
}
