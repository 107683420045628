import {Drawer} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import InfluencerComponent from "../../components/InfluencerComponent";
import {trackMixpanel} from "../../mixpanel";
import mixpanel_constant from "../../mixpanel/mixpanel.constants";
import {
  setOpenProfileDispatch,
  setSelectedProfile
} from "../../state/actions/homeAction";
import {getRoles} from "../../state/actions/listenerActions";
import Profile from "../Profile";
import RequestReview from "./RequestReview";
import {useGetInfluencers} from "../../hooks";

const Influencers = () => {
  const dispatch = useDispatch();
  const {
    influencersList,
    searchInfluencers,
    isLoadMoreAllowed,
    isLoading,
    isLoadingMore,
    onLoadMoreInfluencers
  } = useGetInfluencers({isApiCallOnMount: true})
  const openProfile = useSelector(state => state.home.openProfile);
  const [showRequestReview, setShowRequestReview] = React.useState(false);
  const [search, setSearch] = useState('')


  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const handleOpenProfile = useCallback(
    userData => {
      if (userData) {
        dispatch(setSelectedProfile({...userData}));
        trackMixpanel(mixpanel_constant.view_influencer_profile, {...userData});
      }
      dispatch(setOpenProfileDispatch(!openProfile));
    },
    [openProfile, trackMixpanel, mixpanel_constant]
  );

  const handleCloseProfile = () => {
    dispatch(setOpenProfileDispatch(null));
  };

  const searchData = useCallback((event) => {
    const text = event.target.value;
    trackMixpanel(mixpanel_constant.search_influencer, {searchTest: text});
    setSearch(text)
    searchInfluencers(text)
  }, []);

  const handleOpenReviewRequest = React.useCallback(() => {
    setShowRequestReview(true);
    trackMixpanel(mixpanel_constant.influencer_request_review_open, {...openProfile});
  }, [setShowRequestReview, trackMixpanel, mixpanel_constant, openProfile]);

  const handleClearSearch = useCallback(() => {
    setSearch('')
    searchInfluencers('')
  }, [])

  return (
    <>
      <InfluencerComponent
        handleOnChange={searchData}
        handleOpenProfile={handleOpenProfile}
        handleClearSearch={handleClearSearch}
        dataList={influencersList}
        searchValue={search}
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        isLoadMoreAllowed={isLoadMoreAllowed}
        onLoadMore={onLoadMoreInfluencers}
      />
      <Drawer anchor="right" open={!!openProfile} onClose={handleCloseProfile}>
        <Profile onClose={handleCloseProfile} handleOpenReviewRequest={handleOpenReviewRequest}/>
      </Drawer>
      <RequestReview open={showRequestReview} handleClose={() => setShowRequestReview(false)}/>
    </>
  );
};

export default Influencers;
