import React, { useState } from "react";
import styled from "styled-components";
import { axiosInstance } from "../utils/axiosInstance";

export default function KSubscriptionPopup({ onClose }) {
  const [email, setEmail] = useState("");
  const [subLoading, setSubLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const onSubscribe = async () => {
    if (subLoading) return;
    setSubLoading(true);
    try {
      await axiosInstance.post("/v1/users/subscribe", { email });
      setEmail("");
      setUploaded(true);
      setSubLoading(false);
    } catch (err) {
      setSubLoading(false);
    }
  };

  const renderUploaded = () => {
    if (!uploaded) return null;
    return (
      <div className="uploaded">
        <div className="uploaded-text">
          <div className="title">SUCCESS!</div>
          <span>THANKS FOR SUBSCRIBING!</span>
        </div>
        <div className="uploaded-button">
          <div className="btn-close btn-listen" onClick={onClose}>
            CLOSE POP-UP
          </div>
        </div>
      </div>
    );
  };

  return (
    <KSubscriptionWrapper>
      <section className="subscribe-web h-mob">
        <img onClick={onClose} src="/svg/close_white.svg" alt="" className="close" />
        <img className="bg" src="/images/subscribe_bg.png" alt="" />
        <div className="subscribe-wrap">
          <aside>
            {renderUploaded()}
            {!uploaded && (
              <div className="subscribe-box">
                <div className="title">Don't miss out on new music career resources</div>
                <div className="tagline">Get the latest resources delivered to your inbox.</div>

                <div className="exc-gap flex jcsb ci">
                  <aside>
                    <img className="exclusive" src="/images/exclusive_ribbon.png" alt="" />
                  </aside>
                  <aside>
                    <div className="tagline left">
                      Subscribe now and get{" "}
                      <b>
                        <i>free</i>
                      </b>{" "}
                      access to our private podcast The Cheat Code for Promoting Music That Goes Viral.
                    </div>
                  </aside>
                </div>

                <div className="actions flex jcsb ci">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Email Address"
                    className="sub-email"
                  />

                  <div onClick={onSubscribe} className="btn-listen">
                    {subLoading ? "Please wait..." : "Listen Now"}
                  </div>
                </div>
              </div>
            )}
          </aside>

          <aside>
            <img className="img-mob" src="/images/dark_mob_web.png" alt="" />
          </aside>
        </div>
      </section>

      <section className="subscribe-mob s-mob">
        {renderUploaded()}
        {!uploaded && (
          <>
            <img onClick={onClose} src="/svg/close_white.svg" alt="" className="close" />
            <div className="subscribe-wrap-mobile">
              <div className="title">Get the latest resources delivered to your inbox</div>
              <div className="tagline">
                Subscribe now and get{" "}
                <b>
                  <i>free</i>
                </b>{" "}
                access to our private podcast The Cheat Code for Promoting Music That Goes Viral.
              </div>
              <img src="/images/dark_mob_mob.png" alt="" />
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="EMAIL ADDRESS"
                className="sub-email"
              />
              <div onClick={onSubscribe} className="btn-listen">
                {subLoading ? "Please wait..." : "Listen Now"}
              </div>
            </div>
          </>
        )}
      </section>
    </KSubscriptionWrapper>
  );
}

const KSubscriptionWrapper = styled.div`
  width: 90vw;
  height: 90vh;
  min-height: 600px;
  overflow-y: auto;

  .uploaded {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: #fff;
    max-width: 600px;
    border-radius: 20px;
    padding: 50px;
    text-align: center;
    margin-right: 120px;

    @media (max-width: 767px) {
      padding: 50px 0;
      margin-right: 0;
    }
    .uploaded-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
    }

    .btn-close {
      margin-top: 20px;
    }
  }

  .subscribe-web {
    height: 100%;
    position: relative;
    background: #18181a;
    border-radius: 6px;

    img.bg {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    img.close {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 6;
      cursor: pointer;
    }
  }

  .subscribe-wrap {
    position: relative;
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;

    .subscribe-box {
      background: #18181a;
      color: #fff;
      max-width: 600px;
      border-radius: 20px;
      padding: 50px;
      text-align: center;
      margin-right: 80px;
      border: 1px solid #c8a86b;

      .exc-gap {
        padding: 16px 0;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .tagline {
        font-size: 14px;
        font-weight: 400;
        /* text-transform: uppercase; */
        margin-bottom: 10px;
        &.left {
          text-align: left;
        }
      }
    }

    img.exclusive {
      height: 60px;
      margin-right: 20px;
    }
  }

  .subscribe-mob {
    background: #18181a;
    border: 1px solid #c8a86b;
    color: #fff;
    padding: 24px;
    border-radius: 10px;
    text-align: center;
    position: relative;

    img.close {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 6;
      cursor: pointer;
      width: 24px;
    }

    img {
      width: 100%;
    }

    .subscribe-wrap-mobile {
      position: relative;
      z-index: 5;

      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 29px;
        margin: 10px 0;
      }

      .tagline {
        line-height: 29px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
      }

      .sub-email {
        margin: 0;
        width: 100%;
        margin-top: 24px;
        font-size: 12px;
      }

      .btn-listen {
        width: 100%;
        margin-top: 16px;
        font-size: 12px;
      }
    }
  }

  input.sub-email {
    height: 44px;
    background: #18181a;
    flex: 1;
    margin-right: 20px;
    font-size: 16px;
    border: 0.5px solid #c8a86b;
    padding: 0 16px;
    border-radius: 4px;
    color: #fff;
    outline: none;
  }
  .btn-listen {
    background: #c8a86b;
    height: 44px;
    color: #000;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
  }

  .actions {
    margin-top: 24px;
  }
`;
