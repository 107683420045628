export const PLANS = {
  FREE: {
    id: "free-id",
    monthly: {
      title: "Free plan",
      price: 0,
      priceId: "free-priceId",
    },
    annual: {
      title: "Free plan",
      price: 0,
      priceId: "free-priceId",
    },
  },
  STARTER: {
    id: "prod_KeMselkLxK86kT",
    monthly: {
      title: "Starter",
      price: 4.99,
    },
    annual: {
      title: "Starter",
      price: 49.99,
    },
  },

  DREAM_TEAM: {
    id: "prod_KeN1tZo4A38hdY",
    monthly: {
      title: "Dream Team",
      price: 14.99,
    },
    annual: {
      title: "Dream Team",
      price: 149.99,
    },
  },

  BUSINESS: {
    id: "prod_KeN5BgSloQzBnL",
    monthly: {
      title: "Business",
      price: 29.99,
    },
    annual: {
      title: "Business",
      price: 299.99,
    },
  },
};
