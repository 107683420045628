export default {
  v: "5.7.1",
  fr: 60,
  ip: 0,
  op: 85,
  w: 1000,
  h: 1000,
  nm: "Comp 1",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 10, s: [-90] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [-45] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 60, s: [-45] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 70, s: [-90] },
                { t: 80, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [240.789, 634.211, 0],
                  to: [0, 20.833, 0],
                  ti: [-6.36, -3.728, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 10,
                  s: [240.789, 759.211, 0],
                  to: [6.36, 3.728, 0],
                  ti: [-6.36, 17.105, 0],
                },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.333, y: 0.333 }, t: 20, s: [278.947, 656.579, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 60,
                  s: [278.947, 656.579, 0],
                  to: [-6.36, 17.105, 0],
                  ti: [6.36, 3.728, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 70,
                  s: [240.789, 759.211, 0],
                  to: [-6.36, -3.728, 0],
                  ti: [0, 20.833, 0],
                },
                { t: 80, s: [240.789, 634.211, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-261.842, -146.053, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 25,
              nm: "Drop Shadow",
              np: 8,
              mn: "ADBE Drop Shadow",
              ix: 1,
              en: 1,
              ef: [
                { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 150, ix: 5 } },
                { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [521.053, 50], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.78431372549, 0.658823529412, 0.419607843137, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-2.632, -98.684], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 40,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 2",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 5, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 75, s: [0] },
                { t: 80, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [502.632, 598.684, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 25,
              nm: "Drop Shadow",
              np: 8,
              mn: "ADBE Drop Shadow",
              ix: 1,
              en: 1,
              ef: [
                { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 150, ix: 5 } },
                { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [521.053, 50], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.78431372549, 0.658823529412, 0.419607843137, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-2.632, -98.684], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 40,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 10, s: [-90] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [45] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 60, s: [45] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 70, s: [-90] },
                { t: 80, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [760.526, 363.158, 0],
                  to: [0, -20.614, 0],
                  ti: [18.421, -59.211, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 10,
                  s: [760.526, 239.474, 0],
                  to: [-18.421, 59.211, 0],
                  ti: [18.421, -79.825, 0],
                },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.333, y: 0.333 }, t: 20, s: [650, 718.421, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 60,
                  s: [650, 718.421, 0],
                  to: [18.421, -79.825, 0],
                  ti: [-18.421, 59.211, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 70,
                  s: [760.526, 239.474, 0],
                  to: [18.421, -59.211, 0],
                  ti: [0, -20.614, 0],
                },
                { t: 80, s: [760.526, 363.158, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [257.895, -53.947, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 25,
              nm: "Drop Shadow",
              np: 8,
              mn: "ADBE Drop Shadow",
              ix: 1,
              en: 1,
              ef: [
                { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 150, ix: 5 } },
                { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [521.053, 50], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.78431372549, 0.658823529412, 0.419607843137, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-2.632, -98.684], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 40,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -45, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -35, s: [-90] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -25, s: [-45] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 15, s: [-45] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [-90] },
                { t: 35, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: -45,
                  s: [240.789, 634.211, 0],
                  to: [0, 20.833, 0],
                  ti: [-6.36, -3.728, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: -35,
                  s: [240.789, 759.211, 0],
                  to: [6.36, 3.728, 0],
                  ti: [-6.36, 17.105, 0],
                },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.333, y: 0.333 }, t: -25, s: [278.947, 656.579, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 15,
                  s: [278.947, 656.579, 0],
                  to: [-6.36, 17.105, 0],
                  ti: [6.36, 3.728, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 25,
                  s: [240.789, 759.211, 0],
                  to: [-6.36, -3.728, 0],
                  ti: [0, 20.833, 0],
                },
                { t: 35, s: [240.789, 634.211, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-261.842, -146.053, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 25,
              nm: "Drop Shadow",
              np: 8,
              mn: "ADBE Drop Shadow",
              ix: 1,
              en: 1,
              ef: [
                { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 150, ix: 5 } },
                { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [521.053, 50], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.78431372549, 0.658823529412, 0.419607843137, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-2.632, -98.684], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 75,
          st: -45,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 2",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -45, s: [100] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -40, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 30, s: [0] },
                { t: 35, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [502.632, 598.684, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 25,
              nm: "Drop Shadow",
              np: 8,
              mn: "ADBE Drop Shadow",
              ix: 1,
              en: 1,
              ef: [
                { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 150, ix: 5 } },
                { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [521.053, 50], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.78431372549, 0.658823529412, 0.419607843137, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-2.632, -98.684], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 75,
          st: -45,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -45, s: [0] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -35, s: [-90] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: -25, s: [45] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 15, s: [45] },
                { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [-90] },
                { t: 35, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: -45,
                  s: [760.526, 363.158, 0],
                  to: [0, -20.614, 0],
                  ti: [18.421, -59.211, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: -35,
                  s: [760.526, 239.474, 0],
                  to: [-18.421, 59.211, 0],
                  ti: [18.421, -79.825, 0],
                },
                { i: { x: 0.667, y: 0.667 }, o: { x: 0.333, y: 0.333 }, t: -25, s: [650, 718.421, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 15,
                  s: [650, 718.421, 0],
                  to: [18.421, -79.825, 0],
                  ti: [-18.421, 59.211, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 25,
                  s: [760.526, 239.474, 0],
                  to: [18.421, -59.211, 0],
                  ti: [0, -20.614, 0],
                },
                { t: 35, s: [760.526, 363.158, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [257.895, -53.947, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 25,
              nm: "Drop Shadow",
              np: 8,
              mn: "ADBE Drop Shadow",
              ix: 1,
              en: 1,
              ef: [
                { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 150, ix: 5 } },
                { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [521.053, 50], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.78431372549, 0.658823529412, 0.419607843137, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-2.632, -98.684], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 75,
          st: -45,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "2",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 40,
      op: 115,
      st: 40,
      bm: 0,
    },
  ],
  markers: [],
};
