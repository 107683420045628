import React from "react";
import styled from "styled-components";

// components
import { ExperiencesCard } from "../../components/NewInternship";

const ThirdParagraph = () => {
  return (
    <Wrapper>
      <Title>An Internship Experience That Works For Everyone</Title>
      <ExperiencesCard />
    </Wrapper>
  );
};

export default ThirdParagraph;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15vh 1.66vw;
  @media (max-width: 768px) {
    margin: 100px 36px;
  }
`;

const Title = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 2vw;
  line-height: 4.4vh;
  color: #ffffff;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
