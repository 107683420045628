import React from "react";
import styled from "styled-components";

// components
import { Menu } from "../../components/NewInternship";

const Footer = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Space />
        <Logo src="assets/Logo.svg" />
        <Menu />
      </LogoWrapper>
      <LinksWrapper>
        <Link>Terms</Link>
        <Link>Privacy</Link>
        <Link>Guidelines</Link>
        <Link>Contact Us</Link>
      </LinksWrapper>
      <Copyright>Copyright © Breaker Nation 2022. All rights reserved.</Copyright>
      <IconsWrapper>
        <IconWrapper>
          <Icon src="assets/Insta.svg" />
        </IconWrapper>
        <IconWrapper>
          <Icon src="assets/LinkedIn.svg" />
        </IconWrapper>
        <IconWrapper>
          <Icon src="assets/Twitter.svg" />
        </IconWrapper>
        <IconWrapper>
          <Icon src="assets/TikTok.svg" />
        </IconWrapper>
        <IconWrapper>
          <Icon src="assets/YouTube.svg" />
        </IconWrapper>
      </IconsWrapper>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18.7vh 0 11.7vh 0;
  @media (max-width: 768px) {
    padding: 110px 0 85.5px 0;
  }
`;

const LogoWrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8.33vw;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Space = styled.div``;

const Logo = styled.img`
  width: 5.27vw;
  height: 4.5vw;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 71px;
    height: 61px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  @media (max-width: 768px) {
    margin: 15px 0 15px 0;
  }
`;

const Link = styled.a`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #ffffff;
  padding: 0 50px;
  border-right: 1px solid #fff;
  :last-child {
    border-right-width: 0;
  }
  :hover {
    text-decoration: underline;
    font-weight: bold;
    color: #fff;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    margin-bottom: 15px;
  }
`;

const Copyright = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-align: center;
  margin: 0 74px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const IconsWrapper = styled.div`
  margin-top: 40px;
`;

const IconWrapper = styled.a`
  margin-right: 33px;
  :last-child {
    margin-right: 0;
  }
`;

const Icon = styled.img`
  transition: transform 0.2s linear;
  :hover {
    transform: scale(2);
  }
`;
