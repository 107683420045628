import React from "react";
import OtpContainer from "../Musicians/OtpContainer";
export default function VerifyPhone(props) {
  return (
    <div className="signup-create-account">
      <div className="signup-otp__container">
        <OtpContainer
          //   onClose={() => setShowPhoneModal(false)}
          phoneNumber={props.phoneNumber}
          countryCode={props.countryCode}
          handlePhoneVerify={props.handlePhoneVerify}
          onReSend={props.onReSend}
          resetOtpError={props.resetOtpError}
          errors={props.errors}
          resendTime={props.resendTime}
        />
      </div>
    </div>
  );
}
