import {trackMixpanel} from "../mixpanel";
import mixpanel_constant from "../mixpanel/mixpanel.constants";

export const trackMembershipMixpanel = (props) => {
  const {
    firstTimeUpload,
    premiumUser,
    activateProAccount,
    activateProYearly,
    trackInfo
  } = props || {}
  const {trackAuthor, trackTitle} = trackInfo || {}
  const trackInfoParams = {trackAuthor, trackTitle}
  if (firstTimeUpload) {
    trackMixpanel(mixpanel_constant.upload_first_track, {amount: 0, ...trackInfo})
    if (!premiumUser) {
      if (activateProAccount) {
        trackMixpanel(mixpanel_constant.purchase_monthly_membership, {
          amount: 15,
          isFirstTrack: true, ...trackInfoParams
        })
      } else if (activateProYearly) {
        trackMixpanel(mixpanel_constant.purchase_yearly_membership, {
          amount: 100,
          isFirstTrack: true, ...trackInfoParams
        })
      }
    }
  } else if (!premiumUser) {
    if (activateProAccount) {
      trackMixpanel(mixpanel_constant.purchase_monthly_membership, {
        amount: 15,
        isFirstTrack: false, ...trackInfoParams
      })
    } else if (activateProYearly) {
      trackMixpanel(mixpanel_constant.purchase_yearly_membership, {
        amount: 100,
        isFirstTrack: false, ...trackInfoParams
      })
    }
    if (activateProAccount || activateProYearly) {
      trackMixpanel(mixpanel_constant.upload_track_with_membership, {
        amount: 5,
        isFirstTrack: false, ...trackInfoParams
      })
    } else {
      trackMixpanel(mixpanel_constant.upload_track_without_membership, {
        amount: 9,
        isFirstTrack: false, ...trackInfoParams
      })
    }
  } else if (premiumUser) {
    trackMixpanel(mixpanel_constant.upload_track_with_membership, {
      amount: 5,
      isFirstTrack: false, ...trackInfoParams
    })
  }
}

export const trackMusicianMembershipMixpanel = (amount) => {
  if (amount === 9) {
    trackMixpanel(mixpanel_constant.upload_track_without_membership, {amount: 9,isMusician:true})
  } else if (amount === 15) {
    trackMixpanel(mixpanel_constant.purchase_monthly_membership, {amount: 15,isMusician:true})
  } else if (amount === 100) {
    trackMixpanel(mixpanel_constant.purchase_yearly_membership, {amount: 15,isMusician:true})
  }
}
