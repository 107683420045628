export const preferencsSelector = (state) => ({
  genres: state.preferences.genres,
  tags: state.preferences.tags,
  styles: state.preferences.styles,
  selectedGenres: state.preferences.selectedGenres,
  selectedTags: state.preferences.selectedTags,
  selectedStyles: state.preferences.selectedStyles,
  userDetails: state.userDetails.user,
});

export const musicianInfoSelector = ({preferences}) => {
  const {musicianFlowInfo} = preferences || {}
  return {
    countryCode: musicianFlowInfo?.countryCode,
    selectedStyles: musicianFlowInfo?.selectedStyles,
    selectedGenreMultiple: musicianFlowInfo?.selectedGenreMultiple,
    selectedGenre: musicianFlowInfo?.selectedGenre,
    selectedCover: musicianFlowInfo?.selectedCover,
    selectedFile: musicianFlowInfo?.selectedFile,
    email: musicianFlowInfo?.email,
    artistName: musicianFlowInfo?.artistName,
    emailAddress: musicianFlowInfo?.emailAddress,
    trackName: musicianFlowInfo?.trackName,
    displayName: musicianFlowInfo?.displayName,
    userName: musicianFlowInfo?.userName,
    phoneNumber: musicianFlowInfo?.phoneNumber,
    feedbackId: musicianFlowInfo?.feedbackId,
  }
};
