import React from "react";
import IntershipSignUpComponent from "../../components/Internship/SignupComponent";

export default function InternshipSignUpPage() {
  return (
    <div>
      <IntershipSignUpComponent />
    </div>
  );
}
