import React from "react";
import {useHistory} from "react-router-dom";
import '../../../components/Musicians/musician.style.scss'
import {ReactComponent as Breaker} from "../../../assets/img/musician/breaker_nation.svg";
import PaymentSuccessContainer
  from "../../../components/Musicians/PaymentSuccessContainer";

const MusicianPaymentSuccess = () => {
  const history = useHistory()
  return (
    <>
      <div className="musician-main-container">
        <div className="musician-top-container ">
          <Breaker className="breaker-nation-icon"/>
          <PaymentSuccessContainer
            handleClickGotIt={() => {
              history.replace('/musicians/confirm')
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MusicianPaymentSuccess
