import React from "react";

export default function PlanCardFeatures(props) {
  const formatTitle = (text) => {
    return {
      tt: String(text).startsWith("@") ? "line-through" : "none",
      title: String(text).startsWith("@") ? String(text).substr(1) : text,
      o: String(text).startsWith("@") ? 0.5 : 1,
    };
  };

  return (
    <div className="pricing-plan__features" style={props.style}>
      <ul>
        {props.features.map((item, i) => (
          <li style={{ textDecoration: formatTitle(item).tt, opacity: formatTitle(item).o }} key={`pricefeat${i}`}>
            {formatTitle(item).title}
          </li>
        ))}
      </ul>
    </div>
  );
}
