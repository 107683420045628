import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HomeFooter from "../Home/ui/HomeFooter";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
// import { Helmet } from "react-helmet";

// export default function MerchComponent() {
//   return (
//     <>
//       <div id="myShop">{/* <a href="https://breaker-nation-merch.myspreadshop.com">breaker-nation-merch</a> */}</div>
//       <Helmet>
//         <script
//           type="text/javascript"
//           src="https://breaker-nation-merch.myspreadshop.com/shopfiles/shopclient/shopclient.nocache.js"
//         ></script>
//       </Helmet>
//     </>
//   );
// }

export default function MerchComponent() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenuOnSidebar, setShowMenuOnSidebar] = useState(false);

  useEffect(() => {
    if (!showSidebar) {
      setShowMenuOnSidebar(false);
    }
  }, [showSidebar]);

  return (
    <div>
      <HomeNav onMenuClick={() => setShowSidebar(true)} onMenuClose={() => setShowSidebar(false)} showSidebar={showSidebar} />

      <MerchComponentWrapper>
        <div>
          <div>
            <img src="/images/breaker-nation-hoodie.png" alt="" />
          </div>
          <a rel="noopener noreferrer" href="https://buy.stripe.com/aEU4hM5dyaqb2wE9AJ" target="_blank">
            <div className="buy-btn">BUY NOW ($50)</div>
          </a>
        </div>
      </MerchComponentWrapper>

      <HomeFooter
        onMenuClick={() => {
          setShowSidebar(true);
          setShowMenuOnSidebar(true);
        }}
      />
      <SideDrawer showClose={showMenuOnSidebar} open={showSidebar} onClose={() => setShowSidebar(false)} />
    </div>
  );
}

const MerchComponentWrapper = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  text-align: center;

  img {
    max-width: 90vw;
  }

  .buy-btn {
    background: #c8a86b;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    border: 0;
    width: 220px;
    height: 55px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto auto auto;
  }
`;
