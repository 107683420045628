import React from "react";
import styled from "styled-components";

const StyledButton = ({ title, onClick }) => {
  return <Wrapper onClick={onClick}>{title}</Wrapper>;
};

export default StyledButton;

const Wrapper = styled.button`
  width: 15.94vw;
  border: none;
  border-radius: 6px;
  background-color: ${(props) => props.theme.gold};
  padding: 1.7vh 0;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 0.88vw;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.black};
  @media (max-width: 768px) {
    width: 387px;
    padding: 17px 0;
    font-size: 16px;
  }

  @media (max-width: 425px) {
    width: 80%;
    font-size: 12px;
  }
`;
