import React from "react";
import styled from "styled-components";

// components
import { Menu } from "../../components/NewInternship";

const Navbar = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo src="assets/Logo.svg" />
        <LogoText>Fanbase Marketing Machine</LogoText>
      </LogoWrapper>
      <Menu />
    </Wrapper>
  );
};

export default Navbar;

const Wrapper = styled.div`
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8.1vh;
  width: 100%;
  padding: 0 7.77vw;
  margin-top: 4.5vh;
`;

const LogoWrapper = styled.div``;

const Logo = styled.img`
  width: 4.16vw;
  height: 3.55vw;
  object-fit: contain;
  margin-right: 2.33vw;
  @media (max-width: 768px) {
    width: 63px;
    height: 54px;
  }
`;

const LogoText = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.77vw;
  line-height: 1.7vh;
  text-align: center;
  color: #c8a86b;
  @media (max-width: 768px) {
    display: none;
  }
`;
