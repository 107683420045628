import React from "react";
import styled from "styled-components";

const HeaderTxt = () => {
  return (
    <>
      <Title>AN INTERNSHIP PROGRAM</Title>
      <Subtitle>
        The # 1 way to get the
        <Break />
        experience you need to work
        <Break />
        in the music industry
      </Subtitle>
    </>
  );
};

export default HeaderTxt;

const Title = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  text-transform: uppercase;
  color: ${(props) => props.theme.gold};
  margin-bottom: 12vh;
  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
    margin-bottom: 484px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    margin-bottom: 284px;
  }
`;

const Break = styled.br``;

const Subtitle = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.33vw;
  line-height: 5.88vh;
  color: ${(props) => props.theme.white};
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 4vh;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 28px;
    line-height: 39px;
    ${Break} {
      display: none;
    }
    margin: 0 44px 50px 44px;
  }
`;
