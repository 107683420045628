import React from "react";
import Button from "../../common/Button";
import SuccessPic from "../../assets/img/musician/succes pic.png";
import {ReactComponent as BreakerXS} from "../../assets/icon/breakerxs.svg";
import {ReactComponent as BreakerNation} from "../../assets/icon/breaker-nation.svg";

const PaymentSuccessContainer = ({ handleClickGotIt }) => {
  return (
    <div className="success-main-container">
      <img className="success-img" src={SuccessPic} alt="No Img" />
      <span className="success-message">Success!</span>
      <p className="submission-message">We will be in touch soon with feedback on your music.</p>
      <Button buttonText="GOT IT!" className="landing-acpt-cook-btn" onClick={handleClickGotIt} />
      <section className="drawer-bottom__container">
        <div className="desc-container">
          <div>
            <BreakerXS />
            {"  "}
            <BreakerNation />
          </div>
          <div className="rights">
            BREAKER NATION 2020-2021
            <br /> All rights reserved
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentSuccessContainer;
