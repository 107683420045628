import React from "react";
import styled from "styled-components";

const ExperiencesCard = () => {
  return (
    <Wrapper>
      <SingleCard>
        <Title>01</Title>
        <Subtitle>Flexible Schedules</Subtitle>
        <Paragraph>Set your schedule on your terms for 90% of the work.</Paragraph>
      </SingleCard>
      <SingleCard>
        <Title>02</Title>
        <Subtitle>Work From Anywhere</Subtitle>
        <Paragraph>No travel required. The entire program is 100% remote.</Paragraph>
      </SingleCard>
      <SingleCard>
        <Title>03</Title>
        <Subtitle>No Cost To Apply</Subtitle>
        <Paragraph>It’s free! You don’t need to pay an application fee.</Paragraph>
      </SingleCard>
      <SingleCard>
        <Title>04</Title>
        <Subtitle>Need College Credit?</Subtitle>
        <Paragraph>No problem! After enrolling, just send over documentation and we’ll sign off as needed.</Paragraph>
      </SingleCard>
      <SingleCard>
        <Title>05</Title>
        <Subtitle>Rolling Admission</Subtitle>
        <Paragraph>We review applications weekly and allow you to start when you’re ready.</Paragraph>
      </SingleCard>
    </Wrapper>
  );
};

export default ExperiencesCard;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: calc(20.5vw * 4);
  margin-top: 2vh;
`;

const SingleCard = styled.div`
  width: 22vw;
  height: 22vw;
  display: flex;
  flex-direction: column;
  background: #18181a;
  border: 1px solid #5634c2;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(86, 52, 194, 0.4);
  border-radius: 6px;
  padding: 1.5vw 2.944vw;
  margin: 6vh 1.66vw 0 1.66vw;
  @media (max-width: 768px) {
    width: 369px;
    height: 369px;
    padding: 45px 30px;
    margin: 24px 0 0 0;
  }
  @media (max-width: 425px) {
    width: 280px;
    height: 280px;
  }
`;

const Title = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 5.55vw;
  line-height: 12.2vh;
  text-transform: uppercase;
  text-align: center;
  background: linear-gradient(
    279.64deg,
    rgba(200, 168, 107, 0.7) 10%,
    rgba(86, 52, 194, 0.7) 42.27%,
    rgba(86, 52, 194, 0.7) 55.12%,
    rgba(200, 168, 107, 0.7) 80.53%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 100px;
    line-height: 118px;
    margin-bottom: 24px;
  }
  @media (max-width: 425px) {
    font-size: 80px;
    line-height: 98px;
  }
`;

const Subtitle = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 1.11vw;
  line-height: 3.2vh;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 1.2vh;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 19px;
    margin-bottom: 10px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const Paragraph = styled.text`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.88vw;
  line-height: 2.4vh;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 21px;
  }
`;
