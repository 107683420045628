import React, { useEffect, useState } from "react";
import HomeFooter from "../Home/ui/HomeFooter";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
import downArrowGold from "../../assets/img/pricing/down-arrow.svg";
import "./congratulations.style.scss";
import Addon from "./Addon";
import { Elements, StripeProvider } from "react-stripe-elements";
import { STRIPE_KEY } from "../../config";
import InternPayment from "./InternPayment";
import EnrollmentComplete from "./EnrollmentComplete";
import { handleEnrollment, submitDeclineReason } from "../../state/actions/internActions";
import { toast } from "react-toastify";
import history from "../../history";
import FAQ from "../Pricing/FAQ";
import { Redirect } from "react-router-dom";

export default function Congratulations(props) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenuOnSidebar, setShowMenuOnSidebar] = useState(false);
  const [openInternshipProgram, setOpenInternshipProgram] = useState(false);
  const [total, setTotal] = useState(0);
  const [showPayment, setShowPayment] = useState(false); // change to false
  const [showDecline, setShowDecline] = useState(false); // change to false
  const [showEnrollmentComplete, setShowEnrollmentComplete] = useState(false);
  const [executiveIntern, setExecutiveIntern] = useState(false);
  const [creativeIntern, setCreativeIntern] = useState(false);
  const [isFree, setIsFree] = useState(true);

  const [declineReason, setDeclineReason] = useState("");

  //   payment
  const [addPaymentMethod, setAddPaymentMethod] = React.useState(true);
  const [rememberPaymentMethod, setRememberPaymentMethod] = React.useState(true);
  const [selectedCard, setSelectedCard] = React.useState(null);

  const handleCardSelect = (cardId) => {
    setSelectedCard(cardId);
    setAddPaymentMethod(false);
  };

  const handlePaymentTokenReceived = async (payload) => {
    try {
      await handleEnrollment(payload);
      setShowEnrollmentComplete(true);
    } catch (error) {
      toast.error(error?.error?.message || "Couldn't complete your request, please try again");
      throw error;
    }
  };

  const handleTokenReceived = (token) => {
    const payload = {
      payment_token: selectedCard || token?.id,
      save_card: !addPaymentMethod ? false : rememberPaymentMethod,
      executive_internship: executiveIntern,
      creative_internship: creativeIntern,
    };
    handlePaymentTokenReceived(payload);
  };

  const handleDeclineSubmit = async () => {
    try {
      await submitDeclineReason(declineReason);
      setTimeout(() => {
        history.push("/internship/complete");
      }, 300);
    } catch (error) {
      toast.error(error?.error?.message || "Couldn't complete your request, please try again");
      throw error;
    }
  };

  useEffect(() => {
    if (!executiveIntern && !creativeIntern) {
      setAddPaymentMethod(false);
      setIsFree(true);
    } else {
      setIsFree(false);
      setAddPaymentMethod(true);
    }
  }, [executiveIntern, creativeIntern]);

  if (showEnrollmentComplete) return <Redirect to="/internship/success" />;

  return (
    <div className="internship-congratulations">
      <HomeNav onMenuClick={() => setShowSidebar(true)} onMenuClose={() => setShowSidebar(false)} showSidebar={showSidebar} />
      <div className="container">
        <div className="congratulations-wrapper">
          <div className="title-container">
            <h1>Congratulations!</h1>
            <div className="desc">You have been accepted into Breaker Nation’s Internship Program! </div>
          </div>

          <div className="offer-container">
            <div className="enroll-text">To enroll, please formally accept the internship offer below.</div>
            <div className="offers">
              <div className="header">
                <div className="internship-program">
                  <div className="program-title">
                    <div className="program-title__wrap">
                      <h1>Internship Program</h1>
                      <span>Get real work experience</span>
                    </div>
                    <div className="free-banner hide-on-desktop show-on-mobile">
                      <hr />
                      <span>FREE</span>
                      <hr />
                    </div>
                    <div className="program-cost hide-on-mobile">
                      <h1>FREE</h1>
                    </div>
                  </div>
                  {openInternshipProgram && (
                    <div className="program-details">
                      <ul>
                        <li>Write music reviews weekly</li>
                        <li>Join teams and support the artists you love</li>
                        <li>Share tracks with your network and on social media</li>
                        <li>Participate in discussions, challenges & in-app events</li>
                        <li>Submit an internship essay and request your certificate</li>
                      </ul>
                    </div>
                  )}
                </div>

                <div className="show-details" onClick={() => setOpenInternshipProgram((prev) => !prev)}>
                  <h1 className="show-details__title">Show details</h1>
                  <span className="arrow">
                    <img
                      src={downArrowGold}
                      alt="up-arrow"
                      className={`arrow__img ${openInternshipProgram ? "arrow__down" : "arrow__up"}`}
                    />
                  </span>
                </div>
              </div>
              <hr />
              <div className="optional-add__container">
                <div className="optional-add">
                  <h1>Optional Add On's</h1>
                  <span>Progressive Music Experiences </span>
                </div>
                <div className="addons-container">
                  <Addon
                    setTotal={setTotal}
                    setIntern={setExecutiveIntern}
                    title="Executive in Training"
                    desc="Get more connections"
                    price="499"
                    features={[
                      "An internship manager to oversee your work and help you maximize your participation in the program.",
                      "1 on 1 career counseling session",
                      `1 on 1 mentoring session with a music business executive`,
                      `Work on a project to support our network of creators, industry professionals or partners`,
                      `Present your project at our Intern Showcase & Networking Event`,
                      `A LinkedIn recommendation that verifies your knowledge upon successful completion`,
                    ]}
                  />
                  <br />
                  <Addon
                    setTotal={setTotal}
                    setIntern={setCreativeIntern}
                    title="Musician in Training"
                    desc="Showcase your music"
                    price="499"
                    features={[
                      "An internship manager to oversee your work and help you maximize your participation in the program.",
                      "1 on 1 music feedback session",
                      `1 on 1 mentoring session with an established music professional`,
                      `Work on a music project for a creator, industry pro or partner in our network`,
                      `Present your track at our Intern Showcase & Networking Event`,
                      `A LinkedIn recommendation that verifies your knowledge upon successful completion`,
                    ]}
                  />
                </div>
                <div className="total-container">
                  <div>
                    <h1>TOTAL</h1>
                    <span>$&nbsp;{total}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showPayment && (
            <div className="payment">
              <h1>PAYMENT</h1>
              <StripeProvider apiKey={STRIPE_KEY}>
                <Elements>
                  <InternPayment
                    amount={total}
                    addPaymentMethod={addPaymentMethod}
                    rememberPaymentMethod={rememberPaymentMethod}
                    handleRememberPayment={() => setRememberPaymentMethod((oldVal) => !oldVal)}
                    selectedCard={selectedCard}
                    handleCardSelect={handleCardSelect}
                    handlePaymentTokenReceived={handleTokenReceived}
                    setShowEnrollmentComplete={setShowEnrollmentComplete}
                    isFree={isFree}
                  />
                </Elements>
              </StripeProvider>
            </div>
          )}
        </div>
        {showDecline && (
          <div className="decline">
            <h1>DECLINE INTERNSHIP</h1>
            <div className="decline-input__container">
              <span>Can you tell us why you’re declining the internship?</span>
              <textarea placeholder="Write your answer here" value={declineReason} onChange={(e) => setDeclineReason(e.target.value)} />
            </div>
          </div>
        )}
        <div className="welcome-text">
          <div className="text">
            We are thrilled to welcome you and excited to be a part of your professional journey. We are currently accepting interns for the
            summer session beginning in June 2022. After enrolling, a member of our team will contact you within 7 days to discuss the next
            steps and to answer any questions you may have.
          </div>
          {!showPayment &&
            (!showDecline && (
              <>
                <div className="accept-decline__container">
                  <button
                    className="accept-btn"
                    onClick={() => {
                      setShowPayment(true);
                    }}
                  >
                    ACCEPT & ENROLL
                  </button>
                  <button className="decline-btn" onClick={() => setShowDecline(true)}>
                    DECLINE
                  </button>
                </div>
              </>
            ))}
          {showDecline && (
            <button className="decline-btn" onClick={handleDeclineSubmit} style={{ marginTop: "1em" }}>
              DECLINE
            </button>
          )}
          <div className="faq-container">
            <h1 className="faq-title">FAQ</h1>
            <div className="faq-questions__container">
              <FAQ
                title={"What if I enroll now but something comes up and I can’t participate?"}
                desc={
                  "We understand things happen.  So, just send us an email and we’ll withdraw you from the program.  You can always re-enroll."
                }
              />
              <FAQ title={"Can I enroll now and select an Add On later?"} desc={"As long as we have"} />
              <FAQ
                title={"What if I select an Add On now but change my mind?"}
                desc={"We’re happy to issue a refund within 7 days of purchase."}
              />
              <FAQ
                title={"I need more information.  Who can I contact?"}
                desc={"Please contact hello@breakernation.com with any additional questions."}
              />
            </div>
          </div>
        </div>
      </div>
      <SideDrawer showClose={showMenuOnSidebar} open={showSidebar} onClose={() => setShowSidebar(false)} />
      <HomeFooter
        onMenuClick={() => {
          setShowSidebar(true);
          setShowMenuOnSidebar(true);
        }}
      />
    </div>
  );
}
