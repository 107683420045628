import React from "react";
import "./pricing.style.scss";

export default function PlanCardPrice(props) {
  return (
    <div className="pricing-plan__price" style={props.style}>
      <div className="pricing-plan__price-value__container">
        <div className="pricing-plan__dollar-amount">
          {props.price === "FREE" ? (
            "$0"
          ) : (
            <>
              {/* <sup>$</sup> */}${props.price}
              <span style={{ opacity: 0.5 }} className="small">
                /{props.recurring}
              </span>
              {props.recurring == "yr" && <div style={{ opacity: 0.5, fontSize: 12 }}>Save $144/year</div>}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
