import {connect, useSelector} from "react-redux";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import '../../../components/Musicians/musician.style.scss'
import {axiosInstance} from "../../../utils/axiosInstance";
import PaymentDrawer from "../../../components/PaymentDrawer";
import {musicianInfoSelector} from "../../../state/selectors/preferences";
import Congratulations from "../../../components/Musicians/Congratulations";
import {trackMusicianMembershipMixpanel} from "../../../utils/mixpanel-utils";
import {ReactComponent as Breaker} from "../../../assets/img/musician/breaker_nation.svg";
import {
  getGenres,
  getStylesForGenre
} from "../../../state/actions/preferencesActions";

const MusicianCongrats = () => {
  const {feedbackId} = useSelector(musicianInfoSelector)
  const history=useHistory()
  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [isAddPremium, setIsAddPremium] = useState(false);
  const [isYearlySubscription, setIsYearlySubscription] = useState(false);


  const pushConfirmationRoute =()=>{
    history.replace('/musicians/confirm')
  }

  const pushPaymentSuccessRoute =()=>{
    history.replace('/musicians/payment/success')
  }

  const handlePaymentTokenReceive = async ({
    amount,
    token,
    saveCardDetails,
    paymentFromSavedCard
  }) => {
    const payload = {
      paymentToken: token,
      saveCardDetails,
      isAddPremium,
      isYearlySubscription,
      paymentFromSavedCard,
    };
    try {
      await axiosInstance.post(`v2/tracks/${feedbackId}/upgrade-track`, payload);
      setShowPaymentDrawer(false);
      trackMusicianMembershipMixpanel(amount)
      await axiosInstance.put("users/off_waitlist", null);
      pushPaymentSuccessRoute()
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="musician-main-container">
        <div className="musician-top-container ">
          <Breaker className="breaker-nation-icon"/>
          <Congratulations
            setShowConfirmationPage={pushConfirmationRoute}
            handlePaymentSelection={({
              value,
              isAddPremium,
              isYearlySubscription
            }) => {
              if (value === 0) {
                pushConfirmationRoute()
                return;
              }
              setShowPaymentDrawer(true);
              setPaymentAmount(value);
              setIsAddPremium(isAddPremium);
              setIsYearlySubscription(isYearlySubscription);
            }}
          />
        </div>
      </div>
      <PaymentDrawer
        open={showPaymentDrawer}
        amount={paymentAmount}
        handleClose={() => {
          setShowPaymentDrawer(false);
        }}
        handlePaymentTokenReceived={handlePaymentTokenReceive}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    genres: state.preferences.genres,
    styles: state.preferences.styles,
  };
};

const mapActions = (dispatch) => ({
  getGenresDispatchAction: () => dispatch(getGenres()),
  getStylesDispatchAction: (genreId) => dispatch(getStylesForGenre(genreId)),
});

export default connect(
  mapState,
  mapActions
)(MusicianCongrats);
