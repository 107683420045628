import React, { useState } from "react";
import Button from "../../../../common/Button";
import { ReactComponent as Delete } from "../../../../assets/icon/delete.svg";
import ToggleSwitch from "../../../../common/ToggleSwitch";
import CustomDrawer from "../../../../common/CustomDrawer";
import CloseIcon from "../../../../assets/img/Close.png";
import SuccessImg from "../../../../assets/img/musician/succes pic.png";
import AddPayment from "./AddPayment";
import { StripeProvider, Elements, injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from "react-stripe-elements";
import { STRIPE_KEY } from "../../../../config";
import "./payment.style.scss";
// import ToggleSwitchBlue from "../../../../common/ToggleSwitch/ToggleSwitchBlue";
import BModal from "../../../../common/BModal/BModal";
import { onChangeSubscriptionPlan } from "../../../../state/actions/userActions";
import { useSelector } from "react-redux";

const plan_monthly = [
  {
    name: "Starter",
    price: "$4.99/mo",
    value: "starter",
  },
  {
    name: "Dream Team",
    price: "$14.99/mo",
    value: "dream",
  },
  {
    name: "Business",
    price: "$29.99/mo",
    value: "business",
  },
];

const plan_yearly = [
  {
    name: "Starter",
    price: "$49.99/year",
    value: "starter",
  },
  {
    name: "Dream Team",
    price: "$149.99/year",
    value: "dream",
  },
  {
    name: "Business",
    price: "$299.99/year",
    value: "business",
  },
];

const _plan = [
  {
    name: "RISING MEMBERSHIP",
    price: "$20/mo",
    value: "rising",
  },
  {
    name: "RISING + MEMBERSHIP",
    price: "$96/year",
    value: "rising_membership",
  },
];

const plans = {
  rising: {
    value: "RISING MEMBERSHIP",
    price: "$20/mo",
  },
  rising_membership: {
    value: "RISING + MEMBERSHIP",
    price: "$96/year",
  },
  starter_monthly: {
    value: "Starter Monthly",
    price: "$4.99/mo",
  },
  starter_yearly: {
    value: "Starter Yearly",
    price: "$49.99/year",
  },
  dream_monthly: {
    value: "Dream Monthly",
    price: "$14.99/mo",
  },
  dream_yearly: {
    value: "Dream Yearly",
    price: "$149.99/year",
  },
  business_monthly: {
    value: "Business Monthly",
    price: "$29.99/mo",
  },
  business_yearly: {
    value: "Business Yearly",
    price: "$299.99/mo",
  },
  free: {
    value: "free",
    price: "FREE",
  },
};

const PaymentComponent = ({
  togglePaymentMethodContainer,
  openAddPaymentContainer,
  handleAddPaymentMethod,
  paymentMethods,
  handleOnDeletePaymentMethod,
  handleDefaultPaymentSelect,
  defaultPayment,
  premiumUser,
  defaultCard,
  handleCancelSubscription,
  subType,
}) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const user = useSelector((state) => state?.userDetails?.user);
  const sub_plan = user?.subscription_plan_type || "free";

  return (
    <div className="payment-tab-container">
      <div className="payment-tab-method-container">
        <div className="title">Payment information</div>
        <div className="header-container">
          <span className="payment-receive-header">Active payment method</span>
        </div>
        {paymentMethods.map((card) => (
          <div key={card.paymentId} className="card-detail-container">
            <section>
              <Delete className="card-detail-delete-icon" onClick={() => handleOnDeletePaymentMethod(card.paymentId)} />
              <span className="card-last-digits">{`**** ${card.cardDetails.last4}`}</span>
            </section>
            <section>
              <span className="default-txt">default</span>
              <ToggleSwitch checked={defaultPayment === card.paymentId} onChange={(e) => handleDefaultPaymentSelect(e, card)} />
            </section>
          </div>
        ))}
      </div>
      <div className="add-tab-another-container">
        <Button buttonText="ADD ANOTHER" className="add-another-btn" onClick={togglePaymentMethodContainer} />
      </div>

      {premiumUser && (
        <section className="active-payment">
          <div className="active-plan-title">Active payment plans</div>
          <section className="cancel-pro-subscription">
            <aside className="cancel-pro-left">
              <div className="cancel-pro-title">{plans[sub_plan]?.value}</div>
              <div className="cancel-pro-amount">{plans[sub_plan]?.price}</div>
            </aside>
            <aside className="default-payment">
              {defaultCard && (
                <>
                  <div className="cancel-pro-title">Payment method</div>
                  <div className="cancel-pro-amount">****{defaultCard?.cardDetails?.last4}</div>
                </>
              )}
            </aside>
            <aside className="cancel-pro-right">
              <Button onClick={handleCancelSubscription} className="cancel-pro-button" buttonText="CANCEL PLAN" />
            </aside>
          </section>
        </section>
      )}

      <section className="all-membership">
        <div className="all-header-flex">
          <div className="all-membership-title">All membership plans</div>
          {/* <aside className="toggle-switch">
            <p>Annual</p>
            <div className="switch-component">
              <ToggleSwitchBlue onChange={(e) => setIsMonthly(e.target.checked)} checked={isMonthly} />
            </div>
            <p>Monthly</p>
          </aside> */}
        </div>

        {_plan.map((el) => (
          <MemberShipItem
            key={el.price}
            selected={sub_plan === el?.value}
            onSelect={() => {
              setSelectedPlan(el?.value);
            }}
            {...el}
          />
        ))}

        <div className="membership-item">
          <aside className="left-content">
            <div className="plan-name">Free</div>
            <div className="plan-cost">FREE</div>
          </aside>
          <aside className="right-content">{/* <Button className="btn-select" buttonText="SELECT" /> */}</aside>
        </div>
      </section>

      {/* <div className="transaction-history-container">
          <span className="transaction-history-header">{content.TRANSACTION_HISTORY}</span>
          <div className="transaction-history-card">
            <div className="amount-last-digit">
              <span>{`$5, `}</span>
              <span>**** 1234</span>
            </div>
            <div className="transaction-time-container">
              <span className="time-txt">12 Jun 2020, 12:10</span>
              <span>Track from User name</span>
            </div>
            <Button buttonText="RECEIVED" className="received-btn" />
          </div>
        </div> */}

      <StripeProvider apiKey={STRIPE_KEY}>
        <Elements>
          <UpgradeModal
            open={!!selectedPlan}
            onClose={() => setSelectedPlan(null)}
            defaultPayment={defaultPayment}
            plan_name={selectedPlan}
            plan_frequency={isMonthly ? "monthly" : "yearly"}
          />
        </Elements>
      </StripeProvider>

      <CustomDrawer open={openAddPaymentContainer} handleOnClose={togglePaymentMethodContainer}>
        <div className="roles-drawer">
          <div className="drawer-header">
            <img src={CloseIcon} className="close-icon" onClick={togglePaymentMethodContainer} />
          </div>
          <StripeProvider apiKey={STRIPE_KEY}>
            <Elements>
              <AddPayment handleAddPaymentMethod={handleAddPaymentMethod} />
            </Elements>
          </StripeProvider>
        </div>
      </CustomDrawer>
    </div>
  );
};

const createOptions = {
  style: {
    base: {
      fontSize: "14px",
      color: "#fff",
      letterSpacing: "1px",
      fontFamily: "Source Code Pro, monospace",
      "::placeholder": {
        color: "#696969",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const _modal = ({ open, onClose, plan_name, plan_frequency, defaultPayment, stripe, user_name }) => {
  const [state, setState] = useState({
    status: "idle",
    error: null,
  });

  const onUpdatePlan = async (payment_token = "") => {
    setState((pV) => ({ ...pV, status: "loading" }));
    try {
      const res = await onChangeSubscriptionPlan({
        plan_frequency,
        plan_name,
        payment_token,
      });
      setState((pV) => ({ ...pV, status: "data" }));
      console.log(res);
    } catch (e) {
      setState((pV) => ({ ...pV, status: "error", error: e?.error }));
      console.log(e?.error);
    }
  };

  const handleStripe = async () => {
    setState((pV) => ({ ...pV, status: "loading" }));
    const { token } = await stripe.createToken({ name: user_name });
    onUpdatePlan(token?.id);
  };

  const handleClose = () => {
    setState({ status: "idle", error: null });
    onClose();
  };

  return (
    <BModal open={open} onClose={handleClose}>
      <section className="subscription-modal">
        <div className="close">
          <img onClick={handleClose} src={CloseIcon} alt="" />
        </div>

        {state?.status === "data" && (
          <>
            <div className="sub-title">UPGRADE CONFIRMED</div>
            <div className="success-img">
              <img src={SuccessImg} alt="" />
            </div>
          </>
        )}

        {state.status !== "data" && !!defaultPayment && (
          <>
            <div className="sub-title">You are changing your membership to</div>
            <div className="plan-name">
              {plan_name} {plan_frequency}
            </div>
            <div className="controls">
              <Button
                onClick={onUpdatePlan}
                loading={state?.status === "loading"}
                loadingColor="#fff"
                className="btn-selected"
                buttonText="CONFIRM"
              />
            </div>
          </>
        )}

        {state.status !== "data" && !defaultPayment && (
          <>
            <div className="sub-title">You are changing your membership to</div>
            <div className="plan-name">
              {plan_name} {plan_frequency}
            </div>

            <div className="stripe-form">
              <div className="el-title">Card Number</div>
              <div className="stripe-el">
                <CardNumberElement placeholder="4242 4242 4242 4242" style={createOptions.style} />
              </div>
              <div className="el-flex">
                <aside>
                  <div className="el-title">Expiry Date</div>
                  <div className="stripe-el">
                    <CardExpiryElement placeholder="04/24" style={createOptions.style} />
                  </div>
                </aside>
                <aside>
                  <div className="el-title">CVC</div>
                  <div className="stripe-el">
                    <CardCVCElement style={createOptions.style} placeholder="242" />
                  </div>
                </aside>
              </div>
            </div>

            <div className="controls">
              <Button
                onClick={handleStripe}
                loading={state?.status === "loading"}
                loadingColor="#fff"
                className="btn-selected"
                buttonText="CONFIRM"
              />
            </div>
          </>
        )}
      </section>
    </BModal>
  );
};

const UpgradeModal = injectStripe(_modal);

const MemberShipItem = ({ name, price, onSelect, selected }) => {
  return (
    <div className="membership-item">
      <aside className="left-content">
        <div className="plan-name">{name}</div>
        <div className="plan-cost">{price}</div>
      </aside>
      <aside className="right-content">
        {!selected && <Button onClick={onSelect} className="btn-select" buttonText="SELECT" />}
        {selected && <Button onClick={() => {}} className="btn-selected" buttonText="ACTIVE" />}
      </aside>
    </div>
  );
};

export default PaymentComponent;
