import React from "react";
export default function MobileComponent(props) {
  return (
    <div className={`mobile-component__container ${props.className}`}>
      {props.textInTop ? (
        <>
          <div className="mobile-component__text" style={{ marginBottom: "1em" }}>
            {props.text}
          </div>
          <img src={props.img} alt="mobile device" />
        </>
      ) : (
        <>
          <img src={props.img} alt="mobile device" />
          <div className="mobile-component__text" style={{ marginTop: "1em" }}>
            {props.text}
          </div>
        </>
      )}
    </div>
  );
}
