import React from "react";
import InputField from "../../common/InputField";
import cx from "classnames";
import PhoneComponent from "../Login/PhoneComponent";
import PhoneSMS from "../../assets/img/phonesms.png";
import { toast } from "react-toastify";

export default function CreateAccount(props) {
  return (
    <div className="signup-create-account" {...props}>
      <div className="signup-form__container">
        {props.errors.userExist && <span className="error-txt">{props.errors.message}</span>}
        <div className="signup-form__inputs">
          <div className="inputs-container">
            <div className="register-input-container first-last-name">
              <div className="input-label">First & Last Name</div>
              <InputField
                id="display_name"
                className={cx("register-input", props.errors?.display_name && "error-border")}
                value={props.state.display_name}
                onChange={props.handleChange}
              />
            </div>

            <div className="register-input-container">
              <div className="input-label">Username</div>
              <InputField
                id="user_name"
                className={cx("register-input", props.errors?.user_name && "error-border")}
                value={props.state?.user_name}
                onChange={props.handleChange}
              />
            </div>
          </div>

          <div className="inputs-container">
            <div
              className="register-input-container register-input-container__phone hide-on-mobile"
              style={{
                width: "100%",
                marginRight: "21px",
              }}
            >
              <PhoneComponent
                phoneNumber={props.phoneNumber}
                handleOnChangePhoneNumber={props.handleOnChangePhoneNumber}
                handleOnChangeCountryCode={props.handleOnChangeCountryCode}
                small={false}
                errors={props.errors}
                countryCode={props.countryCode}
                showErrorMessage={true}
              />
              <div className="sms-info-container">
                <img src={PhoneSMS} alt="phone-sms-logo" width="19" height="24" />
                <span className="sms-message-span">{"We will send you an SMS to identificate this number"}</span>
              </div>
            </div>

            <div
              className="register-input-container mobile-margin-0"
              style={{
                marginTop: "-37px",
              }}
            >
              <div className="input-label">Email</div>
              <InputField
                id="email"
                className={cx("register-input", props.errors?.email && "error-border")}
                value={props.state.email}
                onChange={props.handleChange}
              />
            </div>
            <div
              className="register-input-container register-input-container__phone hide-on-desktop"
              style={{
                width: "100%",
                marginRight: "21px",
              }}
            >
              <PhoneComponent
                phoneNumber={props.phoneNumber}
                handleOnChangePhoneNumber={props.handleOnChangePhoneNumber}
                handleOnChangeCountryCode={props.handleOnChangeCountryCode}
                small={false}
                errors={props.errors}
                countryCode={props.countryCode}
                showErrorMessage={true}
              />
              <div className="sms-info-container">
                <img src={PhoneSMS} alt="phone-sms-logo" width="19" height="24" />
                <span className="sms-message-span">{"We will send you an SMS to identificate this number"}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="signup-form__footer">
          <button
            onClick={() => {
              if (props.resendTime > 1) {
                toast.error(`Please wait for ${props.resendTime}secs`);
              } else {
                props.handleOnSubmit();
              }
            }}
          >
            CONTINUE
          </button>
          {/* <div className="signup-form__footer-login">
            <div className="signup-form__footer-login__account">Already have an account?</div>
            <Link to="/login">
              <div className="signup-form__footer-login__now">Log in now!</div>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}
