import React, { useState } from "react";
import downArrow from "../../assets/img/internship/down-arrow-white.svg";

export default function Addon(props) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  return (
    <div className="addon-wrap">
      <div className="addon">
        <div className="first">
          <label class="checkbox-container">
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                setChecked((prev) => !prev);
                props.setIntern((prev) => !prev);
                if (!checked) {
                  props.setTotal((prev) => prev + 499);
                } else {
                  props.setTotal((prev) => (prev === 0 ? 0 : prev - 499));
                }
              }}
            />
            <span className="checkmark"></span>
          </label>
          <div className="addon-title__container">
            <div className="tittle-arrow__container">
              <div className="container-wrap1">
                <div className="container-wrap2">
                  <div className="title-wrapper" onClick={() => setOpen((prev) => !prev)}>
                    <h1>{props.title}</h1>
                    <span className="arrow">
                      <img src={downArrow} alt="up-arrow" className={`arrow__img ${open ? "arrow__down" : "arrow__up"}`} />
                    </span>
                  </div>
                  <div className="addon-desc">
                    <span>{props.desc}</span>
                  </div>
                </div>
                {open && <hr className="hide-on-desktop" />}
                <div className="addon-price">
                  <div className="addon-price__wrap">
                    <span>
                      <sup>$</sup>
                      {props.price}
                    </span>
                    <span className="pre">per enrollment</span>
                  </div>
                </div>
                {open && <hr className="hide-on-desktop" />}
              </div>
            </div>
          </div>
        </div>
        {open && (
          <div className="program-details">
            <ul>
              {props.features.map((item, idx) => (
                <li key={`programdetails${idx}`}>{item}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
