import React from "react";
import styled from "styled-components";

// components
import FirstParagraph from "./FirstParagraph";
import Footer from "./Footer";
import FourthParagraph from "./FourthParagraph";
import Navbar from "./Navbar";
import SecondParagraph from "./SecondParagraph";
import ThirdParagraph from "./ThirdParagraph";

const NewInternship = () => {
  return (
    <>
      <Wrapper>
        <Navbar />
        <FirstParagraph />
        <Seperator />
        <SecondParagraph />
        <Seperator />
        <ThirdParagraph />
        <FourthParagraph />
        <Footer />
      </Wrapper>
    </>
  );
};

export default NewInternship;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #18181a;
`;

const Seperator = styled.div`
  width: 100%;
  border-bottom: 1px solid gold;
`;
