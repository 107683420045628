import React, { useState } from "react";
import downArrow from "../../assets/img/pricing/down-arrow.svg";

export default function FAQ(props) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((open) => !open);
  };
  return (
    <div className={`faq-question__container `} onClick={handleClick}>
      <div className={`faq-question__title ${open ? "padding-bottom-12" : ""}`}>
        <span>{props.title}</span>
        <span className="faq-arrow">
          <img src={downArrow} alt="up-arrow" className={`faq-arrow__img ${open ? "faq-arrow__down" : "faq-arrow__up"}`} />
        </span>
      </div>
      <div className={`${open ? "faq-question__answer" : "faq-question__answer-hide"}`}>{props.desc}</div>
    </div>
  );
}
