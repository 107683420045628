import React, { useEffect, useState } from "react";
import "./pricing.style.scss";
import SwipeIcon from "../../assets/icon/swipe-icon.svg";
import FAQ from "./FAQ";
import PlanCard from "./PlanCard";
import HomeNav from "../Home/ui/HomeNav";
import SideDrawer from "../Home/ui/SideDrawer";
import HomeFooter from "../Home/ui/HomeFooter";
import SwipeableViews from "react-swipeable-views";

const styles = {
  root: {
    padding: "0 40px",
  },
  slideContainer: {
    padding: "0 10px",
  },
};

export const rising = {
  title: "RISING MEMBERSHIP",
  slug: "rising",
  recommended: false,
  description: "The essential tools you need to start generating organic interest in your music.",
  price: 20.0,
  recurring: "mo",
  features: [
    "Upload 1 track per month",
    "10 Reviews",
    "Standard Queue",
    "Fan Targeting",
    "@Bonus Exposure",
    "Member Events",
    "Priority Support",
  ],
  exploreFeatures: [
    {
      title: "Build your team",
      description: "Interact all the new fans who want to help you promote your music in a private discussion forum.",
    },
    {
      title: "Direct message",
      description: "Talk privately with every fan on your team so strategize on how they can help you reach your goals.",
    },
    {
      title: "Music promotion",
      description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
    },
    {
      title: "Analyze your fans",
      description:
        "Find out how interested fans are in your music.  Read all reviews and discover who’s willing to promote you on social media/playlists.",
    },
    {
      title: "Meet new people",
      description: "Attend virtual in-app events to meet other music creators and industry professionals.",
    },
  ],
};

export const risingYearly = {
  title: "RISING + MEMBERSHIP",
  slug: "rising_membership",
  recommended: false,
  description: "Sky's the limit. Share various tracks or an album, etc. Meet new people to grow your team.",
  price: 96.0,
  recurring: "yr",
  features: [
    "Upload 1-20 track per month",
    "Unlimited Reviews",
    "High Priority Queue",
    "Fan & Intern Targeting",
    "Bonus & Exposure",
    "Member + Events",
    "Priority + SMS Support",
  ],
  exploreFeatures: [
    {
      title: "Go live in rooms",
      description:
        "Get your own dedicated workspace to host  events in the app. Enable audio and go live with fans, your team or collaborators. Video coming soon!",
    },
    {
      title: "Build your team",
      description: "Interact all the new fans who want to help you promote your music in a private discussion forum.",
    },
    {
      title: "Direct message",
      description: "Talk privately with every fan on your team so strategize on how they can help you reach your goals.",
    },
    {
      title: "Music promotion",
      description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
    },
    {
      title: "Analyze your fans deeper",
      description: "Measure the performance of each track by accessing more details about how each listener interacts with your track.",
    },
    {
      title: "Meet new people",
      description: "Attend virtual in-app events to meet other music creators and industry professionals.",
    },
  ],
};

export const free = {
  title: "FREE PLAN",
  slug: "free",
  recommended: false,
  description: "Create a profile and explore the app. Listen to new tracks and share one of yours.",
  price: "FREE",
  recurring: "",
  features: [
    "Upload 1 track free",
    "3 Reviews",
    "Low Priority Queue",
    "Public Evemts",
    "@Listener Targeting",
    "@Bonus Exposure",
    "Email Support",
  ],
  exploreFeatures: [
    {
      title: "Music promotion",
      description: "Upload your track into our Queue to promote it to a community of fans looking to discover new music.",
    },
    {
      title: "Analyze your fans",
      description:
        "Find out how interested fans are in your music.  Read all reviews and discover who’s willing to promote you on social media/playlists.",
    },
    {
      title: "Meet new people",
      description: "Attend virtual in-app events to meet other music creators and industry professionals.",
    },
  ],
};

const faqs = [
  {
    question: "Who reviews my music?",
    answer:
      "Our community is full of music lovers, aspiring executives, creators, influencers and industry professionals.  These are real people who want to discover and support musicians however they can.",
  },
  {
    question: "How many tracks can I upload?",
    answer: "Each month you can upload to the limit set by your membership plan.  Upgrade at any time if you’d like to upload more tracks.",
  },
  {
    question: "How long does it take to get reviews?",
    answer:
      "We’re a relatively new service so it’s hard to say.  We’re on a mission to find the best fans we can and hope to get you more than enough reviews each month to help you grow your brand.",
  },
  {
    question: "How do I access the reviews?",
    answer:
      "Login to the website and navigate to the dashboard where you’ll see each track you uploaded.  From there you can navigate to read reviews.  To protect your privacy, reviews are not displayed publicly.",
  },
  {
    question: "Can I upgrade or downgrade / switch plans at any time?",
    answer: "Absolutely!  Just login on the website and visit the settings page.",
  },
  {
    question: "Do you offer refunds?",
    answer:
      "As a company policy, we do not issue refunds unless a system malfunction caused a problem or your account was terminated without cause.",
  },
  {
    question: "How can I get people to join my team?",
    answer:
      "It’s all organic — fans, interns and professionals listen to your song and have the option to join your team.  We also recommend posting to your socials about it as well.  Ask your biggest fans to join your team on Breaker Nation!",
  },
  {
    question: "How does targeting work?",
    answer:
      "We’re in the early stages of targeting, but our goal is to promote your music to the best audience possible.  Currently, we’re promoting music to the most active listeners as well as former, current and prospective interns.",
  },
  {
    question: "How does the queue priority work?",
    answer:
      "Based on the membership plan you have, your music is routed into one of 3 queues: low priority, standard and high priority.  The high priority queue is geared to help you get more reviews in less time, by our most active listeners.  If your track appears in the low priority queue it just means that it may take longer for you to get reviews.  With the standard queue, expect reviews in a moderate amount of time.  Please note that all of this may vary.  We are a young startup without major funding, working to get all the resources we need to build the best possible application.",
  },
  {
    question: "What types of bonus exposure do you offer?",
    answer:
      "Every month we provide offers for our Rising+ Members to be considered for introductions, social media mentions, submission opptys, freebies, discounts, performance opptys and/or anything else that we can do to help you amplify your music!  As we finalize these bonuses, you will be notified via email.",
  },
];

export default function PricingComponent() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenuOnSidebar, setShowMenuOnSidebar] = useState(false);

  useEffect(() => {
    if (!showSidebar) {
      setShowMenuOnSidebar(false);
    }
  }, [showSidebar]);

  return (
    <>
      <div className="pricing-main-container">
        <HomeNav onMenuClick={() => setShowSidebar(true)} onMenuClose={() => setShowSidebar(false)} showSidebar={showSidebar} />

        {/* Plan title */}
        <div className="plans-title__container">
          <div className="pricing-title">Membership Plans</div>

          <div className="pricing-desc">Choose the membership plan that’s right for you and start making moves!</div>
        </div>

        {/* Plan Cards */}
        <div className="pricing-plans">
          {/* Hide on mobile view */}
          <div className="pricing-plans__cards">
            <div className="pricing-plans__container">
              <PlanCard {...free} style={{ marginRight: "10px" }} pricingPlanInterval="monthly" />
              <PlanCard {...rising} style={{ marginRight: "10px" }} pricingPlanInterval="monthly" />
              <PlanCard {...risingYearly} special style={{ marginLeft: "10px" }} pricingPlanInterval={"yearly"} />
            </div>
          </div>

          {/* Show on mobile view */}
          <div className="carousel-swipe__instruction">
            <div>Swipe to see plans</div>
            <img src={SwipeIcon} alt="swipe icon" />
          </div>
          <div className="pricing-plans__carousel">
            <SwipeableViews style={styles.root} slideStyle={styles.slideContainer}>
              <PlanCard {...free} pricingPlanInterval={"monthly"} />
              <PlanCard {...rising} pricingPlanInterval={"monthly"} />
              <PlanCard {...risingYearly} special pricingPlanInterval={"yearly"} />
            </SwipeableViews>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="faq-container">
          <div className="faq-title">General questions</div>
          <div className="faq-questions__container">
            {faqs.map((faq) => (
              <FAQ title={faq.question} desc={faq.answer} />
            ))}
          </div>
        </div>

        {/* Footer */}

        <SideDrawer onClose={() => setShowSidebar(false)} open={showSidebar} showClose={showMenuOnSidebar} />
      </div>
      <HomeFooter
        onMenuClick={() => {
          setShowSidebar(true);
          setShowMenuOnSidebar(true);
        }}
      />
    </>
  );
}
