import api from "../../config";
import { axiosInstance } from "../../utils/axiosInstance";

export const uploadResumeAxiosModified = (file, cb) => {
  const url = `${api}/file/any`;
  const fd = new FormData();
  fd.append("file", file);

  const config = {
    onUploadProgress: (p) => {
      var uploaded = Math.round((p.loaded / p.total) * 100);
      cb && cb(uploaded);
    },
  };

  return axiosInstance.post(url, fd, config);
};

export const handleInternshipFormSubmit = (resumeUrl, questionAnswer) => {
  const url = `${api}/internship`;
  return axiosInstance.post(url, {
    resume_url: resumeUrl,
    question_answer: questionAnswer,
  });
};

export const handleEnrollment = (payload) => {
  const url = `${api}/internship/enroll`;
  return axiosInstance.post(url, payload);
};

export const submitDeclineReason = (reason) => {
  const url = `${api}/internship/decline`;
  return axiosInstance.post(url, {
    reason,
  });
};

export const checkInternStatus = () => {
  const url = `${api}/internship/status`;
  return axiosInstance.get(url);
};
