import React from "react";
import "./pricing.style.scss";

export default function PlanCardHeader(props) {
  return (
    <div className="pricing-plan__header" style={props.style}>
      <div className="pricing-plan__header-title">
        <div>
          {/* <span>Breaker Nation</span> */}
          <h2>{props.title}</h2>
        </div>
      </div>
      <div className="pricing-plan__header-desc">
        <span>{props.description}</span>
      </div>
    </div>
  );
}
